import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DeliveryPackageListViewInterface } from '@kravling/modules/core/models/View/DeliveryPackageListViewInterface';
import { DeliveryElementListViewInterface } from '@kravling/modules/core/models/View/DeliveryElementListViewInterface';

@Injectable()
export class DeliveryPackageService {
  constructor(private http: HttpClient) {}

  doTransition$(id: string, transition: string, data: any): Observable<DeliveryPackageListViewInterface> {
    return this.http.post<DeliveryPackageListViewInterface>(`/delivery-packages/${id}/do/${transition}`, data);
  }

  clone$(id: string): Observable<any> {
    return this.http.post<any>(`/delivery-packages/${id}/clone`, null);
  }

  updateMedia$(id, data: any): Observable<DeliveryPackageListViewInterface> {
    return this.http.post<DeliveryPackageListViewInterface>(`/delivery-packages/${id}/update-media`, data);
  }

  getVisibleDeliveryElements(deliveryElements: DeliveryElementListViewInterface[]): DeliveryElementListViewInterface[] {
    return deliveryElements.filter(_ => _.elementCategory !== 'Hidden');
  }
}
