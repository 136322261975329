import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import UserInterface from '../../../../models/interfaces/user.interface';
import { DeliveryService } from '@kravling/modules/core/services/delivery.service';
import { DeliveryListViewInterface } from '@kravling/modules/core/models/View/DeliveryListViewInterface';
import { DeliveryHasLinkViewInterface } from '@kravling/modules/core/models/View/DeliveryHasLinkViewInterface';

@Component({
  selector: 'app-approval-delivery-public-links',
  templateUrl: './delivery-public-links.component.html',
  styles: [],
})
export class DeliveryPublicLinksComponent implements OnInit, OnDestroy, OnChanges {
  @Input() delivery: DeliveryListViewInterface;
  @Input() user: UserInterface;

  @Output() onPublicLinkCreated: EventEmitter<DeliveryHasLinkViewInterface> =
    new EventEmitter<DeliveryHasLinkViewInterface>();
  @Output()
  onCopyLinkToClipboard: EventEmitter<DeliveryHasLinkViewInterface> = new EventEmitter<DeliveryHasLinkViewInterface>();

  form: FormGroup;
  isFormSubmitting = false;

  constructor(private deliveryService: DeliveryService, private fb: FormBuilder) {}

  ngOnInit(): void {
    this.buildForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.delivery) {
      this.buildForm();
    }
  }

  buildForm() {
    this.form = this.fb.group({
      link: [this.delivery.link && this.delivery.link.link ? this.delivery.link.link : null],
    });
  }

  onFormSubmit() {
    this.isFormSubmitting = true;
    this.deliveryService
      .createLink$(this.delivery.id, {})
      .pipe(
        finalize(() => {
          this.isFormSubmitting = false;
        }),
      )
      .subscribe((_) => {
        this.delivery.link = _;
        this.form.get('link').setValue(_.link);
        this.onPublicLinkCreated.emit(_);
      });
  }

  copyToClipboard(): void {
    const listener = (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', this.form.get('link').value);
      e.preventDefault();
    };

    document.addEventListener('copy', listener);
    document.execCommand('copy');
    document.removeEventListener('copy', listener);
    this.onCopyLinkToClipboard.emit(this.form.get('link').value);
  }

  ngOnDestroy(): void {
    this.form.get('link').setValue(null);
  }
}
