import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { DeliveryPackageListViewInterface } from '@kravling/modules/core/models/View/DeliveryPackageListViewInterface';

@Component({
  selector: '<app-approval-delivery-package-edit>',
  template: '',
})
export class DeliveryPackageEditComponent implements OnInit, OnDestroy {
  @Input() deliveryPackage: DeliveryPackageListViewInterface;
  @Output() onFormStatusChange = new EventEmitter<FormGroup>();

  form: FormGroup;
  isFormSubmitting = false;
  subscriptions: Subscription[] = [];

  constructor(protected fb: FormBuilder) {}

  ngOnInit() {
    this.buildForm();
  }

  buildForm() {
    this.form = this.fb.group({});
    this.subscriptions.push(
      this.form.statusChanges.subscribe((_) => {
        this.onFormStatusChange.emit(this.form);
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((_) => _.unsubscribe());
    this.subscriptions = [];
  }
}
