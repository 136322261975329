import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { EasportsFifaAppComponent } from './easports-fifa-app.component';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormUtilitiesModule } from '@braune-digital/form-utilities';
import { FormsModule } from '@angular/forms';
import { BdFilterModule } from '@braune-digital/angular-query-filter';
import { TimeagoIntl, TimeagoModule } from 'ngx-timeago';
import { CommonModule } from '@angular/common';
import { BdKravlingModule } from '@kravling/bd.kravling.module';
import { RoutingModule } from './pages/pages.routing';
import { SecurityModule } from './modules/security/security.module';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { BsLocaleService, DatepickerModule } from 'ngx-bootstrap/datepicker';
import { NgxSplideModule } from 'ngx-splide';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, '/public/i18n/', '');
}

export class KravIntl extends TimeagoIntl {
  // do extra stuff here...
}

@NgModule({
  imports: [
    BrowserModule,
    CommonModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FormUtilitiesModule,
    DatepickerModule.forRoot(),
    FormsModule,
    RoutingModule,
    BdKravlingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    BdFilterModule.forRoot(),
    SecurityModule.forRoot(),
    TooltipModule.forRoot(),
    TimeagoModule.forRoot({
      intl: { provide: TimeagoIntl, useClass: KravIntl },
    }),
    NgxSplideModule
  ],
  declarations: [EasportsFifaAppComponent],
  providers: [BsLocaleService],
  bootstrap: [EasportsFifaAppComponent],
})
export class EasportsFifaAppModule {}
