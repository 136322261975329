<form class="m10" [formGroup]="form" *ngIf="form" (submit)="handleSubmit()">
  <ng-content></ng-content>

  <bd-input
    [requiredMarker]="true"
    type="password"
    [label]="'security.login.labels.password' | translate"
    [formControl]="form.get('password')"
  ></bd-input>
  <bd-input
    [requiredMarker]="true"
    type="password"
    [label]="'security.login.labels.password_confirm' | translate"
    [formControl]="form.get('passwordRepeated')"
  ></bd-input>

  <ul class="form-errors mb-5" *ngIf="error">
    <li>{{ error | translate }}</li>
  </ul>

  <div class="m10__actions">
    <button type="submit" [disabled]="form.invalid" class="btn btn-primary">
      {{ 'Password Reset' | translate }}
    </button>
  </div>

  <ngx-loading [show]="isSendingRequest"></ngx-loading>
</form>
