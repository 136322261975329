import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '../../../security/services/auth.service';

@Component({
  selector: '<app-approval-dashboard>',
  templateUrl: './dashboard.component.html',
})
export class DashboardComponent {

  isSuperAdmin = AuthService.user && AuthService.user.roles.some((r) => r === 'ROLE_SUPER_ADMIN');

}
