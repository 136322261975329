import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DeliveryReadViewInterface } from '@kravling/modules/core/models/View/DeliveryReadViewInterface';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: '<app-approval-delivery-feed-item>',
  template: '',
})
export class FeedItemComponent {
  constructor(protected modalService: BsModalService) {}

  @Input() delivery: DeliveryReadViewInterface;
  @Output() reload = new EventEmitter<boolean>();
}
