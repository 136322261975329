<div class="container m03" [ngClass]="{ 'm03--open': !!selectedDeliveryPackage }">
  <div class="m01" *ngIf="delivery">
    <div class="m08">
      <div *ngIf="delivery.folder.image" class="m08__image">
        <img [src]="delivery.folder.image?.path" alt="{{ delivery.folder.name }}" />
      </div>
      <div class="m08__text">
        <h2>
          {{ delivery.folder.name }}
          <app-approval-delivery-status [state]="delivery.state"></app-approval-delivery-status>
        </h2>
      </div>
    </div>
    <div class="m02" *ngFor="let item of delivery.deliveryPackages">
      <app-approval-delivery-feed-item-delivery-package
        [readOnly]="true"
        [item]="item"
        [delivery]="delivery"
        (onSelectDeliveryPackage)="selectedDeliveryPackage = $event"
      ></app-approval-delivery-feed-item-delivery-package>
    </div>
  </div>
  <app-approval-delivery-package-gallery
    class="m03__gallery"
    *ngIf="selectedDeliveryPackage"
    [deliveryPackage]="selectedDeliveryPackage"
    (onClose)="selectedDeliveryPackage = null"
  ></app-approval-delivery-package-gallery>
</div>
