import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { DeliveryPackageListViewInterface } from '@kravling/modules/core/models/View/DeliveryPackageListViewInterface';
import { DeliveryPackageStates } from '@kravling/modules/core/models/DeliveryPackageInterface';
import MediaInterface from '../../../../models/interfaces/media.interface';
import {
  NgxGalleryAnimation,
  NgxGalleryComponent,
  NgxGalleryImage,
  NgxGalleryLayout,
  NgxGalleryOptions,
} from '@kolkov/ngx-gallery';
import { DeliveryPackageService } from '@kravling/modules/core/services/delivery-package.service';

@Component({
  selector: '<app-approval-delivery-package-gallery>',
  templateUrl: './delivery-package-gallery.component.html',
})
export class DeliveryPackageGalleryComponent implements OnChanges, OnInit, OnDestroy {
  @Input() deliveryPackage: {
    deliveryPackage: DeliveryPackageListViewInterface;
    index: number;
  };
  @Input() forceAllowDownload: boolean = false;
  @Output() onClose = new EventEmitter<boolean>();

  galleryOptions: NgxGalleryOptions[];
  galleryImages: NgxGalleryImage[] = [];
  DeliveryPackageStates = DeliveryPackageStates;

  title: string;

  @ViewChild(NgxGalleryComponent)
  gallery: NgxGalleryComponent;


  constructor(
    public deliveryPackageService: DeliveryPackageService
  ) {
  }

  ngOnInit(): void {
    this.galleryOptions = [
      {
        width: '100%',
        height: '100%',
        thumbnailsColumns: 6,
        imageAnimation: NgxGalleryAnimation.Fade,
        thumbnailsPercent: 15,
        thumbnailsMargin: 10,
        thumbnailMargin: 10,
        imagePercent: 85,
        imageSwipe: true,
        thumbnailsSwipe: true,
        lazyLoading: true,
        previewZoom: true,
        previewDownload: true,
        imageArrowsAutoHide: true,
        layout: NgxGalleryLayout.ThumbnailsBottom,
        startIndex: this.deliveryPackage.index,
      },
      {
        breakpoint: 960,
        thumbnailsMargin: 2,
        thumbnailMargin: 2,
        thumbnailsColumns: 4,
      },
    ];
  }

  initGallery(): void {
    this.galleryImages = [];
    const visibleDeliveryElements = this
      .deliveryPackageService
      .getVisibleDeliveryElements(
        this.deliveryPackage.deliveryPackage.deliveryElements
      )
    ;
    visibleDeliveryElements.forEach((_) => {
      if (_.preview === null && _.media === null) {
        return;
      }
      const media: MediaInterface = _.preview ? _.preview : _.media;

      if (_.media.contentType === 'video/mp4') {
        this.galleryImages.push({
          small: _.media.original,
          medium: _.media.original,
          big: _.media.original
        });
      } else {
        this.galleryImages.push({
          small: media.path,
          medium: media.big,
          big: media.big
        });
      }

    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['deliveryPackage'].currentValue) {
      this.initGallery();
      if (this.gallery) this.gallery.show(this.deliveryPackage.index);
      this.onChange({
        index: this.deliveryPackage.index,
        image: this.galleryImages[this.deliveryPackage.index],
      });
    }
  }

  onChange(event: { index: number; image: NgxGalleryImage }): void {
    this.title =
      'Draft #' +
      this.deliveryPackage.deliveryPackage.iteration +
      ': ' +
      this.deliveryPackage.deliveryPackage.deliveryElements[event.index].name;
  }

  onPreviewOpen(event): void {
    console.log(event);
  }

  close(): void {
    this.onClose.emit(true);
  }

  ngOnDestroy(): void {
    this.galleryImages = [];
    this.title = null;
  }
}
