<div class="m02__time">
  <div timeago [date]="item.date" [live]="true"></div>
</div>

<div class="m02__header">
  <h3>
    {{ 'Photo upload' | translate }}
    <app-approval-delivery-package-status
		[state]="item.object.state"
		workflow="club_to_bd"
	></app-approval-delivery-package-status>
  </h3>
  <app-approval-delivery-package-download
    class="m02__download"
    [deliveryPackage]="item.object"
    *ngIf="forceAllowDownload || item.object.state === DeliveryPackageStates.APPROVED.toString()"
  ></app-approval-delivery-package-download>
</div>

<div class="m13">
  <div class="main-upload main-upload--primary">
    <!--<div class="heading">
      <h5>Upload Stadium photo</h5>
    </div>
    <p class="subtitle">No players & no sponsors</p>-->

    <div class="photo-upload-text">
      <p>
        Please take a close look at the EA FC photo brief before delivering your photo. Whenever players are shown, it is important that there is never just one player, but always a group of three or more.
      </p>
      <a href="/assets/pdf/FC25_photo_brief.pdf" class="btn btn-primary" title="View EA SPORTS FC photo brief" target="_blank">
        View EA SPORTS FC photo brief
      </a>
    </div>
    <div class="photo-upload">
      <div class="main-uploader" [ngClass]="{ 'main-uploader--image': deliveryElementMain && deliveryElementMain.media }">
        <div class="heading">
          <h5>Main Photo</h5>
        </div>
        <div class="main-uploader-upload-container">
          <div class="main-uploader-upload-container-click-area" (click)="open(0)" *ngIf="uploaderIsDisabled && deliveryElementMain && deliveryElementMain.media">
          </div>
          <button class="icon-button" *ngIf="deliveryElementMain && deliveryElementMain.media">
            <i class="fal fa-search-plus"></i>
          </button>

          <bd-upload
            *ngIf="uploaderMain"
            [multiple]="false"
            [disableMeta]="true"
            [uploader]="uploaderMain"
            [form]="form.get('main')"
            class="main-upload-bd"
            [ngClass]="{
              disabled: this.item.role !== this.Roles.DELIVERER ||
      this.item.object.state === 'waiting' ||
      this.item.object.state === 'rejected' ||
      this.item.object.state === 'approved'
            }"
          >
            <div class="preview preview--cover" *ngIf="deliveryElementMain && deliveryElementMain.media">
              <img [src]="deliveryElementMain.media.big" [alt]="deliveryElementMain.name" />
            </div>

            <div
              class="no-upload"
              *ngIf="(!deliveryElementMain || !deliveryElementMain.media) && item.role !== Roles.DELIVERER"
            >
              <span>No photo uploaded yet</span>
            </div>
          </bd-upload>
          <div class="progress mt-2" *ngIf="showProgressMain">
            <div class="progress-bar progress-bar-striped bg-success" [style.width]="progressMain"></div>
          </div>
        </div>
      </div>
      <div class="main-uploader main-uploader--right" [ngClass]="{ 'main-uploader--image': deliveryElementThird && deliveryElementThird.media }">
        <div class="heading">
          <h5>Secondary Photo</h5>
        </div>
        <div class="main-uploader-upload-container">
          <div class="main-uploader-upload-container-click-area" (click)="open(0)" *ngIf="uploaderIsDisabled && deliveryElementThird && deliveryElementThird.media">
          </div>
          <button class="icon-button" (click)="open(0)" *ngIf="deliveryElementThird && deliveryElementThird.media">
            <i class="fal fa-search-plus"></i>
          </button>

          <bd-upload
            *ngIf="uploaderThird"
            [multiple]="false"
            [disableMeta]="true"
            [uploader]="uploaderThird"
            [form]="form.get('third')"
            class="main-upload-bd"
            [ngClass]="{
              disabled:
                this.item.role !== this.Roles.DELIVERER ||
      this.item.object.state === 'waiting' ||
      this.item.object.state === 'rejected' ||
      this.item.object.state === 'approved'
            }"
          >
            <div class="preview preview--cover" *ngIf="deliveryElementThird && deliveryElementThird.media">
              <img [src]="deliveryElementThird.media.big" [alt]="deliveryElementThird.name" />
            </div>

            <div
              class="no-upload"
              *ngIf="(!deliveryElementThird || !deliveryElementThird.media) && item.role !== Roles.DELIVERER"
            >
              <span>No photo uploaded yet</span>
            </div>
          </bd-upload>
          <div class="progress mt-2" *ngIf="showProgressThird">
            <div class="progress-bar progress-bar-striped bg-success" [style.width]="progressThird"></div>
          </div>

        </div>
      </div>

    </div>
  </div>

  <div class="main-upload main-upload--emblem">
    <div class="heading">
      <h5>Club crest check</h5>
    </div>
    <p class="subtitle">optional upload of a new club crest (In case there has been a change)</p>
    <div class="emblem-check">
      <div
        class="main-uploader"
        [ngClass]="{ 'main-uploader--image': deliveryElementSecondary && deliveryElementSecondary.media }"
      >
        <!--<button class="icon-button" (click)="open(1)" *ngIf="deliveryElementSecondary && deliveryElementSecondary.media">
          <i class="fal fa-search-plus"></i>
        </button>-->
        <bd-upload
          *ngIf="uploaderSecondary"
          [multiple]="false"
          [disableMeta]="true"
          [uploader]="uploaderSecondary"
          [form]="form.get('secondary')"
          class="main-upload-bd"
          [ngClass]="{
            disabled:
              this.item.role !== this.Roles.DELIVERER ||
      this.item.object.state === 'waiting' ||
      this.item.object.state === 'rejected' ||
      this.item.object.state === 'approved'
          }"
        >
          <div class="preview" *ngIf="deliveryElementSecondary && deliveryElementSecondary.media">
            <img [src]="deliveryElementSecondary.media.path" [alt]="deliveryElementSecondary.name" />
          </div>

          <div
            class="no-upload"
            *ngIf="(!deliveryElementSecondary || !deliveryElementSecondary.media) && item.role !== Roles.DELIVERER"
          >
            <span>No photo uploaded yet</span>
          </div>
        </bd-upload>
        <ng-container *ngIf="deliveryElementSecondary && deliveryElementSecondary.media && crestCheckApproved === false">
          <div class="check-btns" *ngIf="item.role === Roles.DELIVERER && item.object.state === deliveryPackageStates.NEW">
            <button class="btn btn-sm btn-primary" (click)="$event.stopPropagation();crestCheckApprove()">Yes</button>
            <button class="btn btn-sm btn-secondary" (click)="$event.stopPropagation();crestCheckReject()">No</button>
          </div>
        </ng-container>
        <div class="progress mt-2" *ngIf="showProgressSecondary">
          <div class="progress-bar progress-bar-striped bg-success" [style.width]="progressSecondary"></div>
        </div>
      </div>
      <div class="emblem-check-text">
        <p>
          Please check if the logo is correct and we can use it as shown. If you click on the "NO" button you can upload a new logo file. If you click on the “YES” button, we will use the same logo we used in the previous year.)
        </p>
      </div>
    </div>
  </div>
</div>

<app-approval-delivery-feed-item-delivery-package-actions
  [hasOpenedGallery]="hasOpenedGallery"
  [item]="item"
  (reload)="reload.emit(true)"
  [readOnly]="readOnly"
  (clone)="clone()"
  (openGallery)="open(0)"
  [form]="form"
  [checkFormValidity]="item.object.state === deliveryPackageStates.NEW"
  workflow="club_to_bd"
></app-approval-delivery-feed-item-delivery-package-actions>
