import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { DeliveryElementListViewInterface } from '@kravling/modules/core/models/View/DeliveryElementListViewInterface';

@Injectable()
export class DeliveryElementService {
  constructor(protected http: HttpClient) {}

  patch$(id, data: any): Observable<DeliveryElementListViewInterface> {
    return this.http.patch<DeliveryElementListViewInterface>(`/delivery-elements/${id}`, data);
  }
}
