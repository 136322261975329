<ul>
  <li *ngIf="showHome">
    <a href="#" [routerLink]="['/']"> {{ 'Overview' | translate }} <i class="fas fa-chevron-right"></i> </a>
    <i *ngIf="isLast" class="fas fa-chevron-right"></i>
  </li>
  <li *ngFor="let folder of rootline; let index = index">
    <app-approval-folder-rootline-item
      [folder]="folder"
      [showChildren]="showChildren && index === rootline.length - 1"
    ></app-approval-folder-rootline-item>
    <i *ngIf="rootline.length - 1 !== index" class="fas fa-chevron-right"></i>
  </li>
</ul>
