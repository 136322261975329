<form class="m10" [formGroup]="form" *ngIf="form" (submit)="handleSubmit()">
  <ng-content></ng-content>
  <div *ngIf="!isRequested; else requested">
    <bd-input [formControl]="form.get('email')" [label]="'security.login.labels.email' | translate"></bd-input>
    <div class="m10__actions">
      <button class="btn btn-primary" [disabled]="form.invalid" type="submit">
        {{ 'security.password_request.labels.request' | translate }}
      </button>
      <div class="m10__actions__link">
        <a href="#" [routerLink]="['/', currentLang, 'login']">{{
          'security.password_request.labels.back_to_login' | translate
        }}</a>
      </div>
    </div>
  </div>
  <ng-template #requested>
    <div class="alert alert-info">
      {{ 'A request has been sent. You will receive an email in a few moments.' | translate }}
    </div>
  </ng-template>
  <ngx-loading [show]="isSendingRequest"></ngx-loading>
</form>
