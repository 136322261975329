import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'delivery-page',
  templateUrl: 'delivery.page.html',
})
export class DeliveryPage implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];
  deliveryId: string;

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.subscriptions.push(
      this.route.params.subscribe((_) => {
        if (_['deliveryId']) {
          this.deliveryId = _['deliveryId'];
        }
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((_) => _.unsubscribe());
  }
}
