import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { DeliveryReadViewInterface } from '@kravling/modules/core/models/View/DeliveryReadViewInterface';
import { DeliveryService } from '@kravling/modules/core/services/delivery.service';
import { UserListViewInterface } from '@kravling/modules/core/models/View/UserListViewInterface';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  templateUrl: 'delivery-add-user.modal.html',
  selector: '<app-approval-delivery-add-user-modal>',
})
export class DeliveryAddUserModal implements OnInit, OnDestroy {
  delivery: DeliveryReadViewInterface;
  type: string;
  isSending: boolean;
  private _subscriptions: Subscription[] = [];
  form: FormGroup;

  @Output()
  onSaveSuccess = new EventEmitter<UserListViewInterface>();

  constructor(public bsModalRef: BsModalRef, private fb: FormBuilder, private deliveryService: DeliveryService) {}

  ngOnInit() {
    this.buildForm();
  }

  buildForm() {
    this.form = this.fb.group({
      email: this.fb.control(null, [Validators.email, Validators.required]),
      firstname: this.fb.control(null, [Validators.required]),
      lastname: this.fb.control(null, [Validators.required]),
      group: this.fb.control(null, [Validators.required]),
    });
  }

  onSubmit(): void {
    this.isSending = true;
    this.deliveryService
      .addApproverOrObserver$(this.delivery.id, this.type, this.form.getRawValue())
      .pipe(take(1))
      .subscribe(
        (_) => {
          this.isSending = false;
          this.onSaveSuccess.emit(_);
          this.bsModalRef.hide();
        },
        (error) => {
          // Todo
        },
      );
  }

  get canSave(): boolean {
    if (this.form && this.form.valid) return true;
    return false;
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach((_) => _.unsubscribe());
  }
}
