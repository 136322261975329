import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DeliveryElementListViewInterface } from '@kravling/modules/core/models/View/DeliveryElementListViewInterface';
import { take } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Component({
  selector: '<app-approval-delivery-element-edit>',
  template: '',
})
export class DeliveryElementEditComponent implements OnInit, OnDestroy {
  @Input() deliveryElement: DeliveryElementListViewInterface;
  @Output() onFormStatusChange = new EventEmitter<FormGroup>();

  form: FormGroup;
  isFormSubmitting = false;
  subscriptions: Subscription[] = [];

  constructor(protected fb: FormBuilder) {}

  ngOnInit() {
    this.buildForm();
  }

  buildForm() {
    this.form = this.fb.group({});
    this.subscriptions.push(
      this.form.statusChanges.subscribe((_) => {
        this.onFormStatusChange.emit(this.form);
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((_) => _.unsubscribe());
    this.subscriptions = [];
  }
}
