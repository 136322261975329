<div class="m02__time">
  <span timeago [date]="item.object.date" [live]="true"></span>
  {{ 'by' | translate }} {{ item.object.author }}
</div>
<blockquote>
  {{ item.object.text }}
</blockquote>
<div class="m02__gallery">
  <div class="m02__gallery__image" *ngFor="let attachment of item.object.attachments; let i = index">
    <app-approval-file
      [file]="attachment"
      [clickable]="true"
      (onClick)="download(attachment)"
      tooltip="{{ 'Download' | translate }}"
    ></app-approval-file>
  </div>
</div>
