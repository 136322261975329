import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DeliveryReadViewInterface } from '@kravling/modules/core/models/View/DeliveryReadViewInterface';
import { DeliveryPackageListViewInterface } from '@kravling/modules/core/models/View/DeliveryPackageListViewInterface';
import { CommentReadViewInterface } from '@kravling/modules/core/models/View/CommentReadViewInterface';
import { DeliveryFeedItemInterface } from '@kravling/modules/core/models/DeliveryFeedItemInterface';
import { DeliveryFeedType } from '@kravling/modules/core/models/DeliveryFeedType.enum';
import UserInterface from '../../../../models/interfaces/user.interface';

@Component({
  selector: 'app-delivery-feed-club-to-bd',
  templateUrl: './delivery-feed-club-to-bd.component.html',
})
export class DeliveryFeedClubToBdComponent implements OnInit {
  @Input()
  delivery: DeliveryReadViewInterface;

  @Input()
  isSuperAdmin: boolean;

  @Output()
  showEditEmblemModal = new EventEmitter<void>();

  @Input()
  selectedDeliveryPackage: {
    deliveryPackage: DeliveryPackageListViewInterface;
    index: number;
  };

  @Input()
  feed: DeliveryFeedItemInterface[];

  @Output()
  handleOnNewComment = new EventEmitter<CommentReadViewInterface>();

  @Output()
  getFeed = new EventEmitter<void>();

  @Output()
  getDelivery = new EventEmitter<void>();

  @Output()
  isUploaded = new EventEmitter<boolean>();

  @Input()
  showDetails: boolean;

  @Input()
  canRemoveUser: boolean;

  @Input()
  canAddUser: boolean;

  @Output()
  removeUser = new EventEmitter<{ user: UserInterface; users: UserInterface[]; type: string; i: number }>();

  @Output()
  openAddUserModal = new EventEmitter<{ type: string; users: UserInterface[] }>();

  @Output()
  toggleComments = new EventEmitter<void>();

   @Input()
   user: UserInterface;

  deliveryFeedType = DeliveryFeedType;

  constructor() {}

  ngOnInit() {}

  reloadDelivery() {
    this.getFeed.emit();
    this.getDelivery.emit();
  }
}
