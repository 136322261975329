import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginPage } from './login/login.page';
import { LogoutPage } from './logout/logout.page';
import { PasswordRequestPage } from './password-request/password-request.page';
import { PasswordResetPage } from './password-reset/password-reset.page';
import { NotFoundPage } from './not-found/not-found.page';
import { TranslateModule } from '@ngx-translate/core';
import { ConfirmInvitationPage } from './confirm-invitation/confirm-invitation.page';
import { SecurityModule } from '../../modules/security/security.module';

export const SecurityRoutes: Routes = [
  {
    path: ':lang/login',
    component: LoginPage,
    data: {
      routeName: 'login',
      hideSidebar: true,
      meta: {
        title: 'security.login.meta.title',
        description: 'security.login.meta.description',
        'og:description': 'security.login.meta.description',
        keywords: 'security.login.meta.tags',
      },
    },
  },
  {
    path: ':lang/logout',
    component: LogoutPage,
    data: {
      routeName: 'logout',
      hideSidebar: true,
      meta: {
        title: 'security.logout.meta.title',
        description: 'security.logout.meta.description',
        'og:description': 'security.logout.meta.description',
        keywords: 'security.logout.meta.tags',
      },
    },
  },
  {
    path: ':lang/404',
    component: NotFoundPage,
    data: {
      routeName: '404',
      meta: {
        title: 'security.not_found.meta.title',
        description: 'security.not_found.meta.description',
        'og:description': 'security.not_found.meta.description',
        keywords: 'security.not_found.meta.tags',
      },
    },
  },
  {
    path: ':lang/password-request',
    component: PasswordRequestPage,
    data: {
      routeName: 'password-request',
      hideSidebar: true,
      meta: {
        title: 'security.password_request.meta.title',
        description: 'security.password_request.meta.description',
        'og:description': 'security.password_request.meta.description',
        keywords: 'security.password_request.meta.tags',
      },
    },
  },
  {
    path: ':lang/password-reset/:hash',
    component: PasswordResetPage,
    data: {
      routeName: 'password-reset',
      hideSidebar: true,
      meta: {
        title: 'security.password_reset.meta.title',
        description: 'security.password_reset.meta.description',
        'og:description': 'security.password_reset.meta.description',
        keywords: 'security.password_reset.meta.tags',
      },
    },
  },
  {
    path: ':lang/confirm-invitation/:hash',
    component: ConfirmInvitationPage,
    data: {
      routeName: 'confirm-invitation',
      hideSidebar: true,
      meta: {
        title: 'security.confirm_invitation.meta.title',
        description: 'security.confirm_invitation.meta.description',
        'og:description': 'security.confirm_invitation.meta.description',
        keywords: 'security.confirm_invitation.meta.tags',
      },
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(SecurityRoutes), SecurityModule, TranslateModule],
  declarations: [LoginPage, LogoutPage, NotFoundPage, PasswordRequestPage, PasswordResetPage, ConfirmInvitationPage],
  exports: [
    RouterModule,
    LoginPage,
    LogoutPage,
    NotFoundPage,
    PasswordRequestPage,
    PasswordResetPage,
    ConfirmInvitationPage,
  ],
})
export class SecurityPageModule {}
