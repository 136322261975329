import { Component, Input } from '@angular/core';
import { FolderListViewInterface } from '@kravling/modules/core/models/View/FolderListViewInterface';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: '<app-approval-folder-rootline-item>',
  templateUrl: './folder-rootline-item.component.html',
})
export class FolderRootlineItemComponent {
  @Input() folder: FolderListViewInterface;
  @Input() showChildren: boolean;
  @Input() isLast: boolean;

  constructor(public translate: TranslateService) {}
}
