<form
  [formGroup]="deliveryPackageForm"
  (submit)="$event.preventDefault(); onFormStatusChange.emit(form)"
  class="comment-form"
  *ngIf="form"
>
  <app-approval-upload
    [multiple]="true"
    [form]="form.get('media')"
    [label]="'File' | translate"
    [uploader]="uploader"
  ></app-approval-upload>

  <div formArrayName="deliveryElements">
    <div *ngFor="let deliveryElementControl of deliveryPackageForm.get('deliveryElements').controls; let i = index">
      <h4>{{ deliveryPackage.deliveryElements[i].name }}</h4>
      <div class="m11">
        <div class="m11__gallery">
          <div class="m11__gallery__image">
            <app-approval-file
              [file]="getFile(deliveryElementControl.get('media').value)"
              [clickable]="false"
              [caption]="'Media'"
              [editable]="false"
            ></app-approval-file>
          </div>
          <div class="m11__gallery__image">
            <app-approval-file
              [file]="getFile(deliveryElementControl.get('preview').value)"
              [clickable]="false"
              [caption]="'Preview'"
              [editable]="false"
            ></app-approval-file>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
