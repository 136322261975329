import { Component, Input, OnInit } from '@angular/core';
import { ClubImportView } from '@content-hub/ts-models/dist/Club';

@Component({
  selector: 'import-club-row-item',
  templateUrl: './club-row-item.component.html',
})
export class ImportClubRowItemComponent implements OnInit {
  @Input()
  club: ClubImportView;

  @Input()
  rowNumber: number;

  @Input()
  clubIdArray: string[];

  isChecked = false;

  constructor() {}

  ngOnInit() {}

  toggleCheckbox(): void {
    if (!this.isChecked) {
      this.clubIdArray.push(this.club.clubFolderId);
      this.isChecked = true;
    } else {
      this.isChecked = false;
      this.clubIdArray.splice(
        this.clubIdArray.findIndex((r) => r === this.club.clubFolderId),
        1,
      );
    }
  }
}
