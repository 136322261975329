import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import MediaInterface, { MediaProviderEnum } from '../../../../models/interfaces/media.interface';

@Component({
  selector: '<app-approval-file>',
  templateUrl: './file.component.html',
})
export class FileComponent implements OnInit, OnChanges {
  @Input() file: MediaInterface;
  @Input() fallback: MediaInterface;
  @Input() caption: string;
  @Input() clickable: boolean;
  @Input() editable: boolean;
  @Output() onClick = new EventEmitter<boolean>();

  MediaProviderEnum = MediaProviderEnum;
  media: MediaInterface;

  ngOnInit(): void {
    this.handleMedia();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['file'] && changes['file'].currentValue) {
      this.handleMedia();
    }
  }

  handleMedia(): void {
    if (this.file) {
      this.media = this.file;
    } else if (!this.file && this.fallback) {
      this.media = this.fallback;
    }
  }

  handleOnClick(): void {
    if (!this.clickable) return;
    this.onClick.emit(true);
  }
}
