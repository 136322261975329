<div class="m02__time">
  <span timeago [date]="item.object.date" [live]="true"></span>
  {{ 'by' | translate }} {{ item.object.user }}
</div>

<div class="m02__info">
  <span
    class="m02__circle"
    [ngClass]="{
      'm02__circle--reopened': item.object.action === DeliveryPackageTransitions.REOPEN.toString(),
      'm02__circle--rejected': item.object.action === DeliveryPackageTransitions.REJECT.toString(),
      'm02__circle--approved': item.object.action === DeliveryPackageTransitions.APPROVE.toString()
    }"
  ></span>
  {{ item.object.message }}
</div>
