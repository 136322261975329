<ng-container *ngIf="!!deliveryPackage; else loading">
  <div>
    <div class="modal-header">
      <h5 class="modal-title">{{ 'Edit'|translate }}</h5>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="bsModalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <app-approval-delivery-package-edit-files
        [deliveryPackage]="deliveryPackage"
        (onFormStatusChange)="form = $event"
      ></app-approval-delivery-package-edit-files>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary" [disabled]="!canSave" (click)="onSave()">
        {{ 'Save'|translate }}
      </button>
      <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="bsModalRef.hide()">
        {{ 'Close'|translate }}
      </button>
    </div>
    <ngx-loading [show]="isSending"></ngx-loading>
  </div>
</ng-container>

<ng-template #loading>
  <ngx-loading [show]="true"></ngx-loading>
</ng-template>
