<span
  class="badge"
  [ngClass]="{
    'badge-info': state === DeliveryPackageStates.NEW || state === DeliveryPackageStates.DELIVERED,
    'badge-success': state === DeliveryPackageStates.APPROVED,
    'badge-danger': state === DeliveryPackageStates.REJECTED,
    'badge-secondary': state === DeliveryPackageStates.WAITING
  }"
  >
  {{ 'delivery_package.states.' + workflow + '.' + state | translate }}
</span
>
