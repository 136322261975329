<div class="m02__time">
  <div timeago [date]="item.date" [live]="true"></div>
</div>

<div class="m02__header">
  <h3>
    {{ 'Draft #' | translate }}
    <app-approval-delivery-package-status [state]="item.object.state"></app-approval-delivery-package-status>
  </h3>
  <app-approval-delivery-package-download
    class="m02__download"
    [deliveryPackage]="item.object"
    *ngIf="forceAllowDownload || item.object.state === DeliveryPackageStates.APPROVED.toString()"
  ></app-approval-delivery-package-download>
</div>
<div
  class="m02__info"
  [ngClass]="{ 'm02__info--nogallery': !showGallery }"
  *ngIf="item.object.state === DeliveryPackageStates.NEW && item.role !== Roles.DELIVERER"
>
  {{ 'Your designs will be displayed here as soon as they are available. Then you will be able to give feedback and download all designs once approved.' | translate }}
</div>
<ng-container *ngIf="showGallery">
  <div class="m02__gallery m02__gallery--{{ item.object.taskType }}">
    <div class="m02__gallery__image" *ngFor="let deliveryElement of deliveryPackageService.getVisibleDeliveryElements(item.object.deliveryElements); let i = index">
      <app-approval-file
        [file]="deliveryElement.preview"
        [fallback]="deliveryElement.media"
        [caption]="deliveryElement.name"
        [clickable]="canEdit() || canOpen()"
        [editable]="canEdit()"
        (onClick)="handleOnClick(item.object, i)"
      ></app-approval-file>
    </div>
  </div>
</ng-container>

<app-approval-delivery-feed-item-delivery-package-actions
	[hasOpenedGallery]="hasOpenedGallery"
	[item]="item"
	[readOnly]="readOnly"
	(clone)="clone()"
	(reload)="reload.emit(true)"
	(openGallery)="open(0)"
></app-approval-delivery-feed-item-delivery-package-actions>
