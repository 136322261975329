<div class="modal-header">
  <h5 class="modal-title">{{ 'Delete delivery'|translate }}</h5>
  <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <p *ngIf="deliveryListView">
    You are going to delete {{ deliveryListView.folder.name }}. Are you sure?
  </p>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-link" data-dismiss="modal" (click)="bsModalRef.hide()">
    {{ 'Close'|translate }}
  </button>
  <a (click)="$event.preventDefault();onSubmit()" href="#" class="btn btn-primary">
    {{ 'Delete'|translate }}
  </a>
</div>
<ngx-loading [show]="isSending"></ngx-loading>

<ng-template #loading>
  <ngx-loading [show]="true"></ngx-loading>
</ng-template>
