import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { DeliveryPackageListViewInterface } from '@kravling/modules/core/models/View/DeliveryPackageListViewInterface';
import { DeliveryPackageService } from '@kravling/modules/core/services/delivery-package.service';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  templateUrl: 'delivery-package-edit.modal.html',
  selector: '<app-approval-delivery-package-edit-modal>',
})
export class DeliveryPackageEditModal implements OnInit, OnDestroy {
  deliveryPackage: DeliveryPackageListViewInterface;
  form: FormGroup;
  isSending: boolean;
  private _subscriptions: Subscription[] = [];

  @Output()
  onSaveSuccess = new EventEmitter<DeliveryPackageListViewInterface>();

  constructor(
    public bsModalRef: BsModalRef,
    private _fb: FormBuilder,
    private deliveryPackageService: DeliveryPackageService,
  ) {}

  ngOnInit(): void {}

  onSave(): void {
    this.isSending = true;
    this.deliveryPackageService
      .updateMedia$(this.deliveryPackage.id, this.form.getRawValue())
      .pipe(take(1))
      .subscribe(
        (_) => {
          this.isSending = false;
          this.onSaveSuccess.emit(_);
          this.bsModalRef.hide();
        },
        (error) => {
          // Todo
        },
      );
  }

  get canSave(): boolean {
    const deliveryElements = this.form.get('deliveryElements');

    if (null !== deliveryElements) {
      const deliveryElementsWithEmptyMedia = deliveryElements.value.filter(deliveryElement => null === deliveryElement.media);

      if (deliveryElementsWithEmptyMedia.length > 0) {
        return false;
      }
    }

    return this.form && this.form.valid;
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach((_) => _.unsubscribe());
  }
}
