import { Component } from '@angular/core';
import { DeliveryElementEditComponent } from '../delivery-element-edit.component';
import { Validators } from '@angular/forms';
import { FileUploader } from 'ng2-file-upload';
import { environment } from '../../../../../../../environments/environment';
import { AuthService } from '../../../../../security/services/auth.service';

@Component({
  selector: '<app-approval-delivery-element-edit-file>',
  templateUrl: './delivery-element-edit-file.component.html',
})
export class DeliveryElementEditFileComponent extends DeliveryElementEditComponent {
  protected uploader = new FileUploader({
    url: environment.api + '/upload',
    authToken: 'Bearer ' + AuthService.user.token,
    method: 'post',
    autoUpload: true,
    headers: [
      {
        name: 'Accept',
        value: 'application/json',
      },
    ],
  });

  protected uploaderPreview = new FileUploader({
    url: environment.api + '/upload',
    authToken: 'Bearer ' + AuthService.user.token,
    method: 'post',
    autoUpload: true,
    headers: [
      {
        name: 'Accept',
        value: 'application/json',
      },
    ],
  });

  buildForm() {
    super.buildForm();
    this.form.addControl('media', this.fb.control(null, [Validators.required]));
    this.form.addControl('preview', this.fb.control(null, null));
  }
}
