import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { ImportQueueEntryView, ImportQueueView } from '@content-hub/ts-models/dist/Import';
import { map, take } from 'rxjs/operators';
import { ImportService } from '../../../services/import.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ViewportScroller } from '@angular/common';

@Component({
  selector: 'app-import-queue-entry',
  templateUrl: './import-queue-entry.component.html',
})
export class ImportQueueEntryComponent implements OnInit, OnChanges {

  importQueue: ImportQueueView;
  entry: ImportQueueEntryView;

  @Input() entryId: string;

  constructor(
    public importService: ImportService,
    private translate: TranslateService,
    private router: Router,
    private viewport: ViewportScroller
  ) {
  }

  ngOnInit() {
    this.getActiveImportQueue();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['entryId']) {
      this.getActiveImportQueue();
      this
        .viewport
        .scrollToPosition([0, 0])
      ;
    }
  }

  getActiveImportQueue(): void {
    this
      .importService
      .getActiveImportQueue$()
      .pipe(
        take(1),
        map(_ => {
          if (_.id) {
            this.importQueue = _;
            this.entry = _.entries.find(entry => {
              return entry.id === this.entryId;
            });
            if (this.entry.status === 'done') {
              this.navigateToNextOrDashboard();
            }
          }
        })
      )
      .subscribe()
    ;
  }

  get nextEntry(): ImportQueueEntryView {
    if (!this.importQueue) {
      return null;
    }
    const indexOfEntry = this
      .importQueue
      .entries
      .indexOf(
        this.entry
      )
    ;
    if (indexOfEntry + 1 > this.importQueue.entries.length) {
      return null;
    }
    return this
      .importQueue
      .entries[indexOfEntry + 1]
    ;
  }

  navigateToNextOrDashboard(): void {
    if (this.nextEntry) {
      this
        .router
        .navigate([
          '/',
          this.translate.currentLang,
          'import-queue',
          this.nextEntry.id
        ])
      ;
    } else {
      this
        .router
        .navigate([
          '/',
          this.translate.currentLang,
          'dashboard'
        ])
      ;
    }
  }


}
