<div *ngIf="!isLoading" class="form-group {{ classes.main }}">
  <div class="form-input">
    <div class="form-legend">
      <label class="form-label" *ngIf="label">
        {{ label | translate }}
      </label>
    </div>
    <div
      ng2FileDrop
      class="{{ classes.dropzone }}"
      [ngClass]="[dropZoneHoverState ? classes.dropzoneHover : '']"
      [uploader]="uploader"
      (fileOver)="dropZoneHoverState = $event"
    >
      <label class="{{ classes.dropzoneLegend }}">
        <input type="file" ng2FileSelect [uploader]="uploader" multiple />
        <ng-content></ng-content>
      </label>
    </div>
  </div>
  <div class="progress mt-2">
    <div class="progress-bar progress-bar-striped bg-success" [style.width]="progress"></div>
  </div>
</div>
