import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { FolderService } from '@kravling/modules/core/services/folder.service';
import { take } from 'rxjs/operators';
import { DeliveryListViewInterface } from '@kravling/modules/core/models/View/DeliveryListViewInterface';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'folder-page',
  template: '',
})
export class FolderPage implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];
  folderId: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private folderService: FolderService,
    private translate: TranslateService,
  ) {}

  ngOnInit(): void {
    this.subscriptions.push(
      this.route.params.subscribe((_) => {
        if (_['folderId']) {
          this.folderService
            .getDeliveriesByFolder$(_['folderId'])
            .pipe(take(1))
            .subscribe((deliveries: DeliveryListViewInterface[]) => {
              if (deliveries.length === 1)
                this.router.navigate(['/', this.translate.currentLang, 'd', deliveries[0].id]);
            });
        }
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((_) => _.unsubscribe());
  }
}
