import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DeliveryReadViewInterface } from '@kravling/modules/core/models/View/DeliveryReadViewInterface';
import { Roles } from '@kravling/modules/core/models/RolesEnum';
import UserInterface from '../../../../models/interfaces/user.interface';

@Component({
  selector: 'app-delivery-package-settings',
  templateUrl: './delivery-package-settings.component.html',
})
export class DeliveryPackageSettingsComponent implements OnInit {
  @Input()
  delivery: DeliveryReadViewInterface;

  @Input()
  showDetails: boolean;

  @Input()
  canRemoveUser: boolean;

  @Input()
  canAddUser: boolean;

  @Input()
  showCommentsSettings = true;

  @Output()
  removeUser = new EventEmitter<{ user: UserInterface; users: UserInterface[]; type: string; i: number }>();

  @Output()
  openAddUserModal = new EventEmitter<{ type: string; users: UserInterface[] }>();

  @Output()
  toggleComments = new EventEmitter<void>();

  roles = Roles;

  constructor() {}

  ngOnInit() {}
}
