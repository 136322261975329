<div class="container">
  <div class="m04">
    <app-club-packs-header
      headline="Launch suite request"
    ></app-club-packs-header>
  </div>

  <div class="card">
    <div class="card-body">
      <h3>
        {{ 'Import packs from EA SPORTS FC 24' }}
      </h3>
      <div class="alert alert-info">
        After importing, you will get the option to edit all Club data in the next step. Please import a promoted or relegated club and change the league in the next step.
      </div>
    </div>
    <div class="m14">
      <div class="m14__title">
        <i class="fa fa-table mr-1"></i> FC 24 Launch Suite
      </div>
      <div class="m14__table">
        <div class="table-data-container">
          <ngx-loading [show]="isLoadingClubs"></ngx-loading>
          <ng-container *ngIf="!isLoadingClubs">
            <import-club-row-item
              *ngFor="let club of clubs$ | async; let index = index"
              [club]="club"
              [rowNumber]="index + 1"
              [clubIdArray]="selectedArray"
            ></import-club-row-item>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="m-5 text-center">
      <a class="btn btn--big" [routerLink]="['/', translate.currentLang, 'request-packs', 'select-region']">
        {{ 'Back' }}
      </a>
      <button
        class="btn btn--big btn-primary"
        [disabled]="selectedArray.length === 0"
        (click)="$event.preventDefault();importSelected()"
      >
        {{ 'Import selected' }}
      </button>
    </div>
    <ngx-loading [show]="isSending"></ngx-loading>
  </div>
</div>

