<nav class="navbar navbar-expand-lg navbar-dark fixed-top">
  <a class="navbar-brand" href="#" [routerLink]="['/']">
    <img src="/assets/images/Logo_EA_FC.svg" alt="EASPORTS FC" />
  </a>
  <button
    class="navbar-toggler"
    type="button"
    data-toggle="collapse"
    data-target="#navbarSupportedContent"
    aria-controls="navbarSupportedContent"
    aria-expanded="false"
    aria-label="Toggle navigation"
  >
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto" *ngIf="(route.data|async) as routeData">
      <li
        class="nav-item"
        [ngClass]="{'active': routeData.routeName && routeData.routeName === 'dashboard'}"
      >
        <a class="nav-link" href="#" [routerLink]="['/']">
          <ng-container *ngIf="authService.isSuperAdmin; else useRequestsAsLabel">
            Dashboard
          </ng-container>
          <ng-template #useRequestsAsLabel>
            Requests
          </ng-template>
        </a>
      </li>
      <!-- Add permission check -->
      <li
        *ngIf="authService.isSuperAdmin"
        class="nav-item"
        [ngClass]="{'active': routeData.routeName && routeData.routeName === 'requests'}"
      >
        <a
          class="nav-link"
          href="#"
          [routerLink]="['/', translate.currentLang, 'requests']"
        >Requests</a>
      </li>
      <li
        *ngIf="authService.isSuperAdmin"
        class="nav-item"
        [ngClass]="{'active': routeData.routeName && routeData.routeName === 'legal_lines'}"
      >
        <a
          class="nav-link"
          href="#"
          [routerLink]="['/', translate.currentLang, 'legal-lines']"
        >Legal Lines</a>
      </li>
      <li
        *ngIf="authService.isSuperAdmin"
        class="nav-item"
        [ngClass]="{'active': routeData.routeName && routeData.routeName === 'budget'}"
      >
        <a
          class="nav-link"
          href="#"
          [routerLink]="['/', translate.currentLang, 'budget']"
        >Budget</a>
      </li>
      <!-- Add permission check -->
      <li
        *ngIf="authService.isSuperAdmin"
        class="nav-item"
        [ngClass]="{'active': routeData.routeName && routeData.routeName === 'requestors'}"
      >
        <a
          class="nav-link"
          href="#"
          [routerLink]="['/', translate.currentLang, 'requestors']"
        >Requestors</a>
      </li>
    </ul>
    <a class="btn btn-outline-primary btn-sm" href="#" [routerLink]="['/', translate.currentLang, 'logout']">
      {{ 'Logout' | translate }}
    </a>
  </div>
</nav>

<ng-container *ngIf="notifications$ | async as notifications">
  <ng-container *ngIf="notifications.length">
    <div class="position-fixed" style="margin-top: 70px; width: 100%; z-index: 10">
      <ng-container *ngFor="let notification of notifications">
        <app-notification [notification]="notification"></app-notification>
      </ng-container>
    </div>
  </ng-container>
</ng-container>

<div class="content">
  <ng-content></ng-content>
</div>
