import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FolderInterface } from '../models/FolderInterface';
import { HttpClient } from '@angular/common/http';
import { FolderHasLinkInterface } from '@kravling/modules/core/models/FolderHasLinkInterface';
import { DeliveryListViewInterface } from '@kravling/modules/core/models/View/DeliveryListViewInterface';
import { FolderHasLinkViewInterface } from '@kravling/modules/core/models/View/FolderHasLinkViewInterface';

@Injectable()
export class FolderService {
  constructor(private http: HttpClient) {}

  patch$(id, data: FolderInterface): Observable<FolderInterface> {
    return this.http.patch<FolderInterface>(`/folders/${id}`, data);
  }

  createLink$(id, data: any): Observable<FolderHasLinkInterface> {
    return this.http.post<FolderHasLinkInterface>(`/folders/${id}/create-link`, data);
  }

  getPublicFolder$(token): Observable<FolderHasLinkViewInterface> {
    return this.http.get<FolderHasLinkViewInterface>(`/public/folders/${token}`);
  }

  getDeliveriesByFolder$(id: string): Observable<DeliveryListViewInterface[]> {
    return this.http.get<DeliveryListViewInterface[]>(`/folders/${id}/deliveries`);
  }
}
