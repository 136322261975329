import { Component } from '@angular/core';
import { DeliveryPackageEditComponent } from '../delivery-package-edit.component';
import { FormArray, FormGroup, Validators } from '@angular/forms';
import { FileItem, FileUploader } from 'ng2-file-upload';
import { environment } from '../../../../../../../environments/environment';
import { AuthService } from '../../../../../security/services/auth.service';

@Component({
  selector: '<app-approval-delivery-package-edit-files>',
  templateUrl: './delivery-package-edit-files.component.html',
})
export class DeliveryPackageEditFilesComponent extends DeliveryPackageEditComponent {
  deliveryPackageForm: FormGroup;

  protected uploader: FileUploader;

  buildForm() {
    this.uploader = new FileUploader({
      url: environment.api + '/upload',
      authToken: 'Bearer ' + AuthService.user.token,
      method: 'post',
      autoUpload: true,
      headers: [
        {
          name: 'Accept',
          value: 'application/json',
        },
      ],
    });

    var self = this;
    super.buildForm();

    this.form.addControl(
      'media',
      this.fb.control(
        [],
        [Validators.required, Validators.minLength(this.deliveryPackage.deliveryElements.length * 2)],
      ),
    );

    const controls = this.fb.array([]);
    this.deliveryPackage.deliveryElements.forEach((deliveryElement) => {
      controls.push(
        this.fb.group({
          id: this.fb.control(deliveryElement.id),
          media: this.fb.control(deliveryElement.media ? deliveryElement.media.id : null),
          preview: this.fb.control(deliveryElement.preview ? deliveryElement.preview.id : null),
        }),
      );
    });

    this.deliveryPackageForm = this.fb.group({
      deliveryElements: controls,
    });

    this.uploader.onCompleteItem = function (item: FileItem) {
      self.mapMedia();
    };

    this.uploader.onCompleteAll = function () {
      self.mapMedia();
      self.onFormStatusChange.emit(self.deliveryPackageForm);
    };
  }

  /**
   *
   * @param id
   */
  getFile(id: number): any {
    const file = this.uploader.queue.find((_) => {
      if (!_._file) {
        return false;
      }
      return id === _._file['id'];
    });
    if (file) {
      return file._file;
    }
    return null;
  }

  /**
   *
   */
  mapMedia(): void {
    this.uploader.queue
      .sort((a: FileItem, b: FileItem) => {
        return a.file.name < b.file.name ? -1 : 1;
      })
      .forEach((fileItem: FileItem, i: number) => {
        const deliveryElements = <FormArray>this.deliveryPackageForm.get('deliveryElements');
        let index: number;
        if (i % 2 === 0) {
          index = i / 2;
        } else if (i % 2 === 1) {
          index = (i - 1) / 2;
        }
        const control = i % 2 === 0 ? 'media' : 'preview';
        if (fileItem._file['id']) deliveryElements.at(index).get(control).setValue(fileItem._file['id']);
      });
  }
}
