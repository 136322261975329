import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import RequestorView from '@content-hub/ts-models/src/User/Requestor/requestor.view';

@Injectable()
export class RequestorService {

  private _requestors$ = new BehaviorSubject<RequestorView[]>([]);

  constructor(protected http: HttpClient) {}

  get requestors$() {
    return this._requestors$.asObservable();
  }

  create$(data): Observable<any> {
    return this.http.post<any>(`/requestor`, data);
  }

  update$(id: string, data): Observable<any> {
    if (typeof data.email !== 'undefined') {
      delete data.email;
    }
    return this.http.patch<any>(`/requestor/` + id, data);
  }

  updateRequestors(): void {
    this
      .http
      .get<RequestorView[]>('/requestors')
      .pipe(
        take(1),
        map(_ => {
          this._requestors$.next(_);
        })
      )
      .subscribe()
    ;
  }
}
