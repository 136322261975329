<div class="m03__wrapper">
  <div class="m03__wrapper__header" *ngIf="title">
    <h2>{{ title }}</h2>
    <div class="m03__wrapper__header__actions">
      <app-approval-delivery-package-download
        btnClass="btn btn-link"
        class="m03__wrapper__header__actions__download"
        [deliveryPackage]="deliveryPackage"
        *ngIf="
          forceAllowDownload || deliveryPackage.deliveryPackage.state === DeliveryPackageStates.APPROVED.toString()
        "
      ></app-approval-delivery-package-download>
      <a
        href="#"
        class="m03__wrapper__header__actions__close"
        title="{{ 'Close' | translate }}"
        (click)="$event.preventDefault(); close()"
      >
        <i class="fa fa-times"></i>
      </a>
    </div>
  </div>
  <ngx-gallery
    [options]="galleryOptions"
    [images]="galleryImages"
    (change)="onChange($event)"
    #gallery
  ></ngx-gallery>
</div>
