<!--
  ~ @author Felix Baltruschat <fb@braune-digital.com>
  ~ @copyright 2/5/19 4:42 PM Braune Digital GmbH
  -->

<div *ngIf="!isLoading" class="form-group {{ classes.main }}">

    <div class="form-input">
        <div class="form-legend">
            <label class="form-label" *ngIf="label">
                {{ label | translate }}
            </label>
        </div>
        <div
            ng2FileDrop
            class="{{ classes.dropzone }}"
            [ngClass]="[ (dropZoneHoverState) ? classes.dropzoneHover : '' ]"
            [uploader]="uploader"
            (fileOver)="dropZoneHoverState = $event;"
        >
            <label class="{{ classes.dropzoneLegend }}">
                <span *ngIf="!isAudio && !isFile">
                    <strong *ngIf='uploader.queue?.length === 0'>
                        {{ 'form.fields.uploader.upload' | translate }}
                    </strong>
                    <strong *ngIf='uploader.queue?.length > 0 && !multiple'>
                        {{ 'form.fields.uploader.replace' | translate }}
                    </strong>
                    <strong *ngIf='uploader.queue?.length > 0 && multiple'>
                        {{ 'form.fields.uploader.add' | translate }}
                    </strong>
                    {{ 'form.fields.uploader.howto' | translate }}
                </span>
                <span *ngIf="isAudio">
                    <strong *ngIf='uploader.queue?.length === 0'>
                        {{ 'form.fields.uploader.upload_audio' | translate }}
                    </strong>
                    <strong *ngIf='uploader.queue?.length > 0'>
                        {{ 'form.fields.uploader.replace_audio' | translate }}
                    </strong>
                    {{ 'form.fields.uploader.howto_audio' | translate }}
                </span>
                <span *ngIf="isFile">
                    <strong *ngIf='uploader.queue?.length === 0'>
                        {{ 'form.fields.uploader.upload_file' | translate }}
                    </strong>
                    <strong *ngIf='uploader.queue?.length > 0'>
                        {{ 'form.fields.uploader.replace_file' | translate }}
                    </strong>
                    {{ 'form.fields.uploader.howto_file' | translate }}
                </span>
                <input type="file" ng2FileSelect [uploader]="uploader" multiple>
                <ng-content></ng-content>
            </label>
        </div>
        <div class="form-help" *ngIf="help">
            {{ help }}
        </div>
    </div>

</div>


<ng-template #templateUploaderModal>
    <div *ngIf="modalImageItem">
        <bd-content-modal title="Edit {{ modalImageItem.file.name }}" [modalRef]="modalRef">
          <bd-content-modal-main>
            <form [formGroup]="modalForm" *ngIf="modalForm" (submit)="handleModalSubmit(modalImageItem._file['id'])">

                <bd-input [ignoreTouchedForErrors]="true"
                          [label]="'form.fields.image.file_name' | translate"
                          [placeholder]="'form.placeholder.image.file_name' | translate"
                          [formControl]="modalForm.get('name')"></bd-input>

                <bd-input [ignoreTouchedForErrors]="true"
                          [label]="'form.fields.image.copyright' | translate"
                          [placeholder]="'form.placeholder.image.copyright' | translate"
                          [formControl]="modalForm.get('copyright')"></bd-input>

                <bd-textarea [ignoreTouchedForErrors]="true"
                             [formControl]="modalForm.get('description')"
                             [placeholder]="'form.placeholder.image.description' | translate"
                             [label]="'form.fields.image.description' | translate"
                             maxLength="300"
                             [help]="'form.fields.image.description' | translate">
                </bd-textarea>

                <bd-progress-button
                    [btnStateLoading]="isSendingRequest"
                    btnType="submit"
                    [btnDisabled]="form.invalid"
                    btnClass="btn--primary">
                    {{ 'form.actions.save' | translate }}
                </bd-progress-button>
            </form>
          </bd-content-modal-main>
        </bd-content-modal>
    </div>
</ng-template>
