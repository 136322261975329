<div
  class="text-white text-center py-2 px-2 position-relative"
  [ngClass]="{ 'bg-danger': notification.severity === 'error', 'bg-success': notification.severity === 'info' }"
>
  <p class="m-0" style="padding: 0 2rem">{{ notification.message }}</p>
  <button
    class="position-absolute"
    style="all: unset; right: 1rem; top: 50%; transform: translateY(-50%); font-size: 1.05rem; cursor: pointer"
    (click)="close()"
  >
    <i class="fa fa-times"></i>
  </button>
</div>
