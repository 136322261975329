import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BdKravCoreModule } from '../core/core.module';
import { TranslateModule } from '@ngx-translate/core';
import { BdFilterModule } from '@braune-digital/angular-query-filter';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { FormUtilitiesModule } from '@braune-digital/form-utilities';

@NgModule({
  imports: [
    BdKravCoreModule,
    BdFilterModule,
    TranslateModule,
    ReactiveFormsModule,
    CommonModule,
    FormUtilitiesModule,
  ],
  declarations: [],
  providers: [],
  exports: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class BdKravDeliverModule {}
