<div class="container m03" [ngClass]="{ 'm03--open': !!selectedDeliveryPackage }">
  <div class="m01" *ngIf="delivery">
    <div class="m08">
      <div class="m08__image">
        <img
          [src]="delivery.folder.image?.path || 'assets/images/team_emblem_placeholder.png'"
          alt="{{ delivery.folder.name }}"
        />

        <button *ngIf="isSuperAdmin" class="edit-emblem" (click)="showEditEmblemModal.emit()">
          <i class="fa fa-pencil"></i>
        </button>
      </div>

      <div class="m08__text">
        <app-approval-folder-rootline class="m06" [rootline]="delivery.rootline"></app-approval-folder-rootline>
        <h2>
          {{ delivery.folder.name }} <ng-container *ngIf="delivery.isImageDelivery"> - Photo Delivery</ng-container>
          <app-approval-delivery-status
            [state]="delivery.state"
            workflow="club_to_bd"
          ></app-approval-delivery-status>
        </h2>
        <i
          class="m08__text__detail-cta fas fa-info-circle"
          [ngClass]="{ 'm08__text__detail-cta--open': !!showDetails }"
          (click)="showDetails = !showDetails"
          tooltip="{{ 'Information' }}"
        ></i>

        <app-delivery-package-settings
          [showCommentsSettings]="false"
          [delivery]="delivery"
          (toggleComments)="toggleComments.emit($event)"
          [canAddUser]="canAddUser"
          (openAddUserModal)="openAddUserModal.emit($event)"
          (removeUser)="removeUser.emit($event)"
          [canRemoveUser]="canRemoveUser"
          [showDetails]="showDetails"
        ></app-delivery-package-settings>
      </div>
    </div>

    <div class="m07">

      <div class="description-text">
        <div class="text-block">
          <div>
            <p>
              For the <strong>main photo</strong> on the front, we have two approaches (please look through the examples on the right). If you are able supply player photos, the main photo should feature a single heroic team shot (e.g. a team huddle or celebration scene). <strong>Important:</strong> Please ensure that the image includes more than one player. Alternatively, you can choose to upload a main photo, that captures the vibrant fandom and atmosphere of the stadium. This can include banners, flags, and tifos that represent the club's spirit and energy.
            </p>
            <p>
              The second image on the back of the pack can be either a view of the stadium or a close up shot of the fandom (capturing the atmosphere and unity in the stadium).
            </p>
          </div>
          <!--<p class="footnote">
            <strong>Important: The design draft is still highly confidential and is not to be shared anywhere yet.</strong>
          </p>-->
        </div>

        <div class="example-image">
          <!--<img src="/assets/images/case_example_fc25_mancity.png" alt="Example" />
          <div class="example-badge">Example</div>-->
          <splide>
            <splide-slide>
              <img src="/assets/images/example-1.png" alt="Example 1" />
            </splide-slide>
            <splide-slide>
              <img src="/assets/images/example-2.png" alt="Example 2" />
            </splide-slide>
            <splide-slide>
              <img src="/assets/images/example-3.png" alt="Example 3" />
            </splide-slide>
            <splide-slide>
              <img src="/assets/images/example-4.png" alt="Example 4" />
            </splide-slide>
          </splide>
          <p class="example-image-caption">
            Placeholder designs.<br/>Only for illustrative purposes.
          </p>

        </div>
      </div>
      <div *ngIf="delivery.enableComments">
        <div class="alert alert-primary">
          If you want to contact us, please use the following comment field. Please reach out, if you have questions regarding the photo upload or the club launch suite in general.
        </div>
        <app-approval-comment-form
          [delivery]="delivery"
          *ngIf="delivery.enableComments"
          (onNewComment)="handleOnNewComment.emit($event)"
          [commentPlaceholder]="'comment.field.new_comment' | translate"
        ></app-approval-comment-form>
      </div>
    </div>

    <div *ngIf="feed && feed.length > 0">
      <div *ngFor="let feedItem of feed" class="m02">
        <ng-container [ngSwitch]="feedItem.type">
          <app-feed-type-delivery-club
            *ngSwitchCase="deliveryFeedType.DELIVERY_PACKAGE"
            [item]="feedItem"
            [delivery]="delivery"
            (reload)="reloadDelivery()"
            (onSelectDeliveryPackage)="selectedDeliveryPackage = $event"
            (isUploaded)="isUploaded.emit()"
          ></app-feed-type-delivery-club>

          <app-approval-delivery-feed-item-comment
            *ngSwitchCase="deliveryFeedType.COMMENT"
            [item]="feedItem"
            [delivery]="delivery"
            (reload)="getFeed.emit()"
          ></app-approval-delivery-feed-item-comment>
          <app-approval-delivery-feed-item-activity
            *ngSwitchCase="deliveryFeedType.ACTIVITY"
            [item]="feedItem"
            [delivery]="delivery"
            (reload)="getFeed.emit()"
          ></app-approval-delivery-feed-item-activity>
        </ng-container>
      </div>
    </div>

    <div class="m01__actions">
      <a href="#" [routerLink]="['/']" title="{{ 'Back to overview' | translate }}"
        ><i class="fa fa-arrow-left"></i> {{ 'Back to overview' | translate }}</a
      >
    </div>
  </div>
  <app-approval-delivery-package-gallery
    class="m03__gallery"
    *ngIf="selectedDeliveryPackage"
    [deliveryPackage]="selectedDeliveryPackage"
    (onClose)="selectedDeliveryPackage = null"
    [forceAllowDownload]="delivery.allowDownloadOnEveryState"
  ></app-approval-delivery-package-gallery>
</div>
