import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { map, take } from 'rxjs/operators';
import { RegionService } from '../../../services/region.service';
import { RegionView } from '@content-hub/ts-models/dist/Region';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-select-region',
  templateUrl: './select-region.component.html',
})
export class SelectRegionComponent implements OnInit, OnDestroy {

  currentView: string;
  regions$ = new BehaviorSubject<RegionView[]>([]);
  selected: RegionView = null;

  constructor(
    public translate: TranslateService,
    private regionService: RegionService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.getUserRegions();
  }

  getUserRegions(): void {
    this
      .regionService
      .getUserRegions$()
      .pipe(
        take(1),
        map(_ => {
          this.regions$.next(_);
        })
      )
      .subscribe()
    ;
  }

  navigateToImport(region: RegionView): void {
    this
      .router
      .navigate([
        '/',
        this.translate.currentLang,
        'request-packs',
        'import',
        region.id
      ])
    ;
  }

  ngOnDestroy(): void {
    this.selected = null;
    this.regions$.next([]);
  }


}
