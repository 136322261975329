import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'club-row-item',
  templateUrl: './club-row-item.component.html',
})
export class ClubRowItemComponent implements OnInit {
  @Input()
  club: any;

  @Input()
  rowNumber: number;

  @Input()
  rowArray: number[];

  isChecked = false;

  constructor() {}

  ngOnInit() {}

  toggleCheckbox(): void {
    if (!this.isChecked) {
      this.rowArray.push(this.rowNumber);
      this.isChecked = true;
    } else {
      this.isChecked = false;
      this.rowArray.splice(
        this.rowArray.findIndex((r) => r === this.rowNumber),
        1,
      );
    }
  }
}
