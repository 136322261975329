import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BdKravCoreModule } from './modules/core/core.module';
import { BdKravDeliverModule } from './modules/deliver/deliver.module';
import { BdKravRequestModule } from './modules/request/request.module';
import { BdKravValidateModule } from './modules/validate/validate.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    BdKravCoreModule,
    BdKravDeliverModule,
    BdKravRequestModule,
    BdKravValidateModule,
    BdKravCoreModule,
    TranslateModule,
  ],
  declarations: [],
  providers: [],
  exports: [BdKravCoreModule, BdKravDeliverModule, BdKravCoreModule, BdKravRequestModule, BdKravValidateModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class BdKravlingModule {}
