import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { LegalLineView } from '@content-hub/ts-models/dist/LegalLine';

@Injectable()
export class LegalLineService {

  private _legalLines$ = new BehaviorSubject<LegalLineView[]>([]);

  constructor(protected http: HttpClient) {}

  get legalLines$() {
    return this._legalLines$.asObservable();
  }

  create$(data): Observable<any> {
    return this.http.post<any>(`/legal-line`, data);
  }

  update$(id: string, data): Observable<any> {
    return this.http.patch<any>(`/legal-line/` + id, data);
  }

  fetchLegalLines(): void {
    this
      .http
      .get<LegalLineView[]>('/legal-lines')
      .pipe(
        take(1),
        map(_ => {
          this._legalLines$.next(_);
        })
      )
      .subscribe()
    ;
  }

}
