<ng-container *ngIf="!!form; else loading">
  <form [formGroup]="form" (submit)="onSubmit()" class="comment-form" *ngIf="form">
    <div class="modal-header">
      <h5 class="modal-title">
        <ng-container *ngIf="requestor; else titleForNewRequestor">
          {{ 'Edit requestor'|translate }}
        </ng-container>
        <ng-template #titleForNewRequestor>
          {{ 'Invite a new requestor'|translate }}
        </ng-template>
      </h5>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="bsModalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col-md-6">
          <bd-input
            [formControl]="form.get('firstName')"
            placeholder="Firstname"
          ></bd-input>
        </div>
        <div class="col-md-6">
          <bd-input
            [formControl]="form.get('lastName')"
            placeholder="Lastname"
          ></bd-input>
        </div>
      </div>
      <bd-input
        [readOnly]="!!requestor"
        [formControl]="form.get('email')"
        placeholder="Email"
      ></bd-input>

      <ng-select
        bdSelect
        class="mb-0"
        [formControl]="form.get('regions')"
        bindValue="id"
        [virtualScroll]="true"
        bindLabel="name"
        [disabled]="isSending"
        [multiple]="true"
        [items]="regionService.regions$ | async"
        placeholder="Region (optional)"
      ></ng-select>

    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-link" data-dismiss="modal" (click)="bsModalRef.hide()">
        {{ 'Close'|translate }}
      </button>
      <button type="button" class="btn btn-primary" [disabled]="!canSave" type="submit">
        <ng-container *ngIf="requestor; else submitForNewRequestor">
          {{ 'Edit requestor'|translate }}
        </ng-container>
        <ng-template #submitForNewRequestor>
          {{ 'Send invite'|translate }}
        </ng-template>
      </button>
    </div>
    <ngx-loading [show]="isSending"></ngx-loading>
  </form>
</ng-container>

<ng-template #loading>
  <ngx-loading [show]="true"></ngx-loading>
</ng-template>
