import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommentatorService } from './services/commentator.service';
import { DeliveryService } from './services/delivery.service';
import { DeliveryElementService } from './services/deliveryElement.service';
import { ElementService } from './services/element.service';
import { FolderService } from './services/folder.service';
import { RequestService } from './services/request.service';
import { TasksService } from './services/tasks.service';
import { UserService } from './services/user.service';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { BdFilterModule } from '@braune-digital/angular-query-filter';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormUtilitiesModule } from '@braune-digital/form-utilities';
import { TimeagoModule } from 'ngx-timeago';
import { TransitionService } from './services/transition.service';
import { KravFormElementDirective } from './directives/krav-form-element/krav-form-element.directive';
import { LightboxModule } from 'ngx-lightbox';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DeliveryPackageService } from '@kravling/modules/core/services/delivery-package.service';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ModalModule } from 'ngx-bootstrap/modal';
import { AlertService } from '@kravling/modules/core/services/alert.service';

@NgModule({
  imports: [
    TranslateModule,
    CommonModule,
    BdFilterModule,
    TooltipModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    FormsModule,
    FormUtilitiesModule,
    TimeagoModule,
    ModalModule,
    LightboxModule,
  ],
  declarations: [
    // Krav Form Elements
    KravFormElementDirective,
  ],
  providers: [
    CommentatorService,
    DeliveryService,
    DeliveryElementService,
    ElementService,
    FolderService,
    RequestService,
    TasksService,
    UserService,
    TransitionService,
    DeliveryPackageService,
    AlertService
  ],
  exports: [
    // Krav Form Elements
    KravFormElementDirective,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class BdKravCoreModule {}
