import { Component, OnDestroy, OnInit } from '@angular/core';
import { LegalLineService } from '../../services/legal-line.service';
import { InviteOrEditRequestorModal } from '../modal/invite-or-edit-requestor/invite-or-edit-requestor.modal';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { LegalLineView } from '@content-hub/ts-models/dist/LegalLine';
import { CreateOrEditLegalLineModal } from '../modal/create-or-edit-legal-line/create-or-edit-legal-line.modal';

@Component({
  selector: 'app-legal-line-list',
  templateUrl: './legal-line-list.component.html',
})
export class LegalLineListComponent implements OnInit, OnDestroy {

  modal: BsModalRef;

  constructor(
    public legalLineService: LegalLineService,
    private modalService: BsModalService
  ) {
  }

  ngOnInit() {
    this.refresh();
  }

  openCreateOrEditLegalLineModal(legalLine?: LegalLineView): void {
    this.modal = this.modalService.show(CreateOrEditLegalLineModal, {
      class: '',
    });
    this.modal.content.legalLine = legalLine;
    this.modal.content.onSaveSuccess.subscribe(() => {
      this
        .refresh()
      ;
    });
  }

  refresh(): void {

    this
      .legalLineService
      .fetchLegalLines()
    ;
  }

  ngOnDestroy(): void {
  }


}
