export const environment = {
  production: false,
  hmr: false,
  api: 'API_SERVER_URL',
  version: '1.0',
  defaultLocale: 'en',
  defaultPage: {
    ROLE_USER: ['en'],
  },
};
