import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ImportService } from '../services/import.service';
import { take } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class ImportQueueGuard implements CanActivate {

  constructor(
    private importService: ImportService,
    private router: Router,
    private translate: TranslateService,
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    return new Promise<boolean>((resolve, reject) => {
      this
        .importService
        .getActiveImportQueue$()
        .pipe(
          take(1),
        )
        .subscribe(_ => {
          if (_.id && _.entries.length > 0) {

            const uncompletedEntries = _
              .entries
              .filter(_ => _.status === 'created')
            ;
            if (uncompletedEntries.length === 0) {
              resolve(true);
            } else {
              this.router.navigate([
                '/',
                this.translate.currentLang,
                'import-queue',
                uncompletedEntries[0].id
              ]);
              resolve(false);
            }
          } else {
            resolve(true);
          }
        }, error => {
          resolve(false);
        })
      ;
    });
  }

}
