import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { take } from 'rxjs/operators';
import { DeliveryService } from '@kravling/modules/core/services/delivery.service';
import { UserListViewInterface } from '@kravling/modules/core/models/View/UserListViewInterface';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { DeliveryListViewInterface } from '@kravling/modules/core/models/View/DeliveryListViewInterface';

@Component({
  templateUrl: 'delete-delivery.modal.html',
  selector: '<app-approval-delete-delivery-modal>',
})
export class DeleteDeliveryModal implements OnDestroy {
  type: string;
  isSending: boolean;
  deliveryListView: DeliveryListViewInterface;

  @Output()
  onSaveSuccess = new EventEmitter<UserListViewInterface>();

  constructor(public bsModalRef: BsModalRef,
              private fb: FormBuilder,
              private deliveryService: DeliveryService) {}

  onSubmit(): void {
    this.isSending = true;
    this.deliveryService
      .delete$(this.deliveryListView.id)
      .pipe(take(1))
      .subscribe(
        (_) => {
          this.isSending = false;
          this.onSaveSuccess.emit(_);
          this.bsModalRef.hide();
        },
        (error) => {
          // Todo
        },
      );
  }
  ngOnDestroy(): void {
  }
}
