<ng-container *ngIf="!!form; else loading">
  <form [formGroup]="form" (submit)="onSubmit()" class="comment-form" *ngIf="form">
    <div class="modal-header">
      <h5 class="modal-title">{{ 'Edit'|translate }}</h5>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="bsModalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <bd-input [formControl]="form.get('email')" [label]="'Email' | translate"></bd-input>

      <bd-input [formControl]="form.get('firstname')" [label]="'Firstname' | translate"></bd-input>

      <bd-input [formControl]="form.get('lastname')" [label]="'Lastname' | translate"></bd-input>

      <bd-input [formControl]="form.get('group')" [label]="'Group' | translate"></bd-input>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-link" data-dismiss="modal" (click)="bsModalRef.hide()">
        {{ 'Close'|translate }}
      </button>
      <button type="button" class="btn btn-primary" [disabled]="!canSave" type="submit">
        {{ 'Invite'|translate }}
      </button>
    </div>
    <ngx-loading [show]="isSending"></ngx-loading>
  </form>
</ng-container>

<ng-template #loading>
  <ngx-loading [show]="true"></ngx-loading>
</ng-template>
