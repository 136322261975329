import { Component, HostBinding, Input } from '@angular/core';
import { FeedItemComponent } from '../feed-item.component';
import { ActivityFeedItemInterface } from '@kravling/modules/core/models/ActivityFeedItemInterface';
import { DeliveryPackageTransitions } from '@kravling/modules/core/models/DeliveryPackageInterface';

@Component({
  selector: '<app-approval-delivery-feed-item-activity>',
  templateUrl: './feed-type-activity.component.html',
})
export class FeedTypeActivityComponent extends FeedItemComponent {
  @Input() item: ActivityFeedItemInterface;

  DeliveryPackageTransitions = DeliveryPackageTransitions;

  @HostBinding('class')
  get hostClasses(): string {
    return 'm02__activity';
  }
}
