<div class="container">
  <div class="row">
    <div class="col-sm-9 col-md-7 col-lg-5 mx-auto">
      <div class="card card-signin my-5">
        <div class="card-body">
          <div class="text-center mb-3">
            <img src="/assets/images/ea.svg" alt="EA" width="100" />
          </div>
          <h5 class="card-title text-center">
            {{ 'Password Reset' | translate }}
          </h5>
          <p>
            {{
              'Please type in your new password. Use a secure password with random numbers, lower-/uppercase and symbols.'
                | translate
            }}
          </p>
          <security-password-reset-component></security-password-reset-component>
        </div>
      </div>
    </div>
  </div>
</div>
