import { Component, EventEmitter, HostBinding, Input, OnDestroy, Output } from '@angular/core';
import { DeliveryPackageFeedItemInterface } from '@kravling/modules/core/models/DeliveryPackageFeedItemInterface';
import { FeedItemComponent } from '../feed-item.component';
import { DeliveryElementEditModal } from '../../modal/delivery-element-edit/delivery-element-edit.modal';
import { DeliveryElementListViewInterface } from '@kravling/modules/core/models/View/DeliveryElementListViewInterface';
import { DeliveryElementStates } from '@kravling/modules/core/models/DeliveryElementInterface';
import { Roles } from '@kravling/modules/core/models/RolesEnum';
import {
  DeliveryPackageStates,
  DeliveryPackageTransitions,
} from '@kravling/modules/core/models/DeliveryPackageInterface';
import { DeliveryPackageService } from '@kravling/modules/core/services/delivery-package.service';
import { take } from 'rxjs/operators';
import { DeliveryPackageListViewInterface } from '@kravling/modules/core/models/View/DeliveryPackageListViewInterface';
import { DeliveryPackageEditModal } from '../../modal/delivery-package-edit/delivery-package-edit.modal';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: '<app-approval-delivery-feed-item-delivery-package>',
  templateUrl: './feed-type-delivery-package.component.html',
})
export class FeedTypeDeliveryPackageComponent extends FeedItemComponent implements OnDestroy {
  @Input() item: DeliveryPackageFeedItemInterface;
  @Input() readOnly: boolean = false;
  @Input() forceAllowDownload: boolean = false;
  @Output() onSelectDeliveryPackage = new EventEmitter<{
    deliveryPackage: DeliveryPackageListViewInterface;
    index: number;
  }>();

  @HostBinding('class')
  get hostClasses(): string {
    let classes = 'm02__delivery-package';
    classes += ' m02__delivery-package--' + this.item.object.state;
    classes += ' m02__delivery-package--role-' + this.item.role;
    return classes;
  }

  deliveryElementEditModal: BsModalRef;
  Roles = Roles;
  DeliveryPackageStates = DeliveryPackageStates;
  DeliveryPackageTransitions = DeliveryPackageTransitions;
  hasOpenedGallery: boolean;

  constructor(modalService: BsModalService, public deliveryPackageService: DeliveryPackageService) {
    super(modalService);
  }

  openDeliveryElementEditModal(deliveryElement: DeliveryElementListViewInterface, i: number): void {
    if (!this.canEdit()) return;
    this.deliveryElementEditModal = this.modalService.show(DeliveryElementEditModal, {
      class: 'modal--default',
    });
    this.deliveryElementEditModal.content.deliveryElement = deliveryElement;
    this.deliveryElementEditModal.content.onSaveSuccess.subscribe((_: DeliveryElementListViewInterface) => {
      this.item.object.deliveryElements[i] = _;
      if (this.areAllDeliveryElementsCompleted) this.reload.emit(true);
    });
  }

  openDeliveryPackageEditModal(i: number): void {
    if (!this.canEdit()) return;
    this.deliveryElementEditModal = this.modalService.show(DeliveryPackageEditModal, {
      class: 'modal--default',
    });
    this.deliveryElementEditModal.content.deliveryPackage = this.item.object;
    this.deliveryElementEditModal.content.onSaveSuccess.subscribe((_) => {
      this.item.object = _;
      this.reload.emit(true);
    });
  }

  areAllDeliveryElementsCompleted(): boolean {
    return this.item.object.deliveryElements.some((_) => _.state === DeliveryElementStates.COMPLETED);
  }

  /**
   * canEdit
   */
  canEdit(): boolean {
    if (!this.item.object.isCurrent) return false;
    if (this.item.role === Roles.DELIVERER) {
      if (this.item.object.state === DeliveryPackageStates.APPROVED) return false;
      if (this.item.object.state === DeliveryPackageStates.REJECTED) return false;
      if (this.item.object.actions.includes(DeliveryPackageTransitions.REJECT)) return false;
      if (this.item.object.actions.includes(DeliveryPackageTransitions.REOPEN)) return false;
      return true;
    }
    return false;
  }

  /**
   * canOpen
   */
  canOpen(): boolean {
    return true;
  }

  /**
   * @param deliveryPackage
   * @param i
   */
  handleOnClick(deliveryPackage: DeliveryPackageListViewInterface, i: number): void {
    if (this.canEdit()) {
      this.openDeliveryPackageEditModal(i);
    } else {
      this.open(i);
    }
  }

  get showGallery(): boolean {
    return this.item.object.state !== DeliveryPackageStates.NEW || this.item.role === Roles.DELIVERER.toString();
  }

  /**
   * canOpenGallery
   */
  canOpenGallery(): boolean {
    if (this.item.role === Roles.DELIVERER) {
      if (this.item.object.state === DeliveryPackageStates.REJECTED) return true;
      return false;
    }
    return true;
  }

  clone(): void {
    this.deliveryPackageService
      .clone$(this.item.object.id)
      .pipe(take(1))
      .subscribe((_) => {
        this.reload.emit(true);
      });
  }

  open(i: number): void {
    this.hasOpenedGallery = true;
    this.onSelectDeliveryPackage.emit({
      deliveryPackage: this.item.object,
      index: i,
    });
  }

  ngOnDestroy(): void {
    this.hasOpenedGallery = null;
  }
}
