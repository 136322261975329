import { Component, OnDestroy, OnInit } from '@angular/core';
import { BudgetService } from '../../services/budget.service';

@Component({
  selector: 'app-budget',
  templateUrl: './budget.component.html',
})
export class BudgetComponent implements OnInit {

  constructor(
    public budgetService: BudgetService
  ) {
  }

  ngOnInit() {
    this.refresh();
  }

  refresh(): void {
    this
      .budgetService
      .fetchBudget()
    ;
  }


}
