<div class="m02__actions" *ngIf="(!readOnly && item.object?.actions?.length) || canClone">
  <p class="m02__actions__info" *ngIf="showGalleryInfo" (click)="openGallery.emit(true)">
    <i class="fa fa-exclamation-circle"></i>
    {{
      'Before you can approve or reject the designs, you must have opened the gallery. Please click on one of the images.'
        | translate
    }}
  </p>
  <div *ngIf="item.object.actions.length">
    <a
      class="btn"
      *ngFor="let action of item.object.actions"
      [ngClass]="{
        'btn-success': action === DeliveryPackageTransitions.APPROVE || action === DeliveryPackageTransitions.DELIVER,
        'btn-danger': action === DeliveryPackageTransitions.REJECT || action === DeliveryPackageTransitions.REOPEN,
        'btn-disabled': isTransitionDisabled(action) || (checkFormValidity && form && !form.valid)
      }"
      href="#"
      (click)="$event.preventDefault(); openTransitionModal(action)"
    >
      {{ 'delivery_package.transitions.cta.' + workflow + '.' + action | translate: { count: this.deliveryPackageService.getVisibleDeliveryElements(item.object.deliveryElements).length } }}
    </a>
  </div>
  <a *ngIf="canClone" href="#" class="btn btn-primary" (click)="$event.preventDefault(); clone.emit(true)">
    {{ 'Copy and update' | translate }}
  </a>
</div>
