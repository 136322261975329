import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class TransitionService {
  constructor(private http: HttpClient) {}

  do$<T>(type: string, id: string, transition: string, graph: string): Observable<T> {
    return this.http.patch<T>(`/states/${type}/${id}/do/${transition}`, {}, { params: { graph: graph } });
  }

  can$(type: string, id: string, graph: string): Observable<Array<string>> {
    return this.http.get<Array<string>>(`/states/${type}/${id}/possible-transitions`, { params: { graph: graph } });
  }
}
