import { Component } from '@angular/core';
import { DeliveryListComponent } from '../delivery-list/delivery-list.component';
import { take } from 'rxjs/operators';
import { DeliveryService } from '@kravling/modules/core/services/delivery.service';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { BsModalService } from 'ngx-bootstrap/modal';
import { RequestorService } from '../../services/requestor.service';
import { RequestorView } from '@content-hub/ts-models/dist/User/Requestor';
import { FolderService } from '../../services/folder.service';
import { DeliveryListViewInterface } from '@kravling/modules/core/models/View/DeliveryListViewInterface';
import { DeleteDeliveryModal } from '../modal/delete-delivery/delete-delivery.modal';

@Component({
  selector: '<app-approval-admin-delivery-list>',
  templateUrl: './admin-delivery-list.component.html',
})
export class AdminDeliveryListComponent extends DeliveryListComponent {

  listUrl = '/deliveries/all';
  resultsPerPage = 50;
  hidePagination = false;
  currentRequestorFilter: string;
  selectedRequestor: RequestorView;

  constructor(
    public deliveryService: DeliveryService,
    public translate: TranslateService,
    protected http: HttpClient,
    protected modalService: BsModalService,
    protected folderService: FolderService,
    public requestorService: RequestorService
  ) {
    super(
      deliveryService,
      translate,
      http,
      modalService,
      folderService
    );
  }


  ngOnInit() {
    super.ngOnInit();
    this
      .requestorService
      .updateRequestors()
    ;
  }

  fetchProgress() {
    this.deliveryService
      .fetchProgressForAll$(
        (this.selectedRequestor)
          ? this.selectedRequestor.id
          : null
      )
      .pipe(take(1))
      .subscribe(
        (_) => {
          this.progress = _;
          this.isLoading = false;
        },
        (error) => {
          this.isLoading = false;
        },
      )
    ;
  }

  onChangeRequestorFilter(requestor: RequestorView): void {

    this.filter.requestUrl = (requestor)
      ? this.listUrl + '?requestor=' + requestor.id
      : this.listUrl
    ;

    this.selectedRequestor = requestor;
    this.filter.refresh();

  }

  openDeleteModal(deliveryListItem: DeliveryListViewInterface): void {
    const modal = this.modalService.show(DeleteDeliveryModal, {
      class: 'modal--default',
    });
    modal.content.deliveryListView = deliveryListItem;
    modal.content.type = event.type;
    modal.content.onSaveSuccess.subscribe(_ => {
      this.filter.refresh();
    });
  }
}
