import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { map, take } from 'rxjs/operators';
import { RegionService } from '../../../services/region.service';
import { RegionView } from '@content-hub/ts-models/dist/Region';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Subject, Subscription, zip } from 'rxjs';
import { ImportService } from '../../../services/import.service';
import { ClubImportView } from '@content-hub/ts-models/dist/Club';

@Component({
  selector: 'app-import',
  templateUrl: './import.component.html',
})
export class ImportComponent implements OnInit, OnDestroy {

  currentView: string;
  isLoadingClubs = true;
  region$ = new Subject<RegionView>();
  regionSubscription: Subscription;
  clubs$ = new BehaviorSubject<ClubImportView[]>([]);
  selectedArray = [];
  isSending: boolean;

  constructor(
    public translate: TranslateService,
    private regionService: RegionService,
    private importService: ImportService,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  ngOnInit() {
    this.isSending = false;
    this.importService.clearActiveImportQueue();
    this.regionSubscription = this
      .region$
      .pipe(
        take(1),
        map(_ => {
          this
            .getClubs(
              _
            )
          ;
        })
      )
      .subscribe()
    ;
    this.getSelectedRegion();
  }


  getClubs(region: RegionView): void {
    this.isLoadingClubs = true;
    this
      .importService
      .getClubsForRegionWithDeliveryFromLastYear$(
        region.id
      )
      .pipe(
        take(1),
        map(_ => {
          this.clubs$.next(_);
          this.isLoadingClubs = false;
        })
      )
      .subscribe()
    ;
  }

  getSelectedRegion(): void {
    zip(
      this.route.params,
      this.regionService.getUserRegions$()
    )
      .pipe(
        take(1),
        map(_ => {
          this
            .region$
            .next(
              _[1].find(region => region.id === _[0].region)
            )
          ;
        })
      )
      .subscribe()
    ;
  }

  importSelected(): void {
    this.isSending = true;
    this
      .importService
      .import$(
        this.selectedArray
      )
      .pipe(
        take(1),
        map(_ => {
          this
            .importService
            .getActiveImportQueue$()
            .pipe(
              take(1),
              map(activeImportQueue => {
                this.isSending = false;
                const firstEntry = activeImportQueue.entries[0];
                this
                  .router
                  .navigate([
                    '/',
                    this.translate.currentLang,
                    'import-queue',
                    firstEntry.id
                  ])
              })
            )
            .subscribe()
          ;
        })
      )
      .subscribe()
    ;
  }

  ngOnDestroy(): void {
    this.regionSubscription.unsubscribe();
    this.isLoadingClubs = false;
    this.selectedArray = [];
    this.isSending = false;
  }


}
