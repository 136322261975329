import { Component, OnDestroy, OnInit } from '@angular/core';
import { DeliveryService } from '@kravling/modules/core/services/delivery.service';
import { map, take } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { DeliveryListViewInterface } from '@kravling/modules/core/models/View/DeliveryListViewInterface';
import { ProgressViewInterface } from '@kravling/modules/core/models/View/ProgressViewInterface';
import { BehaviorSubject, Observable, Subscription, zip } from 'rxjs';
import { DeliveryStates } from '@kravling/modules/core/models/DeliveryInterface';
import { ParamFilter } from '@braune-digital/angular-query-filter';
import { HttpClient } from '@angular/common/http';
import { ImportClubsComponent } from '../modal/import-clubs/import-clubs.component';
import { AuthService } from 'src/app/modules/security/services/auth.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FolderService } from '../../services/folder.service';
import { FolderView } from '@content-hub/ts-models/dist/Folder';

@Component({
  selector: '<app-approval-delivery-list>',
  templateUrl: './delivery-list.component.html',
})
export class DeliveryListComponent implements OnInit, OnDestroy {
  isLoading = false;
  isRelatedListLoading = false;
  deliveryListItems: DeliveryListViewInterface[];
  progress: ProgressViewInterface;
  DeliveryStates = DeliveryStates;
  filter: ParamFilter;
  _subscriptions: Subscription[] = [];
  listUrl = '/related-deliveries';
  resultsPerPage = 9999;
  hidePagination = true;
  selectedLeague: FolderView;

  importClubsModal: BsModalRef;

  currentParam = null;

  isSuperAdmin = AuthService.user && AuthService.user.roles.some((r) => r === 'ROLE_SUPER_ADMIN');
  isRequestor = AuthService.user && AuthService.user.roles.some((r) => r === 'ROLE_REQUESTOR');

  hasImageDelivery$: Observable<boolean>;
  leagues$ = new BehaviorSubject<FolderView[]>([]);

  constructor(
    public deliveryService: DeliveryService,
    public translate: TranslateService,
    protected http: HttpClient,
    protected modalService: BsModalService,
    protected folderService: FolderService,
  ) {}

  ngOnInit() {
    this.isLoading = true;

    this.fetchLeagues();
    this.hasImageDelivery$ = this.deliveryService.getHasImageDelivery$();

    this.filter = new ParamFilter(
      this.listUrl,
      this.http,
      {},
      false,
    );
    this.filter.setResultsPerPage(this.resultsPerPage);

    this._subscriptions.push(
      this.filter.isLoadingEvent.subscribe((loading) => {
        this.isRelatedListLoading = loading;
      }),
      this.filter.responseEvent.subscribe((deliveryListItems: DeliveryListViewInterface[]) => {
        this.deliveryListItems = deliveryListItems;
        this.fetchProgress();
      }),
      this.filter.isReady.subscribe((_) => {
        if (_) {
          this.filter.refresh();
        }
      }),
    );
  }

  openImportModal(): void {
    this.importClubsModal = this.modalService.show(ImportClubsComponent, {
      class: 'import-clubs-modal',
    });

    this.importClubsModal.content.onSaveSuccess.subscribe(() => {
      this.filter.refresh();
    });
  }

  updateFilter(filter: string) {
    if (this.currentParam === filter) {
      this.filter.setParams({});
      this.currentParam = null;
    } else {
      this.filter.setParams({ state: filter });
      this.currentParam = filter;
    }

    this.filter.refresh();
  }

  onChangeLeagueFilter(folder: FolderView): void {
    this.filter.requestUrl = (folder)
      ? this.listUrl + '?league=' + folder.id
      : this.listUrl
    ;

    this.selectedLeague = folder;
    this.filter.refresh();
  }

  fetchLeagues(): void {
    this
      .folderService
      .fetchAllLeagues$()
      .pipe(
        take(1),
        map(_ => {
          this
            .leagues$
            .next(_)
          ;
        })
      )
      .subscribe()
    ;
  }

  fetchProgress() {
    this.deliveryService
      .fetchProgress$(
        (this.selectedLeague)
          ? this.selectedLeague.id
          : null
      )
      .pipe(take(1))
      .subscribe(
        (_) => {
          this.progress = _;
          this.isLoading = false;
        },
        (error) => {
          this.isLoading = false;
        },
      );
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach((s) => s.unsubscribe());
  }
}
