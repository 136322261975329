import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { DeliveryService } from '@kravling/modules/core/services/delivery.service';
import { DeliveryPublicReadViewInterface } from '@kravling/modules/core/models/View/DeliveryPublicReadViewInterface';

@Component({
  selector: 'public-delivery-page',
  templateUrl: 'public-delivery.page.html',
})
export class PublicDeliveryPage implements OnInit, OnDestroy {
  subscription: Subscription;
  delivery: DeliveryPublicReadViewInterface;
  isLoading: boolean = false;
  linkExpired: boolean;
  deliveryNotFound: boolean;

  constructor(private route: ActivatedRoute, private deliveryService: DeliveryService) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.subscription = this.route.params.subscribe((params) => {
      if (params['publicDeliveryToken']) {
        this.deliveryService.getPublicDelivery$(params['publicDeliveryToken']).subscribe(
          (_) => {

            this.delivery = _;
            this.isLoading = false;
          },
          (error) => {
            if (error.error.message === 'delivery_not_found') {
              this.deliveryNotFound = true;
            } else if (error.error.message === 'link_expired') {
              this.linkExpired = true;
            }
            this.isLoading = false;
          },
        );
      }
    });
  }

  ngOnDestroy(): void {
    if (this.subscription) this.subscription.unsubscribe();
    this.isLoading = false;
  }
}
