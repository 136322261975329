<div class="container">
  <div class="m04">
    <app-club-packs-header
      headline="Launch suite request"
    ></app-club-packs-header>
  </div>
  <div class="card">
    <div class="card-body">
      <app-club-form
        (onRequestSuccess)="navigateToDasboard()"
      ></app-club-form>
    </div>
  </div>
</div>

