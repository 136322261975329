<div class="m12">
  <ng-container *ngIf="delivery.link">
    <p class="m12__copy">
      <a
        href="#"
        (click)="$event.preventDefault(); copyToClipboard()"
        tooltip="{{ 'form.cta.copy_to_clipboard' | translate }}"
        ><i class="fa fa-clipboard-check"></i> {{ form.get('link').value }}</a
      >
    </p>
  </ng-container>

  <form [formGroup]="form" (submit)="onFormSubmit()" *ngIf="delivery && form && !delivery.link">
    <p>
      {{ 'help.text.folder_public_links' | translate }}
    </p>
    <button class="btn btn-sm btn-secondary">
      {{ 'form.cta.create_public_link' | translate }}
    </button>
  </form>
</div>
