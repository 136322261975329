<form class="m10" [formGroup]="form" *ngIf="form" (submit)="handleSubmit()">
  <ng-content></ng-content>
  <bd-input [formControl]="form.get('email')" [label]="'security.login.labels.email' | translate"></bd-input>
  <bd-input
    type="password"
    [formControl]="form.get('password')"
    [label]="'security.login.labels.password' | translate"
  ></bd-input>

  <div class="m10__actions">
    <button class="btn btn-primary" [disabled]="form.invalid" type="submit">
      {{ 'security.login.labels.login' | translate }}
    </button>
    <div class="m10__actions__link">
      <a href="#" [routerLink]="['/', currentLang, 'password-request']">{{
        'security.login.labels.reset' | translate
      }}</a>
    </div>
  </div>

  <ngx-loading [show]="isSendingRequest"></ngx-loading>
</form>
