import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { BudgetView } from '@content-hub/ts-models/dist/Budget';

@Injectable()
export class BudgetService {

  private _budget$ = new BehaviorSubject<BudgetView>(null);

  constructor(protected http: HttpClient) {}

  get budget$() {
    return this._budget$.asObservable();
  }

  fetchBudget(): void {
    this
      .http
      .get<BudgetView>('/budget')
      .pipe(
        take(1),
        map(_ => {
          this._budget$.next(_);
        })
      )
      .subscribe()
    ;
  }

}
