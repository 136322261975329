import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { DeliveryPackageService } from '@kravling/modules/core/services/delivery-package.service';
import { DeliveryPackageListViewInterface } from '@kravling/modules/core/models/View/DeliveryPackageListViewInterface';
import { DeliveryPackageTransitions } from '@kravling/modules/core/models/DeliveryPackageInterface';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  templateUrl: 'delivery-package-transition.modal.html',
  selector: '<app-approval-delivery-package-transition-modal>',
})
export class DeliveryPackageTransitionModal implements OnInit, OnDestroy {
  deliveryPackage: DeliveryPackageListViewInterface;
  transition: string;
  form: FormGroup;
  isSending: boolean;
  isLoading = false;
  workflow: string = 'default';
  private _subscriptions: Subscription[] = [];

  @Output()
  onTransitionSuccess = new EventEmitter<DeliveryPackageListViewInterface>();

  constructor(
    public bsModalRef: BsModalRef,
    private fb: FormBuilder,
    public deliveryPackageService: DeliveryPackageService,
  ) {}

  ngOnInit() {
    this.buildForm();
  }

  buildForm() {
    this.form = this.fb.group({});
  }

  onSave(): void {
    this.isLoading = true;
    this.deliveryPackageService
      .doTransition$(this.deliveryPackage.id, this.transition, this.form.getRawValue())
      .pipe(take(1))
      .subscribe((_) => {
        this.isLoading = false;
        this.bsModalRef.hide();
        this.onTransitionSuccess.emit(_);
      });
  }

  get canDoTransition(): boolean {
    if (this.transition === DeliveryPackageTransitions.REJECT) {
      return this.form.get('text').value !== null && this.form.get('text').value !== '';
    }
    return true;
  }

  get commentPlaceholder(): string {
      if (this.transition === DeliveryPackageTransitions.REJECT) {
        return 'comment.field.new_comment';
      }
      return 'comment.field.optional_comment';
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach((_) => _.unsubscribe());
  }
}
