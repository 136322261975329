<ng-container *ngIf="!!deliveryElement; else loading">
  <div>
    <div class="modal-header">
      <h5 class="modal-title">{{ 'Edit'|translate }}</h5>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <ng-container [ngSwitch]="deliveryElement.type">
        <app-approval-delivery-element-edit-file
          [deliveryElement]="deliveryElement"
          (onFormStatusChange)="form = $event"
        ></app-approval-delivery-element-edit-file>
      </ng-container>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary" [disabled]="!canSave" (click)="onSave()">
        {{ 'Save'|translate }}
      </button>
      <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="bsModalRef.hide()">
        {{ 'Close'|translate }}
      </button>
    </div>
    <ngx-loading [show]="isSending"></ngx-loading>
  </div>
</ng-container>

<ng-template #loading>
  <ngx-loading [show]="true"></ngx-loading>
</ng-template>
