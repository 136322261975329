import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CommentInterface } from '../models/CommentInterface';
import { Observable } from 'rxjs';

@Injectable()
export class CommentatorService {
  constructor(protected http: HttpClient) {}

  post$(url, data: CommentInterface): Observable<CommentInterface> {
    return this.http.post<CommentInterface>(url, data);
  }

  get$(url): Observable<Array<CommentInterface>> {
    return this.http.get<Array<CommentInterface>>(url);
  }
}
