import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ImportQueueView } from '@content-hub/ts-models/dist/Import';
import { ClubImportView } from '@content-hub/ts-models/dist/Club';
import { map, publishReplay, refCount } from 'rxjs/operators';
import { AuthService } from '../../security/services/auth.service';

@Injectable()
export class ImportService {

  activeImportQueue: Observable<ImportQueueView>;

  constructor(protected http: HttpClient) {}

  getClubsForRegionWithDeliveryFromLastYear$(region: string): Observable<ClubImportView[]> {
    return this.http.get<ClubImportView[]>(`/clubs/region/${region}/delivery-in-last-year`);
  }

  import$(clubFolderIds: string[]): Observable<ClubImportView[]> {
    return this.http.post<ClubImportView[]>(`/import-previous`, {
      clubFolders: clubFolderIds
    });
  }

  confirmAndRequest$(data: any): Observable<ClubImportView[]> {
    return this.http.post<ClubImportView[]>(`/request/confirm-and-request`, data);
  }

  deleteImportQueueEntry$(entryId: string): Observable<any> {
    return this.http.delete<any>(`/import/queue/${entryId}`);
  }

  getActiveImportQueue$(): Observable<ImportQueueView> {
    if (!this.activeImportQueue) {
      this.activeImportQueue = this
        .http
        .get<ImportQueueView>(`/import/queue/active`)
        .pipe(
          map(_ => _),
          publishReplay(1),
          refCount()
        )
    }

    return this.activeImportQueue;
  }

  clearActiveImportQueue(): void {
    this.activeImportQueue = null;
  }

  countCompletedEntriesOfImportQueue(importQueue: ImportQueueView): number {
    return importQueue.entries.filter(_ => _.status === 'done').length;
  }

  getProgressOfImportQueue(importQueue: ImportQueueView): number {
    return Math.round((this.countCompletedEntriesOfImportQueue(importQueue) / importQueue.entries.length) * 100);
  }

  getProgressPercentageOfImportQueue(importQueue: ImportQueueView): string {
    return this.getProgressOfImportQueue(importQueue).toString() + '%';
  }

}
