<figure class="m05" [ngClass]="{ 'm05--clickable': clickable }" (click)="handleOnClick()">
  <ng-container *ngIf="media; else dummy">
    <ng-container [ngSwitch]="media.providerName">
      <ng-container *ngSwitchCase="MediaProviderEnum.IMAGE">
        <div
          class="m05__image"
          [ngStyle]="{
            'background-image': 'url(' + media.path + ')'
          }"
        ></div>
      </ng-container>
      <ng-container *ngSwitchDefault>
        <div class="m05__file">
          <i class="fa fa-file"></i>
          <span>{{ media.name }}</span>
        </div>
      </ng-container>
    </ng-container>
    <div class="m05__play-icon" *ngIf="fallback && fallback.contentType === 'video/mp4'">
      <img src="assets/images/play.svg" />
    </div>
  </ng-container>
  <figcaption class="m05__caption" *ngIf="caption">{{ caption }}</figcaption>
</figure>
<ng-template #dummy>
  <div class="m05__dummy">
    <i class="fa fa-upload" *ngIf="editable"></i>
  </div>
</ng-template>
