import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DeliveryInterface } from '../models/DeliveryInterface';
import { DeliveryFeedItemInterface } from '@kravling/modules/core/models/DeliveryFeedItemInterface';
import { DeliveryListViewInterface } from '@kravling/modules/core/models/View/DeliveryListViewInterface';
import { DeliveryReadViewInterface } from '@kravling/modules/core/models/View/DeliveryReadViewInterface';
import { CommentReadViewInterface } from '@kravling/modules/core/models/View/CommentReadViewInterface';
import { ProgressViewInterface } from '@kravling/modules/core/models/View/ProgressViewInterface';
import { UserListViewInterface } from '@kravling/modules/core/models/View/UserListViewInterface';
import { DeliveryHasLinkViewInterface } from '@kravling/modules/core/models/View/DeliveryHasLinkViewInterface';
import { DeliveryPublicReadViewInterface } from '@kravling/modules/core/models/View/DeliveryPublicReadViewInterface';

@Injectable()
export class DeliveryService {
  constructor(private http: HttpClient) {}

  fetch$(): Observable<Array<DeliveryInterface>> {
    return this.http.get<Array<DeliveryInterface>>('/deliveries');
  }

  fetchRelatedDeliveries$(): Observable<DeliveryListViewInterface[]> {
    return this.http.get<DeliveryListViewInterface[]>('/related-deliveries');
  }

  fetchProgress$(leagueId?: string): Observable<ProgressViewInterface> {
    const params = (leagueId)
      ? {
        'league': leagueId
      }
      : null;

    return this.http.get<ProgressViewInterface>('/related-deliveries/progress', {
      params: params
    });
  }

  fetchProgressForAll$(requestorId?: string): Observable<ProgressViewInterface> {

    const params = (requestorId)
      ? {
        'requestor': requestorId
      }
      : null;

    return this.http.get<ProgressViewInterface>('/deliveries/all/progress', {
      params: params
    });
  }

  get$(id): Observable<DeliveryReadViewInterface> {
    return this.http.get<DeliveryReadViewInterface>(`/deliveries/${id}`);
  }

  delete$(deliveryId: string): Observable<any> {
    return this.http.delete<any>(`/deliveries/${deliveryId}`);
  }

  patch$(delivery: DeliveryInterface, form): Observable<DeliveryInterface> {
    return this.http.patch<DeliveryInterface>(`/deliveries/${delivery.id}`, form);
  }

  post$(form): Observable<DeliveryInterface> {
    return this.http.post<DeliveryInterface>(`/deliveries`, form);
  }

  getFeed$(id: string): Observable<DeliveryFeedItemInterface[]> {
    return this.http.get<DeliveryFeedItemInterface[]>(`/deliveries/${id}/feed`);
  }

  removeApproverOrObserver$(id: string, type: string, userId: string): Observable<any> {
    return this.http.delete<any>(`/deliveries/${id}/${type}/${userId}`);
  }

  addApproverOrObserver$(id: string, type: string, data: any): Observable<UserListViewInterface> {
    return this.http.post<UserListViewInterface>(`/deliveries/${id}/add/${type}`, data);
  }

  comment$(id: string, data: any): Observable<CommentReadViewInterface> {
    return this.http.post<CommentReadViewInterface>(`/deliveries/${id}/comments`, data);
  }

  toggleComments$(id: string): Observable<{ enabled: boolean }> {
    return this.http.post<{ enabled: boolean }>(`/deliveries/${id}/comments/toggle`, {});
  }

  getPublicDelivery$(token): Observable<DeliveryPublicReadViewInterface> {
    return this.http.get<DeliveryPublicReadViewInterface>(`/public/deliveries/${token}`);
  }

  createLink$(id, data: any): Observable<DeliveryHasLinkViewInterface> {
    return this.http.post<DeliveryHasLinkViewInterface>(`/deliveries/${id}/create-link`, data);
  }

  getAllTabsFromSheet$(): Observable<any> {
    return this.http.get('/import/tabs');
  }

  getAllClubs$(tab: any): Observable<any> {
    return this.http.get(`/import/clubs/${tab}`);
  }

  postImportClubs$(tabIndex, rows: number[]): Observable<any> {
    return this.http.post('/import/clubs', {
      tab: tabIndex,
      rows,
    });
  }

  getHasImageDelivery$(): Observable<boolean> {
    return this.http.get<boolean>(`/delivery/user/has-image-delivery`);
  }
}
