<!--BD to club-->
<ng-container *ngIf="delivery && !delivery.isImageDelivery">
  <app-delivery-feed-bd-to-club
    [delivery]="delivery"
    [imageDelivery]="imageDelivery"
    [imageDeliveryLatestDeliveryPackage]="imageDeliveryLatestDeliveryPackage"
    [isSuperAdmin]="isSuperAdmin"
    [selectedDeliveryPackage]="selectedDeliveryPackage"
    (showEditEmblemModal)="showEditEmblemModal()"
    [showDetails]="showDetails"
    [canRemoveUser]="canRemoveUser"
    (removeUser)="removeUser($event)"
    (openAddUserModal)="openAddUserModal($event)"
    [canAddUser]="canAddUser"
    (toggleComments)="toggleComments()"
    [user]="user"
    (handleOnNewComment)="handleOnNewComment($event)"
    [feed]="feed"
    (getFeed)="getFeed()"
    [isLoading]="isLoading"
  ></app-delivery-feed-bd-to-club>
</ng-container>

<!--Club to BD-->
<ng-container *ngIf="delivery && delivery.isImageDelivery">
  <app-delivery-feed-club-to-bd
    [selectedDeliveryPackage]="selectedDeliveryPackage"
    [delivery]="delivery"
    [isSuperAdmin]="isSuperAdmin"
    (showEditEmblemModal)="showEditEmblemModal()"
    [feed]="feed"
	[user]="user"
    (getFeed)="getFeed()"
    (getDelivery)="getDelivery()"

    (handleOnNewComment)="handleOnNewComment($event)"
    [showDetails]="showDetails"
    [canRemoveUser]="canRemoveUser"
    (removeUser)="removeUser($event)"
    (openAddUserModal)="openAddUserModal($event)"
    [canAddUser]="canAddUser"
    (toggleComments)="toggleComments()"
  ></app-delivery-feed-club-to-bd>
</ng-container>
