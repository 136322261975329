<ng-container *ngIf="!!form; else loading">
  <form [formGroup]="form" (submit)="onSubmit()" class="comment-form" *ngIf="form">
    <div class="modal-header">
      <h5 class="modal-title">
        <ng-container *ngIf="legalLine; else titleForNewLegalLine">
          {{ 'Edit legal line'|translate }}
        </ng-container>
        <ng-template #titleForNewLegalLine>
          {{ 'Create a new Legal Line'|translate }}
        </ng-template>
      </h5>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="bsModalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">

      <bd-input
        [formControl]="form.get('title')"
        placeholder="Title"
      ></bd-input>

      <bd-textarea
        [formControl]="form.get('text')"
        placeholder="Text"
      >
      </bd-textarea>

      <ng-select
        placeholder="League"
        bdSelect
        class="mb-0"
        [formControl]="form.get('folder')"
        bindValue="id"
        [virtualScroll]="true"
        bindLabel="name"
        [disabled]="isSending"
        [items]="folderService.fetchAllLeagues$() | async"
      ></ng-select>

    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-link" data-dismiss="modal" (click)="bsModalRef.hide()">
        {{ 'Close'|translate }}
      </button>
      <button type="button" class="btn btn-primary" [disabled]="!canSave" type="submit">
        <ng-container *ngIf="legalLine; else submitForNewLegalLine">
          {{ 'Edit legal line'|translate }}
        </ng-container>
        <ng-template #submitForNewLegalLine>
          {{ 'Create legal line'|translate }}
        </ng-template>
      </button>
    </div>
    <ngx-loading [show]="isSending"></ngx-loading>
  </form>
</ng-container>

<ng-template #loading>
  <ngx-loading [show]="true"></ngx-loading>
</ng-template>
