import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommentComponent } from '../comment/comment.component';
import { FormGroup } from '@angular/forms';

@Component({
  selector: '<app-approval-transition-comment-form>',
  templateUrl: './transition-comment.component.html',
})
export class TransitionCommentComponent extends CommentComponent {
  @Input() form: FormGroup;

  buildForm() {
    this.form.addControl('text', this.fb.control(null));
    this.form.addControl('attachments', this.fb.control([]));
  }

  onSubmit() {
    this.isFormSubmitting = true;
  }

  get isSubmitDisabled(): boolean {
    return false;
  }
}
