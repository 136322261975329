import { Component } from '@angular/core';
import { DeliveryStatusComponent } from '../delivery-status/delivery-status.component';
import { DeliveryPackageStates } from '@kravling/modules/core/models/DeliveryPackageInterface';

@Component({
  selector: '<app-approval-delivery-package-status>',
  templateUrl: './delivery-package-status.component.html',
})
export class DeliveryPackageStatusComponent extends DeliveryStatusComponent {
  DeliveryPackageStates = DeliveryPackageStates;
}
