import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { RequestInterface } from '../models/RequestInterface';
import { DeliveryInterface } from '@kravling/modules/core/models/DeliveryInterface';
import { FolderInterface } from '@kravling/modules/core/models/FolderInterface';

@Injectable()
export class RequestService {
  constructor(private http: HttpClient) {}

  fetch$(): Observable<Array<RequestInterface>> {
    return this.http.get<Array<RequestInterface>>('/requests');
  }

  get$(id): Observable<RequestInterface> {
    return this.http.get<RequestInterface>(`/requests/${id}`);
  }

  getDeliveriesByRequest$(id): Observable<DeliveryInterface[]> {
    return this.http.get<DeliveryInterface[]>(`/requests/${id}/deliveries`);
  }

  getFoldersByRequest$(id): Observable<FolderInterface[]> {
    return this.http.get<FolderInterface[]>(`/requests/${id}/folders`);
  }

  delete$(request: RequestInterface): Observable<any> {
    return this.http.delete<any>(`/requests/${request.id}`);
  }

  patch$(request: RequestInterface, form): Observable<RequestInterface> {
    return this.http.patch<RequestInterface>(`/requests/${request.id}`, form);
  }

  post$(form): Observable<RequestInterface> {
    return this.http.post<RequestInterface>(`/requests`, form);
  }
}
