<div class="container">

  <div class="card">
    <div class="card-body">
      <h3>
        {{ 'Launch suite request' }}
      </h3>
      <p>
        To request a launch suite for your clubs, leagues or similar, you can either reuse the club data from last year (if the clubs were already part of the “FC 24 Club Packs”) or create a request from scratch for a new launch suite. After finishing the request, the respective approvers will be notified and invited to the platform. You can also add yourself as approver to manage the next steps of the process.
      </p>
      <p>
        The club launch suite includes printable covers for PlayStation and Xbox games, promotional images for social media, high-resolution wallpapers for mobile and desktop, and an engaging social media clip for the reveal.
      </p>

      <div *ngIf="importQueue">
        <div class="m04__progress__bar">
          <div class="progress">
            <div
              class="progress-bar progress-bar-striped bg-success"
              [style.width]="importService.getProgressPercentageOfImportQueue(importQueue)"
            ></div>
          </div>
        </div>
        <div>
          {{ importService.countCompletedEntriesOfImportQueue(importQueue) }} / {{ importQueue.entries.length }}
        </div>
      </div>
    </div>
  </div>

  <div class="card">
    <div class="card-body">
      <ng-container *ngIf="entry">
        <app-club-form
          [importQueueEntry]="entry"
          (onRequestSuccess)="importService.clearActiveImportQueue();navigateToNextOrDashboard()"
          (onCancelEntry)="importService.clearActiveImportQueue();navigateToNextOrDashboard()"
        ></app-club-form>
      </ng-container>
    </div>
  </div>
</div>

