<div class="m04" *ngIf="!isLoading">
  <app-club-packs-header
    headline="Club Launch Suite Request"
  >
    <a *ngIf="isRequestor || isSuperAdmin" [routerLink]="['/', translate.currentLang, 'request-packs']" class="btn btn-primary btn--big">
      <span>{{ 'Add a new request' }}</span>
    </a>
    <app-progress-bar
      [progress]="progress"
    ></app-progress-bar>
  </app-club-packs-header>

  <list-container [filter]="filter" [hidePagination]="hidePagination">
    <div filter class="m04__filter">
      <div class="row">
        <div class="col-md-6">
          <filter-component
            type="text"
            [filterPlaceholder]="'Search for club' | translate"
            [params]="{ properties: ['folder.name'] }"
            (onRefreshFilter)="filter.refresh()"
          ></filter-component>
        </div>
        <div class="col-md-6">
          <ng-select
            bdSelect
            bindValue="id"
            bindLabel="rootline"
            [clearable]="true"
            [virtualScroll]="true"
            [multiple]="false"
            (change)="onChangeLeagueFilter($event)"
            [items]="leagues$ | async"
            placeholder="League"
          ></ng-select>
        </div>
      </div>
      <div class="d-flex badges">
        <button
          class="mr-2"
          (click)="updateFilter('new')"
          [ngClass]="{ active: currentParam === 'new' || !currentParam }"
        >
          <app-approval-delivery-status [state]="'new'"></app-approval-delivery-status>
        </button>
        <button
          class="mr-2"
          (click)="updateFilter('delivered')"
          [ngClass]="{ active: currentParam === 'delivered' || !currentParam }"
        >
          <app-approval-delivery-status [state]="'delivered'"></app-approval-delivery-status>
        </button>
        <button
          (click)="updateFilter('completed')"
          [ngClass]="{ active: currentParam === 'completed' || !currentParam }"
        >
          <app-approval-delivery-status [state]="'completed'"></app-approval-delivery-status>
        </button>
      </div>
    </div>

    <ngx-loading loading [show]="true"></ngx-loading>
    <div noResults>
      <div class="m20">
        There are no results
      </div>
    </div>
    <div list>
      <div *ngIf="deliveryListItems && deliveryListItems.length">
        <div
          class="m08"
          *ngFor="let deliveryListItem of deliveryListItems"
          [routerLink]="['/', translate.currentLang, 'd', deliveryListItem.id]"
        >
          <div class="m08__image" *ngIf="deliveryListItem.folder.image">
            <img [src]="deliveryListItem.folder.image?.path" alt="{{ deliveryListItem.folder.name }}" />
          </div>
          <div class="m08__text">
            <app-approval-folder-rootline
              class="m06"
              [rootline]="deliveryListItem.rootline"
              [showHome]="false"
              [showChildren]="false"
            ></app-approval-folder-rootline>
            <h3>
              {{ deliveryListItem.folder.name }} <ng-container *ngIf="deliveryListItem.isImageDelivery"> - Photo Delivery</ng-container>
              <app-approval-delivery-status
                [state]="deliveryListItem.state"
                [workflow]="($any(deliveryListItem).isImageDelivery) ? 'club_to_bd': 'default'"
              ></app-approval-delivery-status>
            </h3>
          </div>
        </div>
      </div>
    </div>
  </list-container>
  <div class="m15" *ngIf="isRequestor">
    <a [routerLink]="['/', translate.currentLang, 'request-packs']">
      <i class="fa fa-plus-circle"></i> <span>{{ 'Add a new request' }}</span>
    </a>
  </div>
</div>
<ngx-loading [show]="isLoading"></ngx-loading>
