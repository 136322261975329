import { MediaInterface as BaseMediaInterface } from '@kravling/modules/core/models/MediaInterface';

export enum MediaProviderEnum {
  IMAGE = 'sonata.media.provider.image',
}

export default interface MediaInterface extends BaseMediaInterface {
  providerName: string;
  big: string;
}
