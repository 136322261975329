<form [formGroup]="form" (submit)="onSubmit()" class="m07" *ngIf="form">
  <div class="m07__textarea">
    <bd-textarea
		[formControl]="form.get('text')"
		[placeholder]="commentPlaceholder"
	></bd-textarea>
    <i
      class="fa fa-paperclip m07__textarea__attachments"
      tooltip="{{ 'Attachments' | translate }}"
      (click)="showAttachments = !showAttachments"
    ></i>
  </div>

  <bd-upload
    class="m07__attachments"
    [ngClass]="{ 'm07__attachments--open': showAttachments }"
    [multiple]="true"
    [form]="form.get('attachments')"
    [uploader]="uploader"
  ></bd-upload>
</form>
