import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { RegionView } from '@content-hub/ts-models/dist/Region';

@Injectable()
export class RegionService {

  private _regions$ = new BehaviorSubject([]);

  constructor(protected http: HttpClient) {}

  get regions$() {
    return this._regions$.asObservable();
  }

  fetchRegions(): void {
    this
      .http
      .get<Array<RegionView>>('/regions')
      .pipe(
        take(1),
        map(_ => {
          this._regions$.next(_);
        })
      )
      .subscribe()
    ;
  }

  getUserRegions$(): Observable<Array<RegionView>> {
    return this
      .http
      .get<Array<RegionView>>('/user/regions')
    ;
  }
}
