<form [formGroup]="form" (submit)="onSubmit()" class="comment-form" *ngIf="form">
  <div class="m07__textarea">
    <bd-textarea [formControl]="form.get('text')" [placeholder]="'comment.field.new_comment' | translate"></bd-textarea>
    <i
      class="fa fa-paperclip m07__textarea__attachments"
      tooltip="{{ 'Attachments' | translate }}"
      (click)="showAttachments = !showAttachments"
    ></i>
  </div>

  <div class="m07__attachments" [ngClass]="{ 'm07__attachments--open': showAttachments }">

  </div>
  <bd-upload
    class="m07__attachments"
    [ngClass]="{ 'm07__attachments--open': showAttachments }"
    [multiple]="true"
    [form]="form.get('attachments')"
    [uploader]="uploader"
  ></bd-upload>


  <div *ngIf="showAttachments">
    <div class="progress mt-2" *ngIf="showProgress">
      <div class="progress-bar progress-bar-striped bg-success" [style.width]="progress"></div>
    </div>
    <div class="m07__thumbs" *ngIf="uploader.queue">
      <figure class="m05" *ngFor="let item of uploader.queue">
        <ng-container *ngIf="item._file; else uploading">
          <ng-container [ngSwitch]="item._file['providerName']">
            <ng-container *ngSwitchCase="MediaProviderEnum.IMAGE">
              <div
                class="m05__image"
                [ngStyle]="{
            'background-image': 'url(' + item._file['path'] + ')'
          }"
              ></div>
            </ng-container>
            <ng-container *ngSwitchDefault>
              <div class="m05__file">
                <i class="fa fa-file"></i>
                <span>{{ item._file['name'] }}</span>
              </div>
            </ng-container>
          </ng-container>
        </ng-container>
        <ng-template #uploading>
          Uploading
        </ng-template>
      </figure>
    </div>
  </div>

  <button
    class="btn btn-secondary m07__submit"
    *ngIf="!isSubmitDisabled"
    [disabled]="isSubmitDisabled || isFormSubmitting"
  >
    {{ 'Comment' | translate }}
  </button>
  <ngx-loading [show]="isFormSubmitting"></ngx-loading>
</form>
