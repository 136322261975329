import { Component, Input } from '@angular/core';
import { DeliveryStates } from '@kravling/modules/core/models/DeliveryInterface';
import { ProgressViewInterface } from '@kravling/modules/core/models/View/ProgressViewInterface';

@Component({
  selector: '<app-progress-bar>',
  templateUrl: './progress-bar.component.html',
})
export class ProgressBarComponent  {

  DeliveryStates = DeliveryStates;

  @Input()
  progress: ProgressViewInterface;

  get progressPercentageCompleted(): string {
    return Math.round((this.progress.completed / this.progress.count) * 100).toString() + '%';
  }

  get progressPercentageDelivered(): string {
    return Math.round((this.progress.delivered / this.progress.count) * 100).toString() + '%';
  }
}
