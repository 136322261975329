<form
  [formGroup]="form"
  (submit)="$event.preventDefault(); onFormStatusChange.emit(form)"
  class="comment-form"
  *ngIf="form"
>
  <bd-upload
    [multiple]="false"
    [form]="form.get('media')"
    [label]="'File' | translate"
    [uploader]="uploader"
  ></bd-upload>
  <bd-upload
    [multiple]="false"
    [form]="form.get('preview')"
    [label]="'Preview file' | translate"
    [uploader]="uploaderPreview"
  ></bd-upload>
</form>
