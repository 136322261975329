import {
  AfterViewInit,
  Component,
  EventEmitter,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { DeliveryReadViewInterface } from '@kravling/modules/core/models/View/DeliveryReadViewInterface';
import { DeliveryService } from '@kravling/modules/core/services/delivery.service';
import { UserListViewInterface } from '@kravling/modules/core/models/View/UserListViewInterface';
import { environment } from '../../../../../../environments/environment';
import { FileUploader } from 'ng2-file-upload';
import { AuthService } from '../../../../security/services/auth.service';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  templateUrl: 'delivery-add-team-emblem.modal.html',
  selector: '<app-approval-delivery-add-team-emblem>',
})
export class DeliveryAddTeamEmblemModal implements OnInit, OnDestroy {
  delivery: DeliveryReadViewInterface;

  private _subscriptions: Subscription[] = [];
  form: FormGroup;

  @Output()
  onSaveSuccess = new EventEmitter<void>();

  uploader: FileUploader;

  constructor(public bsModalRef: BsModalRef, private fb: FormBuilder, private deliveryService: DeliveryService) {}

  ngOnInit() {
    setTimeout(() => {
      this.uploader = new FileUploader({
        url: environment.api + '/folder/' + this.bsModalRef.content.delivery.folder.id + '/emblem/upload',
        authToken: 'Bearer ' + AuthService.user.token,
        method: 'post',
        autoUpload: true,
        headers: [
          {
            name: 'Accept',
            value: 'application/json',
          },
        ],
      });

      this._subscriptions.push(this.uploader.response.pipe(tap(() => this.onSaveSuccess.emit())).subscribe());
    }, 1);

    this.form = this.fb.group({
      logo: this.fb.control(null),
    });
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach((_) => _.unsubscribe());
  }
}
