import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FileItem, FileUploader } from 'ng2-file-upload';
import { environment } from '../../../../../../environments/environment';
import { AuthService } from '../../../../security/services/auth.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DeliveryPackageFeedItemInterface } from '@kravling/modules/core/models/DeliveryPackageFeedItemInterface';
import {take, takeUntil, tap} from 'rxjs/operators';
import { Subject } from 'rxjs';
import { DeliveryElementListViewInterface } from '@kravling/modules/core/models/View/DeliveryElementListViewInterface';
import { FeedTypeDeliveryPackageComponent } from '../feed-type-delivery-package/feed-type-delivery-package.component';
import { DeliveryPackageService } from '@kravling/modules/core/services/delivery-package.service';
import { NotificationService } from '../../../../../services/notification.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { DeliveryPackageStates } from '@kravling/modules/core/models/DeliveryPackageInterface';
import {DeliveryService} from "@kravling/modules/core/services/delivery.service";

enum ElementTypes {
  STADIUM_PHOTO = 'Stadium Photo',
  SECOND_PHOTO = 'Second Photo',
  CREST = 'Crest'
}

@Component({
  selector: 'app-feed-type-delivery-club',
  templateUrl: './feed-type-delivery-club.component.html',
})
export class FeedTypeDeliveryClubComponent extends FeedTypeDeliveryPackageComponent implements OnInit, OnDestroy {
  @Input()
  item: DeliveryPackageFeedItemInterface;

  @Output()
  isUploaded = new EventEmitter<boolean>();

  uploaderMain: FileUploader;
  uploaderSecondary: FileUploader;
  uploaderThird: FileUploader;

  deliveryElementMain: DeliveryElementListViewInterface;
  deliveryElementSecondary: DeliveryElementListViewInterface;
  deliveryElementThird: DeliveryElementListViewInterface;

  form: FormGroup;

  crestCheckApproved: boolean = false;
  deliveryPackageStates = DeliveryPackageStates;
  progressMain: string;
  showProgressMain: boolean = false;
  progressSecondary: string;
  showProgressSecondary: boolean = false;
  progressThird: string;
  showProgressThird: boolean = false;


  private destroy$ = new Subject<boolean>();

  constructor(
    modalService: BsModalService,
    public deliveryPackageService: DeliveryPackageService,
    private fb: FormBuilder,
    private notificationService: NotificationService,
    public deliveryService: DeliveryService,
  ) {
    super(modalService, deliveryPackageService);
  }

  ngOnInit() {
    setTimeout(() => {
      this.deliveryElementMain = this.item.object.deliveryElements.find((d) => d.name === ElementTypes.STADIUM_PHOTO);
      this.uploaderMain = new FileUploader({
        url: environment.api + '/delivery-element/' + this.deliveryElementMain.id + '/upload',
        authToken: 'Bearer ' + AuthService.user.token,
        method: 'post',
        autoUpload: true,
        headers: [
          {
            name: 'Accept',
            value: 'application/json',
          },
        ],
      });

      this.uploaderMain.response
        .pipe(
          tap(() => this.isUploaded.emit()),
          takeUntil(this.destroy$),
        )
        .subscribe((resp) => {
          const response = JSON.parse(resp);
          this.deliveryElementMain.media = response;
          this.updateActions();

          if (response.code >= 400) {
            if (response.code === 415) {
              this.notificationService.emplace({
                message:
                  'The file type you are attempting to upload is not allowed. Please make sure to upload a .jpg or .png file',
                severity: 'error',
                id: Math.random(),
              });
            } else {
              this.notificationService.emplace({
                message: response.message,
                severity: 'error',
                id: Math.random(),
              });
            }
          }
        });
      if (this.deliveryElementMain && this.deliveryElementMain.media) {
        this.form.get('main').setValue(this.deliveryElementMain.media);
      }

      this.deliveryElementSecondary = this.item.object.deliveryElements.find(
        (d) => d.name === ElementTypes.CREST,
      );
      this.uploaderSecondary = new FileUploader({
        url: environment.api + '/delivery-element/' + this.deliveryElementSecondary.id + '/upload',
        authToken: 'Bearer ' + AuthService.user.token,
        method: 'post',
        autoUpload: true,
        headers: [
          {
            name: 'Accept',
            value: 'application/json',
          },
        ],
      });

      this.uploaderSecondary.response
        .pipe(
          tap(() => this.isUploaded.emit()),
          takeUntil(this.destroy$),
        )
        .subscribe((resp) => {
          const response = JSON.parse(resp);
          this.deliveryElementSecondary.media = response;
          this.updateActions();

          if (response.code >= 400) {
            if (response.code === 415) {
              this.notificationService.emplace({
                message:
                  'The file type you are attempting to upload is not allowed. Please make sure to upload a .jpg or .png file',
                severity: 'error',
                id: Math.random(),
              });
            } else {
              this.notificationService.emplace({
                message: response.message,
                severity: 'error',
                id: Math.random(),
              });
            }
          }
        });


      this.deliveryElementThird = this.item.object.deliveryElements.find(
        (d) => d.name === ElementTypes.SECOND_PHOTO,
      );


      this.uploaderThird = new FileUploader({
        url: environment.api + '/delivery-element/' + this.deliveryElementThird.id + '/upload',
        authToken: 'Bearer ' + AuthService.user.token,
        method: 'post',
        autoUpload: true,
        headers: [
          {
            name: 'Accept',
            value: 'application/json',
          },
        ],
      });

      this.uploaderThird.response
        .pipe(
          tap(() => this.isUploaded.emit()),
          takeUntil(this.destroy$),
        )
        .subscribe((resp) => {
          const response = JSON.parse(resp);
          this.deliveryElementThird.media = response;
          this.updateActions();

          if (response.code >= 400) {
            if (response.code === 415) {
              this.notificationService.emplace({
                message:
                  'The file type you are attempting to upload is not allowed. Please make sure to upload a .jpg or .png file',
                severity: 'error',
                id: Math.random(),
              });
            } else {
              this.notificationService.emplace({
                message: response.message,
                severity: 'error',
                id: Math.random(),
              });
            }
          }
        });

      if (this.deliveryElementThird && this.deliveryElementThird.media) {
        this.form.get('third').setValue(this.deliveryElementThird.media);
      }



      const self = this;

      this.progressMain = '0%';
      this.uploaderMain.onAfterAddingAll = function (fileItems: FileItem[]) {
        self.progressMain = '0%';
      };
      this.uploaderMain.onProgressAll = function (progress: number) {
        self.progressMain = progress.toString() + '%';
        self.showProgressMain = true;
      };
      this.uploaderMain.onCompleteAll = function () {
        self.showProgressMain = false;
      };

      this.progressSecondary = '0%';
      this.uploaderSecondary.onAfterAddingAll = function (fileItems: FileItem[]) {
        self.progressSecondary = '0%';
      };
      this.uploaderSecondary.onProgressAll = function (progress: number) {
        self.progressSecondary = progress.toString() + '%';
        self.showProgressSecondary = true;
      };
      this.uploaderSecondary.onCompleteAll = function () {
        self.showProgressSecondary = false;
      };

      this.progressThird = '0%';
      this.uploaderThird.onAfterAddingAll = function (fileItems: FileItem[]) {
        self.progressThird = '0%';
      };
      this.uploaderThird.onProgressAll = function (progress: number) {
        self.progressThird = progress.toString() + '%';
        self.showProgressThird = true;
      };
      this.uploaderThird.onCompleteAll = function () {
        self.showProgressThird = false;
      };

    }, 100);
    this.form = this.fb.group({
      main: this.fb.control(null, [Validators.required]),
      secondary: this.fb.control(null, [Validators.required]),
      third: this.fb.control(null, [Validators.required]),
    });
  }

  crestCheckApprove(): void {
    this.crestCheckApproved = true;
    this.form.get('secondary').setValue(this.deliveryElementSecondary.media);
  }

  crestCheckReject(): void {
    this.deliveryElementSecondary.media = null;
    this.deliveryElementSecondary.preview = null;
    this.form.get('secondary').setValue(null);
  }

  uploaderIsDisabled(): boolean {
    return this.item.role !== this.Roles.DELIVERER ||
      this.item.object.state === 'waiting' ||
      this.item.object.state === 'rejected' ||
      this.item.object.state === 'approved';
  }

  updateActions(): void {
    this.deliveryService
      .getFeed$(this.delivery.id)
      .pipe(take(1))
      .subscribe(
        (_) => {
          const deliveryPackageItems  = _.filter(item => item.type === 'delivery_package') as Array<DeliveryPackageFeedItemInterface>;
          const deliveryPackageItem = deliveryPackageItems.find(item => item.object.id = this.item.object.id);
          this.item.object.actions = deliveryPackageItem.object.actions;
        },
        (error) => {},
      );
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    this.crestCheckApproved = false;
    this.showProgressSecondary = false;
    this.showProgressMain = false;
  }
}
