import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BdKravCoreModule } from '../core/core.module';

@NgModule({
  imports: [BdKravCoreModule],
  declarations: [],
  providers: [],
  exports: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class BdKravValidateModule {}
