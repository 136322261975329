<div class="container m03" [ngClass]="{ 'm03--open': !!selectedDeliveryPackage }">
  <div class="m01" *ngIf="delivery">
    <div class="m08">
      <div class="m08__image">
        <img
          [src]="delivery.folder.image?.path || 'assets/images/team_emblem_placeholder.png'"
          alt="{{ delivery.folder.name }}"
        />
        <button *ngIf="isSuperAdmin" class="edit-emblem" (click)="showEditEmblemModal.emit()">
          <i class="fa fa-pencil"></i>
        </button>
      </div>
      <div class="m08__text">
        <app-approval-folder-rootline class="m06" [rootline]="delivery.rootline"></app-approval-folder-rootline>
        <h2>
          {{ delivery.folder.name }}
          <app-approval-delivery-status [state]="delivery.state"></app-approval-delivery-status>
        </h2>
        <i
          class="m08__text__detail-cta fas fa-info-circle"
          [ngClass]="{ 'm08__text__detail-cta--open': !!showDetails }"
          (click)="showDetails = !showDetails"
          tooltip="{{ 'Information' }}"
        ></i>

        <app-delivery-package-settings
          [delivery]="delivery"
          (toggleComments)="toggleComments.emit($event)"
          [canAddUser]="canAddUser"
          (openAddUserModal)="openAddUserModal.emit($event)"
          (removeUser)="removeUser.emit($event)"
          [canRemoveUser]="canRemoveUser"
          [showDetails]="showDetails"
        ></app-delivery-package-settings>

        <div class="m01__public_links">
          <app-approval-delivery-public-links
            [delivery]="delivery"
            [user]="user"
            (onPublicLinkCreated)="($event)"
            (onCopyLinkToClipboard)="($event)"
          ></app-approval-delivery-public-links>
        </div>
      </div>
    </div>
    <div class="m02" *ngIf="delivery.taskType === 'league_approval'">
      <div class="m21">
        <div class="m21__text">
          <p>
            Before we can start creating Club Packs for the entire league, we need your approval regarding the <strong>league-specific elements</strong>. Please look closely at the example below and check for correct use of the league logo (logo-version, sponsors, size, safe-zone). If there’s something wrong, please reject and let us know by leaving an informative comment and the correct logo if necessary.
          </p>
          <p>
            <small>
              Important: The design draft is still highly confidential and is not to be shared anywhere yet.
            </small>
          </p>
        </div>
        <div class="m21__example">
          <img src="/assets/images/case_example_fc25_mancity.png" height="190" alt="Example" />
          <span class="badge badge-secondary">Example</span>
        </div>
      </div>
    </div>
    <!-- Image delivery -->
    <div class="m02" *ngIf="imageDelivery">
      <div class="m02__header">
        <h3>
          {{ 'Photo Delivery' | translate }}
          <app-approval-delivery-status
            [state]="imageDelivery.state"
            workflow="club_to_bd"
          ></app-approval-delivery-status>
        </h3>
      </div>
      <p *ngIf="imageDelivery.state === deliveryStates.COMPLETED || imageDelivery.state === deliveryStates.DELIVERED">
        Below you will find an overview of the assets that were delivered for this club. This includes the photo and the crest. These assets were checked by us and used on the design drafts.
      </p>
      <p *ngIf="imageDelivery.state === deliveryStates.NEW">
        Before we can start creating your design, we need two photos, which will be the background images of the pack design. For more information and a photo brief, please visit the photo delivery page. Click on the button below to get there and upload your photos. <strong>Please note, that we need the photo asap.</strong>
      </p>
      <div class="m22" *ngIf="imageDeliveryLatestDeliveryPackage && imageDeliveryLatestDeliveryPackage.state !== deliveryPackageStates.NEW">
        <div class="m22__main">
          <img *ngIf="imageDeliveryLatestDeliveryPackage.deliveryElements[0] && imageDeliveryLatestDeliveryPackage.deliveryElements[0].media" [src]="imageDeliveryLatestDeliveryPackage.deliveryElements[0].media.path" />
        </div>
        <div class="m22__main">
          <img *ngIf="imageDeliveryLatestDeliveryPackage.deliveryElements[2] && imageDeliveryLatestDeliveryPackage.deliveryElements[2].media" [src]="imageDeliveryLatestDeliveryPackage.deliveryElements[2].media.path" />
        </div>
        <div class="m22__crest">
          <img *ngIf="imageDeliveryLatestDeliveryPackage.deliveryElements[1] && imageDeliveryLatestDeliveryPackage.deliveryElements[1].media" [src]="imageDeliveryLatestDeliveryPackage.deliveryElements[1].media.path" />
        </div>

      </div>
      <ng-container *ngIf="imageDelivery.state !== deliveryStates.COMPLETED">
        <a class="btn btn-primary" [routerLink]="['/', translate.currentLang, 'd', imageDelivery.id]">
          Visit photo delivery page
        </a>
      </ng-container>
    </div>
    <app-approval-comment-form
      class="m07"
      *ngIf="delivery.enableComments"
      [delivery]="delivery"
      (onNewComment)="handleOnNewComment.emit($event)"
	  [commentPlaceholder]="'comment.field.new_comment' | translate"
    ></app-approval-comment-form>
    <div *ngIf="feed && feed.length > 0">
      <div *ngFor="let feedItem of feed" class="m02">
        <ng-container [ngSwitch]="feedItem.type">
          <app-approval-delivery-feed-item-delivery-package
            *ngSwitchCase="deliveryFeedType.DELIVERY_PACKAGE"
            [item]="feedItem"
            [delivery]="delivery"
            (reload)="getFeed.emit()"
            (onSelectDeliveryPackage)="selectedDeliveryPackage = $event"
            [forceAllowDownload]="delivery.allowDownloadOnEveryState"
          ></app-approval-delivery-feed-item-delivery-package>
          <app-approval-delivery-feed-item-comment
            *ngSwitchCase="deliveryFeedType.COMMENT"
            [item]="feedItem"
            [delivery]="delivery"
            (reload)="getFeed.emit()"
          ></app-approval-delivery-feed-item-comment>
          <app-approval-delivery-feed-item-activity
            *ngSwitchCase="deliveryFeedType.ACTIVITY"
            [item]="feedItem"
            [delivery]="delivery"
            (reload)="getFeed.emit()"
          ></app-approval-delivery-feed-item-activity>
        </ng-container>
      </div>
    </div>
    <div class="m01__actions">
      <a href="#" [routerLink]="['/']" title="{{ 'Back to overview' | translate }}"
        ><i class="fa fa-arrow-left"></i> {{ 'Back to overview' | translate }}</a
      >
    </div>
  </div>
  <app-approval-delivery-package-gallery
    class="m03__gallery"
    *ngIf="selectedDeliveryPackage"
    [deliveryPackage]="selectedDeliveryPackage"
    (onClose)="selectedDeliveryPackage = null"
    [forceAllowDownload]="delivery.allowDownloadOnEveryState"
  ></app-approval-delivery-package-gallery>
  <ngx-loading [show]="isLoading"></ngx-loading>
</div>
