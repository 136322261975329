import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, publishReplay, refCount, take } from 'rxjs/operators';
import { FolderView } from '@content-hub/ts-models/dist/Folder';

@Injectable()
export class FolderService {

  private countries$: Observable<FolderView[]>;
  private leagues$: {[key: string]: Observable<FolderView[]>} = {};

  constructor(protected http: HttpClient) {}

  fetchAllLeagues$(countryFolderId?: string): Observable<FolderView[]> {
    if (!this.leagues$[countryFolderId]) {
      const params = (countryFolderId)
        ? {
          country: countryFolderId
        }
        : null
      ;
      this.leagues$[countryFolderId] = this
        .http
        .get<FolderView[]>(`/folder/leagues`, {
          params: params
        })
        .pipe(
          map(_ => _),
          publishReplay(1),
          refCount()
        )
    }
    return this.leagues$[countryFolderId];
  }

  fetchAllCountries$(): Observable<FolderView[]> {
    if (!this.countries$) {
      this.countries$ = this
        .http
        .get<FolderView[]>(`/folder/countries`)
        .pipe(
          map(_ => _),
          publishReplay(1),
          refCount()
        )
    }
    return this.countries$;
  }

  getGeneralCountryFolder(): Promise<FolderView> {
    return new Promise<FolderView>((resolve, reject) => {
      this
        .fetchAllCountries$()
        .pipe(
          take(1),
          map(_ => {
            const countryFolder = _.find((countryFolder:FolderView) => countryFolder.name === 'General');
            resolve(countryFolder);
          })
        )
        .subscribe()
      ;
    })
      ;
  }

  getCountryFolderById(id: string): Promise<FolderView> {
    return new Promise<FolderView>((resolve, reject) => {
      this
        .fetchAllCountries$()
        .pipe(
          take(1),
          map(_ => {
            resolve(
              _.find((countryFolder:FolderView) => countryFolder.id === id)
            );
          })
        )
        .subscribe()
      ;
    })
      ;
  }

  getLeagueFolderById(id: string): Promise<FolderView> {
    return new Promise<FolderView>((resolve, reject) => {
      this
        .fetchAllLeagues$()
        .pipe(
          take(1),
          map(_ => {
            resolve(
              _.find((leagueFolder:FolderView) => leagueFolder.id === id)
            );
          })
        )
        .subscribe()
      ;
    })
      ;
  }

  getLeagueFolderByNameAndParent(name: string, parentId: string): Promise<FolderView> {
    return new Promise<FolderView>((resolve, reject) => {
      this
        .fetchAllLeagues$()
        .pipe(
          take(1),
          map(_ => {
            resolve(
              _.find((leagueFolder:FolderView) => leagueFolder.name === name && leagueFolder['parentId'] === parentId)
            );
          })
        )
        .subscribe()
      ;
    })
      ;
  }

}
