<div class="container">
  <div class="m04">
    <div class="card ">
      <div class="card-body">
        <div class="d-flex mb-5">
          <h2 class="card-title">
            {{ 'Legal Lines' }}
          </h2>
          <a href="#" (click)="$event.preventDefault();openCreateOrEditLegalLineModal()" class="btn btn-primary btn--big ml-auto">
            <span>{{ 'New entry' }}</span>
          </a>
        </div>

        <div
          class="m19"
          *ngFor="let legalLine of legalLineService.legalLines$ | async"
        >
          <p class="m19__topline">
            {{ legalLine.league.name }}: {{ legalLine.title }}
          </p>
          <div class="m19__text">
            {{ legalLine.text }}
          </div>
          <a class="m19__edit" href="#" (click)="$event.preventDefault();openCreateOrEditLegalLineModal(legalLine)">
            <i class="fa fa-pencil"></i>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>

