import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { BdKravlingModule } from '@kravling/bd.kravling.module';
import { FormUtilitiesModule } from '@braune-digital/form-utilities';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BdFilterModule } from '@braune-digital/angular-query-filter';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { DeliveryListComponent } from './components/delivery-list/delivery-list.component';
import { NgxLoadingModule } from 'ngx-loading';
import { DeliveryFeedComponent } from './components/delivery-feed/delivery-feed.component';
import {
  FeedTypeDeliveryPackageComponent,
} from './components/delivery-feed/feed-type-delivery-package/feed-type-delivery-package.component';
import { FolderRootlineComponent } from './components/folder-rootline/folder-rootline.component';
import { FeedItemComponent } from './components/delivery-feed/feed-item.component';
import { FeedTypeCommentComponent } from './components/delivery-feed/feed-type-comment/feed-type-comment.component';
import { FeedTypeActivityComponent } from './components/delivery-feed/feed-type-activity/feed-type-activity.component';
import { FileUploadModule } from 'ng2-file-upload';
import { CommentComponent } from './components/form/comment/comment.component';
import { FileListComponent } from './components/file-list/file-list.component';
import { FileComponent } from './components/file/file.component';
import { DeliveryElementEditModal } from './components/modal/delivery-element-edit/delivery-element-edit.modal';
import { DeliveryElementEditComponent } from './components/form/delivery-element-edit/delivery-element-edit.component';
import {
  DeliveryElementEditFileComponent,
} from './components/form/delivery-element-edit/delivery-element-edit-file/delivery-element-edit-file.component';
import {
  DeliveryPackageTransitionModal,
} from './components/modal/delivery-package-transition/delivery-package-transition.modal';
import { TransitionCommentComponent } from './components/form/transition-comment/transition-comment.component';
import {
  DeliveryPackageGalleryComponent,
} from './components/delivery-package-gallery/delivery-package-gallery.component';
import {
  DeliveryPackageDownloadComponent,
} from './components/delivery-package-download/delivery-package-download.component';
import {
  FolderRootlineItemComponent,
} from './components/folder-rootline/folder-rootline-item/folder-rootline-item.component';
import { PageComponent } from './components/page/page.component';
import { DeliveryStatusComponent } from './components/delivery-status/delivery-status.component';
import { DeliveryPackageStatusComponent } from './components/delivery-package-status/delivery-package-status.component';
import { TimeagoModule } from 'ngx-timeago';
import { DeliveryAddUserModal } from './components/modal/delivery-add-user/delivery-add-user.modal';
import { DeliveryPackageEditComponent } from './components/form/delivery-package-edit/delivery-package-edit.component';
import {
  DeliveryPackageEditFilesComponent,
} from './components/form/delivery-package-edit/delivery-element-edit-file/delivery-package-edit-files.component';
import { DeliveryPackageEditModal } from './components/modal/delivery-package-edit/delivery-package-edit.modal';
import { UploadComponent } from './components/upload/upload.component';
import { DeliveryPublicLinksComponent } from './components/delivery-public-links/delivery-public-links.component';
import { PublicDeliveryComponent } from './components/public-delivery/public-delivery.component';
import { NotificationComponent } from '../core/components/notification/notification.component';
import { ImportClubsComponent } from './components/modal/import-clubs/import-clubs.component';
import { DeliveryAddTeamEmblemModal } from './components/modal/delivery-add-team-emblem/delivery-add-team-emblem.modal';
import { ClubRowItemComponent } from './components/modal/import-clubs/club-row-item/club-row-item.component';
import {
  DeliveryFeedBdToClubComponent,
} from './components/delivery-feed-bd-to-club/delivery-feed-bd-to-club.component';
import {
  DeliveryFeedClubToBdComponent,
} from './components/delivery-feed-club-to-bd/delivery-feed-club-to-bd.component';
import {
  FeedTypeDeliveryClubComponent,
} from './components/delivery-feed/feed-type-delivery-club/feed-type-delivery-club.component';
import {
  FeedTypeDeliveryPackageActionsComponent,
} from './components/delivery-feed/feed-type-delivery-package/feed-type-delivery-package-actions/feed-type-delivery-package-actions.component';
import {
  DeliveryPackageSettingsComponent,
} from './components/delivery-package-settings/delivery-package-settings.component';
import { RequestorsComponent } from './components/requestors/requestors.component';
import { RequestorService } from './services/requestor.service';
import { InviteOrEditRequestorModal } from './components/modal/invite-or-edit-requestor/invite-or-edit-requestor.modal';
import { RegionService } from './services/region.service';
import { NgxGalleryModule } from '@kolkov/ngx-gallery';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { BdUploadComponent } from './components/bd-upload/bd-upload.component';
import { RequestPacksComponent } from './components/request-packs/request-packs.component';
import { ImportService } from './services/import.service';
import { SelectRegionComponent } from './components/request-packs/select-region/select-region.component';
import { ImportComponent } from './components/request-packs/import/import.component';
import { ImportClubRowItemComponent } from './components/request-packs/import/club-row-item/club-row-item.component';
import { ImportQueueEntryComponent } from './components/request-packs/import-queue-entry/import-queue-entry.component';
import { ImportQueueGuard } from './guards/import-queue.guard';
import { ClubFormComponent } from './components/request-packs/club-form/club-form.component';
import { FolderService } from './services/folder.service';
import { NewRequestComponent } from './components/request-packs/new-request/new-request.component';
import { AdminDeliveryListComponent } from './components/admin-delivery-list/admin-delivery-list.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { ClubPacksHeaderComponent } from './components/club-packs-header/club-packs-header.component';
import { LegalLineListComponent } from './components/legal-line-list/legal-line-list.component';
import { LegalLineService } from './services/legal-line.service';
import {
  CreateOrEditLegalLineModal
} from './components/modal/create-or-edit-legal-line/create-or-edit-legal-line.modal';
import { BudgetComponent } from './components/budget/budget.component';
import { BudgetService } from './services/budget.service';
import { DeleteDeliveryModal } from './components/modal/delete-delivery/delete-delivery.modal';
import {NgxSplideModule} from "ngx-splide";

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    BdFilterModule,
    FormUtilitiesModule,
    FormsModule,
    BdKravlingModule,
    TabsModule,
    NgxLoadingModule,
    ReactiveFormsModule,
    FileUploadModule,
    ModalModule,
    TooltipModule,
    NgxGalleryModule,
    BsDropdownModule.forRoot(),
    TimeagoModule,
    NgxSplideModule
  ],
  declarations: [
    DashboardComponent,
    DeliveryListComponent,
    DeliveryFeedComponent,
    FeedTypeDeliveryPackageComponent,
    FolderRootlineComponent,
    FeedItemComponent,
    FeedTypeCommentComponent,
    FeedTypeActivityComponent,
    CommentComponent,
    FileListComponent,
    FileComponent,
    DeliveryElementEditModal,
    DeliveryElementEditComponent,
    DeliveryElementEditFileComponent,
    DeliveryPackageTransitionModal,
    TransitionCommentComponent,
    DeliveryPackageGalleryComponent,
    DeliveryPackageDownloadComponent,
    FolderRootlineItemComponent,
    PageComponent,
    DeliveryStatusComponent,
    DeliveryPackageStatusComponent,
    DeliveryAddUserModal,
    DeliveryPackageEditComponent,
    DeliveryPackageEditFilesComponent,
    DeliveryPackageEditModal,
    DeliveryAddTeamEmblemModal,
    UploadComponent,
    DeliveryPublicLinksComponent,
    PublicDeliveryComponent,
    NotificationComponent,
    ImportClubsComponent,
    ClubRowItemComponent,
    DeliveryFeedBdToClubComponent,
    DeliveryFeedClubToBdComponent,
    FeedTypeDeliveryClubComponent,
    FeedTypeDeliveryPackageActionsComponent,
    DeliveryPackageSettingsComponent,
    RequestorsComponent,
    InviteOrEditRequestorModal,
    BdUploadComponent,
    RequestPacksComponent,
    SelectRegionComponent,
    ImportComponent,
    ImportClubRowItemComponent,
    ImportQueueEntryComponent,
    ClubFormComponent,
    NewRequestComponent,
    AdminDeliveryListComponent,
    ProgressBarComponent,
    ClubPacksHeaderComponent,
    LegalLineListComponent,
    CreateOrEditLegalLineModal,
    BudgetComponent,
    DeleteDeliveryModal,
  ],
  exports: [
    DashboardComponent,
    DeliveryFeedComponent,
    PageComponent,
    PublicDeliveryComponent,
    RequestorsComponent,
    RequestPacksComponent,
    SelectRegionComponent,
    ImportComponent,
    ImportQueueEntryComponent,
    NewRequestComponent,
    AdminDeliveryListComponent,
    ProgressBarComponent,
    ClubPacksHeaderComponent,
    LegalLineListComponent,
    BudgetComponent,
  ],
  providers: [
    RequestorService,
    RegionService,
    ImportService,
    FolderService,
    LegalLineService,
    ImportQueueGuard,
    BudgetService,
  ],
})
export class ApprovalModule {
}
