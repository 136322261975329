import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { DeliveryService } from '@kravling/modules/core/services/delivery.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { take } from 'rxjs/operators';
import { NotificationService } from '../../../../../services/notification.service';

@Component({
  selector: 'app-import-clubs',
  templateUrl: './import-clubs.component.html',
})
export class ImportClubsComponent implements OnInit {
  sheetRows: any[];
  tabsView: any;
  isLoadingClubs = false;
  isLoadingTabs = false;
  isLoadingImportClubs = false;
  activeTabIndex = 0;
  checkedArray: number[] = [];

  @Output()
  onSaveSuccess = new EventEmitter<void>();

  constructor(
    public bsModalRef: BsModalRef,
    private deliveryService: DeliveryService,
    private notificationService: NotificationService,
  ) {}

  ngOnInit() {
    this.getAllTabs();
  }

  getAllTabs(): void {
    this.isLoadingTabs = true;

    this.deliveryService
      .getAllTabsFromSheet$()
      .pipe(take(1))
      .subscribe(
        (_) => {
          this.tabsView = _;
          this.isLoadingTabs = false;

          if (this.tabsView) {
            this.getClubs(this.tabsView.tabs[0].index);
          }
        },
        (error) => {
          this.notificationService.emplace({
            message: error.statusText,
            severity: 'error',
            id: Math.random(),
          });

          this.bsModalRef.hide();
        },
      );
  }

  getClubs(tabIndex: number): void {
    this.isLoadingClubs = true;
    this.deliveryService
      .getAllClubs$(tabIndex)
      .pipe(take(1))
      .subscribe(
        (_) => {
          this.sheetRows = _.tab.rows;
          this.activeTabIndex = tabIndex;
          this.checkedArray = [];
          this.isLoadingClubs = false;
        },
        (error) => {
          this.notificationService.emplace({
            message: error.statusText,
            severity: 'error',
            id: Math.random(),
          });

          this.bsModalRef.hide();
        },
      );
  }

  importClubs(): void {
    this.isLoadingImportClubs = true;
    this.deliveryService
      .postImportClubs$(this.activeTabIndex, this.checkedArray)
      .pipe(take(1))
      .subscribe(
        (response) => {
          this.isLoadingImportClubs = false;
          this.notificationService.emplace({
            message:
              'Import of ' +
              this.checkedArray.length +
              ' Clubs successful. You can upload designs for the respective approvers now',
            severity: 'info',
            id: Math.random(),
          });

          if (response.missingCrest && response.missingCrest.length) {
            this.notificationService.emplace({
              message: 'The crests for the following clubs couldn’t be imported. Please check ' + response.missingCrest,
              severity: 'error',
              id: Math.random(),
            });
          }

          if (response.missingMedia && response.missingMedia.length) {
            this.notificationService.emplace({
              message:
                'The crests for the following clubs couldn’t be imported. Please check the associated files: ' +
                response.missingMedia,
              severity: 'error',
              id: Math.random(),
            });
          }

          this.checkedArray = [];
          this.bsModalRef.hide();
          this.onSaveSuccess.emit();
          this.getClubs(this.activeTabIndex);
        },
        (error) => {
          this.notificationService.emplace({
            message: error.statusText,
            severity: 'error',
            id: Math.random(),
          });

          this.bsModalRef.hide();
        },
      );
  }
}
