import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-new-request',
  templateUrl: './new-request.component.html',
})
export class NewRequestComponent {

  constructor(
    private router: Router,
    private translate: TranslateService,
  ) {
  }

  navigateToDasboard(): void {
    this
      .router
      .navigate([
        '/',
        this.translate.currentLang,
        'dashboard'
      ])
    ;
  }

}
