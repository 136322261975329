<div class="container">
  <div class="m04">
    <app-club-packs-header
      headline="Club Launch Suite"
    >
      <ng-container *ngIf="isSuperAdmin">
        <button class="btn btn--big btn-primary" (click)="openInviteOrEditRequestorModal()">
          <span>{{ 'Invite requestor' }}</span>
        </button>
      </ng-container>
    </app-club-packs-header>
    <!--<div class="m04__filter">
      <filter-component
        type="text"
        [filterPlaceholder]="'Search for requestors' | translate"
        [params]="{ properties: [] }"
        (onRefreshFilter)="$event"
      ></filter-component>
    </div>-->
    <ng-container *ngIf="(requestorService.requestors$ | async) as requestors; else noResults">
      <div
        class="m08"
        *ngFor="let requestor of requestors"
        (click)="$event.preventDefault(); openInviteOrEditRequestorModal(requestor)"
      >
        <div class="m08__text">
          <div class="d-flex flex-row align-items-center">
            <div>
              <h3>
                {{ requestor.firstName }} {{ requestor.lastName }}
              </h3>
              <div class="text-muted">
                {{ requestor.email }}
              </div>
            </div>
            <div class="d-flex ml-auto text-muted align-items-center">
              <div class="float-right" *ngIf="requestor.regions.length > 0">
                Region: <span *ngFor="let region of requestor.regions; let isLast = last">{{ region.name }}<ng-container *ngIf="!isLast">, </ng-container></span>
              </div>
              <i class="fa fa-pencil-alt ml-2"></i>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-template #noResults>
      <div class="m20">
        There are no results
      </div>
    </ng-template>
  </div>
</div>
