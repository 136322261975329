import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { FormUtilitiesModule } from '@braune-digital/form-utilities';
import { BdFilterModule } from '@braune-digital/angular-query-filter';
import { BdKravlingModule } from '@kravling/bd.kravling.module';
import { NotFoundPage } from './security/not-found/not-found.page';
import { SecurityPageModule } from './security/security.routing';
import { ApprovalPageModule } from './approval/approval.routing';
import { environment } from '../../environments/environment';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TabsModule } from 'ngx-bootstrap/tabs';

export const routes: Routes = [
  {
    path: '',
    redirectTo: environment.defaultLocale + '/dashboard',
    pathMatch: 'full',
  },
  { path: ':lang', redirectTo: ':lang/dashboard', pathMatch: 'full' },

  // 404 Route (has to initialized at the end)
  { path: '**', component: NotFoundPage },
  { path: ':lang/404', pathMatch: 'full', component: NotFoundPage },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
    onSameUrlNavigation: 'reload',
    paramsInheritanceStrategy: 'always',
    anchorScrolling: 'enabled',
    relativeLinkResolution: 'legacy'
}),
    TranslateModule,

    // BD Imports
    BdKravlingModule,
    BdFilterModule,
    FormUtilitiesModule.forRoot({
      displayErrors: true,
      classFormGroup: 'form-group',
      classFromInput: 'form-input',
      classFormInputGroup: 'input-group',
      classFormInputGroupPrepend: 'input-group-prepend',
      classFormInputGroupAppend: 'input-group-append',
      classFormInputGroupText: 'input-group-text',
      classFromLegend: 'form-legend',
      classFormLabel: 'form-label',
      classFormControl: 'form-control',
      classFormCounter: 'form-counter',
      classFormError: 'form-errors',
      classFormHelp: 'form-help',
      classFormRequired: 'form-required',
      classIconBase: 'far',
      requiredString: '*',
      maxLengthString: 'Noch %s Zeichen',
      minLengthString: 'Es fehlen noch %s Zeichen',
      counterBefore: false,
    }),

    // Ngx-Bootstrap
    PaginationModule.forRoot(),
    ModalModule.forRoot(),
    TabsModule.forRoot(),
    SecurityPageModule,
    ApprovalPageModule,
  ],
  declarations: [],
  exports: [RouterModule],
})
export class RoutingModule {}
