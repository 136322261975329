<nav class="navbar navbar-expand-lg navbar-dark fixed-top">
  <a class="navbar-brand" href="#" [routerLink]="['/']">
    <img src="/assets/images/Logo_EA_FC.svg" alt="EA SPORTS FC" />
  </a>
</nav>

<div class="content">
  <ng-container *ngIf="!isLoading">
    <app-approval-public-delivery
      [delivery]="delivery"
      *ngIf="delivery && !linkExpired && !deliveryNotFound; else error"
    ></app-approval-public-delivery>
    <ng-template #error>
      <div class="container">
        <div class="row">
          <div class="col-sm-9 col-md-7 col-lg-5 mx-auto">
            <div class="card card-signin my-5">
              <div class="card-body">
                <div class="text-center mb-3">
                  <img src="/assets/images/ea.svg" alt="EA" width="100" />
                </div>
                <p class="card-body text-center">
                  <ng-container *ngIf="linkExpired">
                    {{ 'pages.public_delivery.link_expired'|translate }}
                  </ng-container>
                  <ng-container *ngIf="deliveryNotFound">
                    {{ 'pages.public_delivery.delivery_not_found'|translate }}
                  </ng-container>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </ng-container>
  <ng-template #loading>
    <ngx-loading [show]="isLoading"></ngx-loading>
  </ng-template>
</div>
