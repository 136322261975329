import { Component, Input, OnInit } from '@angular/core';
import { Notification, NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
})
export class NotificationComponent implements OnInit {
  @Input()
  notification!: Notification;

  constructor(private _notificationService: NotificationService) {}

  ngOnInit(): void {
    if (this.notification.severity !== 'error') {
      setTimeout(() => {
        this.close();
      }, 6000);
    }
  }

  close() {
    this._notificationService.remove(this.notification.id);
  }
}
