import { Component, OnInit } from '@angular/core';
import { RequestorService } from '../../services/requestor.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../../security/services/auth.service';
import { InviteOrEditRequestorModal } from '../modal/invite-or-edit-requestor/invite-or-edit-requestor.modal';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: '<app-requestors>',
  templateUrl: './requestors.component.html',
})
export class RequestorsComponent implements OnInit {

  isSuperAdmin = AuthService.user
    && AuthService.user.roles.some((r) => r === 'ROLE_SUPER_ADMIN');

  inviteOrEditRequestorModal: BsModalRef;

  constructor(
    public requestorService: RequestorService,
    public translate: TranslateService,
    public modalService: BsModalService,
  ) {
  }

  ngOnInit(): void {
    this
      .requestorService
      .updateRequestors()
    ;
  }

  openInviteOrEditRequestorModal(requestor?: any): void {
    this.inviteOrEditRequestorModal = this.modalService.show(InviteOrEditRequestorModal, {
      class: '',
    });
    this.inviteOrEditRequestorModal.content.requestor = requestor;
    this.inviteOrEditRequestorModal.content.onSaveSuccess.subscribe(() => {
      this
        .requestorService
        .updateRequestors()
      ;
    });
  }

}
