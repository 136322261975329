import { Component, Input } from '@angular/core';
import { FolderListViewInterface } from '@kravling/modules/core/models/View/FolderListViewInterface';

@Component({
  selector: '<app-approval-folder-rootline>',
  templateUrl: './folder-rootline.component.html',
})
export class FolderRootlineComponent {
  @Input() rootline: FolderListViewInterface[];
  @Input() showHome: boolean = true;
  @Input() showChildren: boolean = true;
}
