<div class="container">
  <div class="m04" *ngIf="!isLoading">
    <app-club-packs-header
      headline="Launch suite request"
    ></app-club-packs-header>
  </div>

  <div class="card">
    <div class="card-body">
      <div class="m16">
        <h3 class="mb-3">
          Were the clubs you want to add your request for part of the FC 24 Club Packs?
        </h3>
        <div class="mb-3 text-center">
          <div class="buttons">
            <a class="btn btn-primary mr-2" [routerLink]="['/', translate.currentLang, 'request-packs','select-region']">
              {{ 'Yes' }}
            </a>
            <a class="btn btn-secondary" [routerLink]="['/', translate.currentLang, 'request-packs','new-request']" (click)="$event.preventDefault()">
              {{ 'No' }}
            </a>
          </div>
        </div>
        <p>
          <small>
            If you’re not sure, you can check here:<br>
            <a href="https://fc24-club-packs.ea.com" target="_blank" title="EA SPORTS FC24 Club Packs">
              https://fc24-club-packs.ea.com
            </a>
          </small>
        </p>
      </div>
    </div>
  </div>
  <!--<ng-container [ngSwitch]="currentView">
    <ng-container *ngSwitchCase="requestPackViews.ASK_FOR_PREV_YEAR.toString()">
    </ng-container>
    <ng-container *ngSwitchCase="requestPackViews.SELECT_REGION.toString()">
      <div class="card">
        <div class="card-body">
          <h3>
            {{ 'Import Clubs from fifa 22?' }}
          </h3>
          <div class="alert alert-info">
            After importing, you will get the option to edit all Club data in the next step.  Please import a promoted or relegated club and change the league in the next step.
          </div>
          <div>
            <a href="#" (click)="$event.preventDefault()">
              {{ 'Yes' }}
            </a>
            <a href="#" (click)="$event.preventDefault()">
              {{ 'No' }}
            </a>
          </div>
          <p>
            If you’re not sure, you can check here:<br>
            https://fifa22-club-packs.ea.com
          </p>
        </div>
      </div>
    </ng-container>
  </ng-container>-->

</div>

