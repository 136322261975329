import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DeliveryReadViewInterface } from '@kravling/modules/core/models/View/DeliveryReadViewInterface';
import { DeliveryPackageListViewInterface } from '@kravling/modules/core/models/View/DeliveryPackageListViewInterface';
import UserInterface from '../../../../models/interfaces/user.interface';
import { Roles } from '@kravling/modules/core/models/RolesEnum';
import { CommentReadViewInterface } from '@kravling/modules/core/models/View/CommentReadViewInterface';
import { DeliveryFeedItemInterface } from '@kravling/modules/core/models/DeliveryFeedItemInterface';
import { DeliveryFeedType } from '@kravling/modules/core/models/DeliveryFeedType.enum';
import { DeliveryStates } from '@kravling/modules/core/models/DeliveryInterface';
import { TranslateService } from '@ngx-translate/core';
import { DeliveryPackageStates } from '@kravling/modules/core/models/DeliveryPackageInterface';

@Component({
  selector: 'app-delivery-feed-bd-to-club',
  templateUrl: './delivery-feed-bd-to-club.component.html',
})
export class DeliveryFeedBdToClubComponent implements OnInit {
  @Input()
  delivery: DeliveryReadViewInterface;

  @Input()
  imageDelivery: DeliveryReadViewInterface;

  @Input()
  imageDeliveryLatestDeliveryPackage?: DeliveryPackageListViewInterface;

  @Input()
  selectedDeliveryPackage: {
    deliveryPackage: DeliveryPackageListViewInterface;
    index: number;
  };

  @Input()
  isSuperAdmin: boolean;

  @Input()
  showDetails: boolean;

  @Input()
  canRemoveUser: boolean;

  @Input()
  canAddUser: boolean;

  @Input()
  user: UserInterface;

  @Input()
  feed: DeliveryFeedItemInterface[];

  @Input()
  isLoading: boolean;

  @Output()
  showEditEmblemModal = new EventEmitter<void>();

  @Output()
  removeUser = new EventEmitter<{ user: UserInterface; users: UserInterface[]; type: string; i: number }>();

  @Output()
  openAddUserModal = new EventEmitter<{ type: string; users: UserInterface[] }>();

  @Output()
  toggleComments = new EventEmitter<void>();

  @Output()
  handleOnNewComment = new EventEmitter<CommentReadViewInterface>();

  @Output()
  getFeed = new EventEmitter<void>();

  roles = Roles;
  deliveryFeedType = DeliveryFeedType;
  deliveryStates = DeliveryStates;
  deliveryPackageStates = DeliveryPackageStates;

  constructor(
    public translate: TranslateService
  ) {}

  ngOnInit() {}
}
