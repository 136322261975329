import {Component, EventEmitter, Input, OnDestroy, Output} from '@angular/core';
import {
    DeliveryPackageStates,
    DeliveryPackageTransitions
} from '@kravling/modules/core/models/DeliveryPackageInterface';
import {DeliveryPackageFeedItemInterface} from '@kravling/modules/core/models/DeliveryPackageFeedItemInterface';
import {Roles} from '@kravling/modules/core/models/RolesEnum';
import {DeliveryPackageTransitionModal} from '../../../modal/delivery-package-transition/delivery-package-transition.modal';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FormGroup } from '@angular/forms';
import { DeliveryPackageService } from '@kravling/modules/core/services/delivery-package.service';

@Component({
  selector: '<app-approval-delivery-feed-item-delivery-package-actions>',
  templateUrl: './feed-type-delivery-package-actions.component.html',
})
export class FeedTypeDeliveryPackageActionsComponent {

    @Input() item: DeliveryPackageFeedItemInterface;
    @Input() readOnly: boolean;
    @Input() workflow: string = 'default';
    @Input() hasOpenedGallery: boolean;
    @Input() checkFormValidity: boolean = false;
    @Input() form: FormGroup;
    @Output() reload = new EventEmitter<boolean>();
    @Output() clone = new EventEmitter<boolean>();
    @Output() openGallery = new EventEmitter<boolean>();

    deliveryElementEditModal: BsModalRef;
    DeliveryPackageTransitions = DeliveryPackageTransitions;

    constructor(private modalService: BsModalService, public deliveryPackageService: DeliveryPackageService) {}

    openTransitionModal(transition: string): void {
        if (this.isTransitionDisabled(transition)) {
            return;
        }
        if (this.checkFormValidity && this.form && !this.form.valid) {
            return;
        }
        this.deliveryElementEditModal = this.modalService.show(DeliveryPackageTransitionModal, {
            class: 'modal--default',
        });
        this.deliveryElementEditModal.content.transition = transition;
        this.deliveryElementEditModal.content.workflow = this.workflow;
        this.deliveryElementEditModal.content.deliveryPackage = this.item.object;
        this.deliveryElementEditModal.content.onTransitionSuccess.subscribe((_) => {
            this.reload.emit(true);
        });
    }

    /**
     * canClone
     */
    get canClone(): boolean {
        if (
            this.item.role === Roles.DELIVERER &&
            this.item.object.state === DeliveryPackageStates.REJECTED &&
            this.item.object.isCurrent
        ) {
            return true;
        }
        return false;
    }

    get showGalleryInfo(): boolean {
        if (
            !this.item.object.actions.includes(DeliveryPackageTransitions.APPROVE.toString()) &&
            !this.item.object.actions.includes(DeliveryPackageTransitions.REJECT.toString())
        ) {
            return false;
        }
        return (
            this.isTransitionDisabled(DeliveryPackageTransitions.APPROVE.toString()) ||
            this.isTransitionDisabled(DeliveryPackageTransitions.REJECT.toString())
        );
    }

    isTransitionDisabled(action: string): boolean {
        if (
            action !== DeliveryPackageTransitions.REJECT.toString() &&
            action !== DeliveryPackageTransitions.APPROVE.toString()
        ) {
            return false;
        }
        if (this.item.role !== Roles.DELIVERER && !this.hasOpenedGallery) {
            return true;
        }
        return false;
    }

}
