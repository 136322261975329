<div class="modal-header">
  <h5 class="modal-title">{{ 'Import new clubs' | translate }}</h5>
  <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
  <ngx-loading [show]="isLoadingTabs"></ngx-loading>
</div>
<ng-container *ngIf="!isLoadingTabs">
  <div class="doc-filename">
    <i class="fa fa-table"></i>
    <span *ngIf="tabsView?.sheetName">{{ tabsView.sheetName }}</span>
  </div>
  <div class="modal-body">
    <div class="row table-header">
      <div class="col-xs-number"></div>
      <div class="col-xs">Ready</div>
      <div class="col-sm">Country</div>
      <div class="col-sm">League</div>
      <div class="col-sm">Club</div>
    </div>

    <div class="table-data-container">
      <ngx-loading [show]="isLoadingClubs"></ngx-loading>
      <ng-container *ngIf="!isLoadingClubs && sheetRows && sheetRows.length">
        <club-row-item
          *ngFor="let row of sheetRows"
          [club]="row.club"
          [rowNumber]="row.rowNumber"
          [rowArray]="checkedArray"
        ></club-row-item>
      </ng-container>
    </div>
  </div>
  <div class="modal-footer">
    <div class="tabs-view" *ngIf="tabsView && tabsView.tabs && tabsView.tabs.length">
      <button
        class="tab"
        *ngFor="let tab of tabsView.tabs"
        [ngClass]="{ active: activeTabIndex === tab.index }"
        (click)="getClubs(tab.index)"
      >
        {{ tab.title }}
      </button>
    </div>

    <div class="btn-container">
      <button
        type="button"
        class="btn btn-primary"
        (click)="importClubs()"
        [disabled]="checkedArray.length === 0 || this.isLoadingImportClubs"
      >
        <ngx-loading [show]="isLoadingImportClubs"></ngx-loading>
        {{ 'Import selected' | translate }}
      </button>
      <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="bsModalRef.hide()">
        {{ 'Close' | translate }}
      </button>
    </div>
  </div>
</ng-container>
