<div class="card m04__progress">
  <div class="card-body">
    <div class="row">
      <div class="col-12 col-md-7">
        <h2 class="card-title">
          {{ headline }}
        </h2>
        <p class="card-text">
          {{ text }}
        </p>
        <p class="card-text">
          {{ text2 }}
        </p>
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>
