import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../../modules/security/guards/authentication.guard';
import { DashboardPage } from './dashboard/dashboard.page';
import { ApprovalModule } from '../../modules/approval/approval.module';
import { DeliveryPage } from './delivery/delivery.page';
import { FolderPage } from './folder/folder.page';
import { PublicDeliveryPage } from './public-delivery/public-delivery.page';
import { NgxLoadingModule } from 'ngx-loading';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import {RequestorsPage} from './requestors/requestors.page';
import { RequestPacksPage } from './request-packs/request-packs.page';
import { SelectRegionPage } from './select-region/select-region.page';
import { ImportPage } from './import/import.page';
import { ImportQueueEntryPage } from './import-queue-entry/import-queue-entry.page';
import { ImportQueueGuard } from '../../modules/approval/guards/import-queue.guard';
import { NewRequestPage } from './new-request/new-request.page';
import { RequestsPage } from './requests/requests.page';
import { LegalLineListPage } from './legal-line-list/legal-line-list.page';
import { BudgetPage } from './budget/budget.page';

export const DashboardRoutes: Routes = [
  // ---------------------------
  // Dashboard
  // ---------------------------

  {
    path: ':lang/dashboard',
    canActivate: [AuthGuard],
    component: DashboardPage,
    data: {
      routeName: 'dashboard',
    },
  },
  {
    path: ':lang/requests',
    canActivate: [AuthGuard],
    component: RequestsPage,
    data: {
      routeName: 'requests',
    },
  },
  {
    path: ':lang/request-packs',
    canActivate: [AuthGuard, ImportQueueGuard],
    component: RequestPacksPage,
    data: {
      routeName: 'request_packs',
    },
  },
  {
    path: ':lang/request-packs/select-region',
    canActivate: [AuthGuard, ImportQueueGuard],
    component: SelectRegionPage,
    data: {
      routeName: 'select_region',
    },
  },
  {
    path: ':lang/request-packs/new-request',
    canActivate: [AuthGuard, ImportQueueGuard],
    component: NewRequestPage,
    data: {
      routeName: 'new_request',
    },
  },
  {
    path: ':lang/request-packs/import/:region',
    canActivate: [AuthGuard, ImportQueueGuard],
    component: ImportPage,
    data: {
      routeName: 'import',
    },
  },
  {
    path: ':lang/import-queue/:entry',
    canActivate: [AuthGuard],
    component: ImportQueueEntryPage,
    data: {
      routeName: 'import_queue_entry',
    },
  },
  {
    path: ':lang/requestors',
    canActivate: [AuthGuard],
    component: RequestorsPage,
    data: {
      routeName: 'requestors',
    },
  },
  {
    path: ':lang/d/:deliveryId',
    canActivate: [AuthGuard],
    component: DeliveryPage,
    data: {
      routeName: 'delivery',
    },
  },
  {
    path: ':lang/f/:folderId',
    canActivate: [AuthGuard],
    component: FolderPage,
    data: {
      routeName: 'folder',
    },
  },
  {
    path: ':lang/p/:publicDeliveryToken',
    canActivate: [],
    component: PublicDeliveryPage,
    data: {
      routeName: 'public_folder',
    },
  },
  {
    path: ':lang/legal-lines',
    canActivate: [AuthGuard],
    component: LegalLineListPage,
    data: {
      routeName: 'legal_lines',
    },
  },
  {
    path: ':lang/budget',
    canActivate: [AuthGuard],
    component: BudgetPage,
    data: {
      routeName: 'budget',
    },
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(DashboardRoutes),
    ApprovalModule,
    CommonModule,
    NgxLoadingModule,
    TranslateModule
  ],
  declarations: [
    DashboardPage,
    DeliveryPage,
    FolderPage,
    PublicDeliveryPage,
    RequestorsPage,
    RequestPacksPage,
    SelectRegionPage,
    ImportPage,
    ImportQueueEntryPage,
    NewRequestPage,
    RequestsPage,
    LegalLineListPage,
    BudgetPage,
  ],
  exports: [RouterModule],
})
export class ApprovalPageModule {}
