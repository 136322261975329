import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { DeliveryService } from '@kravling/modules/core/services/delivery.service';
import { take } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { DeliveryFeedItemInterface } from '@kravling/modules/core/models/DeliveryFeedItemInterface';
import { DeliveryFeedType } from '@kravling/modules/core/models/DeliveryFeedType.enum';
import { DeliveryReadViewInterface } from '@kravling/modules/core/models/View/DeliveryReadViewInterface';
import { CommentReadViewInterface } from '@kravling/modules/core/models/View/CommentReadViewInterface';
import { DeliveryPackageListViewInterface } from '@kravling/modules/core/models/View/DeliveryPackageListViewInterface';
import { Roles } from '@kravling/modules/core/models/RolesEnum';
import { DeliveryStates } from '@kravling/modules/core/models/DeliveryInterface';
import UserInterface from '../../../../models/interfaces/user.interface';
import { DeliveryAddUserModal } from '../modal/delivery-add-user/delivery-add-user.modal';
import { UserListViewInterface } from '@kravling/modules/core/models/View/UserListViewInterface';
import { DeliveryPackageFeedItemInterface } from '@kravling/modules/core/models/DeliveryPackageFeedItemInterface';
import { DeliveryPackageStates } from '@kravling/modules/core/models/DeliveryPackageInterface';
import { AuthService } from '../../../security/services/auth.service';
import { DeliveryAddTeamEmblemModal } from '../modal/delivery-add-team-emblem/delivery-add-team-emblem.modal';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: '<app-approval-delivery-feed>',
  templateUrl: './delivery-feed.component.html',
})
export class DeliveryFeedComponent implements OnInit, OnDestroy, OnChanges {
  @Input() deliveryId: string;

  delivery: DeliveryReadViewInterface;
  imageDelivery: DeliveryReadViewInterface;
  imageDeliveryLatestDeliveryPackage?: DeliveryPackageListViewInterface;
  feed: DeliveryFeedItemInterface[];
  isLoading = false;
  deliveryFeedType = DeliveryFeedType;
  selectedDeliveryPackage: {
    deliveryPackage: DeliveryPackageListViewInterface;
    index: number;
  };
  showDetails = false;
  DeliveryStates = DeliveryStates;
  addUserModal: BsModalRef;
  editEmblemModal: BsModalRef;
  user: UserInterface;
  roles = Roles;

  isSuperAdmin = AuthService.user && AuthService.user.roles.some((r) => r === 'ROLE_SUPER_ADMIN');

  constructor(
    public deliveryService: DeliveryService,
    public translate: TranslateService,
    private modalService: BsModalService,
  ) {}

  ngOnInit() {
    this.user = AuthService.user;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.getDelivery();
  }

  getDelivery(): void {
    this.deliveryService
      .get$(this.deliveryId)
      .pipe(take(1))
      .subscribe(
        (_) => {
          this.delivery = _;
          this.getFeed();
          if (this.delivery.imageDeliveryId) {
            this.getImageDelivery();
          }
        },
        (error) => {},
      );
  }

  getImageDelivery(): void {
    this.deliveryService
      .get$(this.delivery.imageDeliveryId)
      .pipe(take(1))
      .subscribe(
        (_) => {
          this.imageDelivery = _;
          this.getImageDeliveryFeed();
        },
        (error) => {},
      );
  }

  getFeed(): void {
    this.isLoading = true;
    this.deliveryService
      .getFeed$(this.delivery.id)
      .pipe(take(1))
      .subscribe(
        (_) => {
          this.feed = _;
          this.isLoading = false;
        },
        (error) => {},
      );
  }

  getImageDeliveryFeed(): void {
    this.deliveryService
      .getFeed$(this.imageDelivery.id)
      .pipe(take(1))
      .subscribe(
        (_) => {
          const deliveryPackage = this
            .getLatestDeliveryPackageOfImageDeliveryOrNull(_);
          this.imageDeliveryLatestDeliveryPackage = (deliveryPackage !== null)
            ? deliveryPackage.object
            : null
          ;
        },
        (error) => {},
      );
  }

  getLatestDeliveryPackageOfImageDeliveryOrNull(feedItems: DeliveryFeedItemInterface[]): DeliveryPackageFeedItemInterface|null {
    const deliveryPackages = feedItems.filter(_ => _.type === DeliveryFeedType.DELIVERY_PACKAGE);
    if (deliveryPackages.length === 0) {
      return null;
    }
    return deliveryPackages[0] as DeliveryPackageFeedItemInterface;
  }

  handleOnNewComment(comment: CommentReadViewInterface) {
    this.getFeed();
  }

  get canRemoveUser(): boolean {
    return this.canAddUser;
  }

  get canAddUser(): boolean {
    if (!this.delivery) {
      return false;
    }
    return this.delivery.role === Roles.DELIVERER.toString() || this.delivery.role === Roles.REQUESTER.toString();
  }

  openAddUserModal(event: { type: string; users: UserInterface[] }): void {
    this.addUserModal = this.modalService.show(DeliveryAddUserModal, {
      class: 'modal--default',
    });
    this.addUserModal.content.delivery = this.delivery;
    this.addUserModal.content.type = event.type;
    this.addUserModal.content.onSaveSuccess.subscribe((_: UserListViewInterface) => {
      event.users.push(_);
    });
  }

  /**
   * @param user
   * @param users
   * @param type
   * @param i
   */
  addUser(user: UserInterface, users: UserInterface[], type: string, i: number): void {
    this.deliveryService
      .removeApproverOrObserver$(this.delivery.id, type, user.id)
      .pipe(take(1))
      .subscribe((_) => {
        users.splice(i, 1);
      });
  }

  removeUser(event: { user: UserInterface; users: UserInterface[]; type: string; i: number }): void {
    this.deliveryService
      .removeApproverOrObserver$(this.delivery.id, event.type, event.user.id)
      .pipe(take(1))
      .subscribe((_) => {
        event.users.splice(event.i, 1);
      });
  }

  get hasFirstDelivery(): boolean {
    if (!this.feed) {
      return false;
    }
    const deliveryPackageFeedItems = this.feed.filter((_) => _.type === DeliveryFeedType.DELIVERY_PACKAGE.toString());
    if (deliveryPackageFeedItems.length === 0) {
      return false;
    }
    const deliveryPackage = deliveryPackageFeedItems[0] as DeliveryPackageFeedItemInterface;
    if (deliveryPackage.object.state !== DeliveryPackageStates.NEW) {
      return true;
    }
    return false;
  }

  toggleComments(): void {
    this.deliveryService
      .toggleComments$(this.delivery.id)
      .pipe(take(1))
      .subscribe((_: { enabled: boolean }) => {
        this.delivery.enableComments = _.enabled;
      });
  }

  showEditEmblemModal() {
    this.editEmblemModal = this.modalService.show(DeliveryAddTeamEmblemModal, {
      class: 'modal--default',
    });
    this.editEmblemModal.content.delivery = this.delivery;
    this.editEmblemModal.content.onSaveSuccess.subscribe(() => {
      this.deliveryService
        .get$(this.deliveryId)
        .pipe(take(1))
        .subscribe(
          (_) => {
            this.delivery = _;
            this.getFeed();
          },
          (error) => {},
        );
    });
  }

  ngOnDestroy(): void {
    this.selectedDeliveryPackage = null;
    this.showDetails = false;
  }
}
