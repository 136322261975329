import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { registerLocaleData } from '@angular/common';

import localeDE from '@angular/common/locales/de';
import localeDEextra from '@angular/common/locales/extra/de';
import localeEN from '@angular/common/locales/en';
import localeENextra from '@angular/common/locales/extra/en';

import { strings as enStrings } from 'ngx-timeago/language-strings/en';
import { strings as deStrings } from 'ngx-timeago/language-strings/de';
import { TimeagoIntl } from 'ngx-timeago';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../environments/environment';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { deLocale } from "ngx-bootstrap/locale";

registerLocaleData(localeDE, 'de', localeDEextra);
registerLocaleData(localeEN, 'en', localeENextra);

@Component({
  selector: 'easports-fifa-app',
  templateUrl: './easports-fifa-app.component.html',
})
export class EasportsFifaAppComponent {
  isLoading = true;
  version = environment.version;
  currentTranslation = 'en';

  constructor(
    private translate: TranslateService,
    private bsLocale: BsLocaleService,
    private intl: TimeagoIntl,
    private route: ActivatedRoute,
    private _router: Router,
  ) {
    defineLocale('en', deLocale);
    this.bsLocale.use('en');
    this.translate.use(environment.defaultLocale).subscribe(() => {
      this.isLoading = true;
      this.currentTranslation = this.translate.currentLang;
      if (this.translate.currentLang === 'en') {
        this.intl.strings = enStrings;
      } else if (this.translate.currentLang === 'de') {
        this.intl.strings = deStrings;
      }
      this.intl.changes.next();
    });
  }
}
