<ng-container *ngIf="showChildren && folder.children.length > 1; else noChildren">
  <div class="btn-group" dropdown>
    <button
      id="button-split"
      type="button"
      dropdownToggle
      class="btn btn-link dropdown-toggle dropdown-toggle-split"
      aria-controls="dropdown-split"
    >
      {{ folder.name }}
      <span class="caret"></span>
      <span class="sr-only">{{ folder.name }}</span>
    </button>
    <ul id="dropdown-split" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-split">
      <li *ngFor="let child of folder.children" role="menuitem">
        <a class="dropdown-item" href="#" [routerLink]="['/', translate.currentLang, 'f', child.id]">{{
          child.name
        }}</a>
      </li>
    </ul>
  </div>
</ng-container>
<ng-template #noChildren>
  <span>{{ folder.name }}</span>
</ng-template>
