import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BdKravCoreModule } from '../core/core.module';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormUtilitiesModule } from '@braune-digital/form-utilities';

@NgModule({
  imports: [
    CommonModule,
    BdKravCoreModule,
    TranslateModule,
    FormsModule,
    FormUtilitiesModule,
    ReactiveFormsModule,
  ],
  declarations: [
  ],
  providers: [],
  exports: [
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class BdKravRequestModule {}
