import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { Notification, NotificationService } from 'src/app/services/notification.service';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../../security/services/auth.service';

@Component({
  selector: '<app-page>',
  templateUrl: './page.component.html',
})
export class PageComponent implements OnInit {
  notifications$: Observable<Notification[]>;

  constructor(
    public translate: TranslateService,
    private _notificationService: NotificationService,
    public route: ActivatedRoute,
    public authService: AuthService
  ) {}

  ngOnInit(): void {
    this.notifications$ = this._notificationService.notifications$;
  }
}
