<form *ngIf="form" [formGroup]="form" (submit)="onSubmit()">

  <h3>Add a new request</h3>
  <p *ngIf="importQueueEntry">
    <small>
      Below you can find all relevant information from last years pack. Please double-check and change if necessary.
    </small>
  </p>

  <div class="mt-5 mb-5">
    <ng-select
      bdSelect
      *ngIf="isSuperAdmin"
      [formControl]="form.get('requestor')"
      bindValue="id"
      [virtualScroll]="true"
      bindLabel="fullName"
      [multiple]="false"
      [items]="requestors"
      [clearable]="false"
      placeholder="Requestor"
    ></ng-select>
  </div>
  <bd-radio
    [control]="form.get('type')"
    [group]="form"
    fieldName="type"
    (change)="onChangeType()"
    [radioOptions]="typeOptions"
  ></bd-radio>
  <div class="row">
    <div class="col-md-6">
      <ng-select
        bdSelect
        class="mb-0"
        [formControl]="form.get('countryFolder')"
        bindValue="id"
        [virtualScroll]="true"
        bindLabel="name"
        [multiple]="false"
        [items]="folderService.fetchAllCountries$() | async"
        (change)="onChangeCountry()"
        placeholder="Country"
      ></ng-select>
    </div>
    <div class="col-md-6">
      <ng-select
        bdSelect
        class="mb-0"
        [formControl]="form.get('leagueFolder')"
        bindValue="id"
        [virtualScroll]="true"
        bindLabel="name"
        [multiple]="false"
        [items]="leagues$ | async"
        [clearable]="false"
        (change)="onChangeLeague()"
        placeholder="League"
      ></ng-select>
    </div>
  </div>
  <div>
    <bd-input
      [formControl]="form.get('clubFolderName')"
      [placeholder]="clubFolderInputName"
    ></bd-input>
  </div>
  <div class="mt-5">
    <h3>Crest</h3>
    <p>
      <small>
        Upload of crest is optional. If it doesn't exist, it will be added during production.
      </small>
    </p>
    <div class="m17">
      <div class="m17__crest">
        <img
          [src]="crest?.path || 'assets/images/team_emblem_placeholder.png'" *ngIf="crest"
        />
      </div>
      <div class="m17__upload">
        <bd-upload
          [multiple]="false"
          [form]="form.get('crest')"
          [label]="'drop crest here or click to upload' | translate"
          [uploader]="uploader"
        ></bd-upload>
      </div>
    </div>
  </div>
  <div class="mt-5">
    <div class="alert alert-info">
      <bd-checkbox
        [formControl]="form.get('displayOnPublicPage')"
        label="Should the content be displayed and available for download on the public page fc25-club-packs.ea.com, once approved? Go-live of all approved packs is September 27th 2024."
      ></bd-checkbox>
    </div>
  </div>
  <div class="mt-5">
    <h3>
      Approver & Photo deliverer
    </h3>
    <p>
      <small>
        The approvers and photo deliverers will be in charge of uploading the necessary photos upfont, as well as giving feedback and eventually approving the final designs. All listed users will be invited by email once the request has been completed.
      </small>
    </p>
    <div
      class="m18"
      *ngFor="let approver of form.get('approvers')['controls']; let index = index"
    >
      <div class="m18__index">{{ index + 1 }}</div>
      <bd-input
        class="m18__name"
        [formControl]="approver.get('fullName')"
        placeholder="Fullname"
      ></bd-input>
      <bd-input
        class="m18__email"
        [formControl]="approver.get('email')"
        placeholder="E-Mail"
      ></bd-input>
      <div class="m18__actions">
        <a class="" href="#" (click)="$event.preventDefault();removeApprover(index)">
          <i class="fa fa-times-circle"></i>
        </a>
      </div>
    </div>
    <div class="m15 m15--light">
      <a href="#" (click)="$event.preventDefault(); addApproverFormItem()">
        <i class="fa fa-plus-circle"></i>
        <ng-container *ngIf="form.get('approvers')['controls'].length === 0; else addMore">
          {{ 'Add Approver' }}
        </ng-container>
        <ng-template #addMore>
          {{ 'Add another Approver' }}
        </ng-template>
      </a>
    </div>
  </div>
  <div class="text-center">
    <button
      class="btn btn-primary btn--big mt-5"
      [disabled]="form.invalid || isSendingRequest"
    >
      <ng-container *ngIf="importQueueEntry; else singleRequestSubmitLabel">
        {{ 'Confirm and request' }}
      </ng-container>
      <ng-template #singleRequestSubmitLabel>
        {{ 'Send request' }}
      </ng-template>
    </button>
  </div>
</form>
<div class="text-center mt-3">
  <button
    class="btn btn-link btn-sm"
    (click)="cancelEntry()"
  >
    {{ 'Cancel or request later' }}
  </button>

</div>
<ngx-loading [show]="isSendingRequest"></ngx-loading>
