import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'import-queue-entry-page',
  templateUrl: 'import-queue-entry.page.html',
})
export class ImportQueueEntryPage implements OnInit, OnDestroy {

  entryId: string;
  routeSubscription: Subscription;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.routeSubscription = this
      .route
      .params
      .subscribe(_ => {
        this.entryId = _.entry;
      })
    ;
  }

  fetchData(): void {

  }

  ngOnDestroy(): void {
    this.entryId = null;
    this.routeSubscription.unsubscribe();
    this.routeSubscription = null;
  }



}
