import { DeliveryInterface } from './DeliveryInterface';
import { ElementInterface } from './ElementInterface';
import { RequestInterface } from './RequestInterface';
import { RootlineInterface } from './RootlineInterface';
import { DeliveryElementVersionInterface } from '@kravling/modules/core/models/DeliveryElementVersionInterface';
import { MediaInterface } from '@kravling/modules/core/models/MediaInterface';

export enum DeliveryElementStates {
  COMPLETED = 'completed',
  NEW = 'new',
  IN_PROGRESS = 'in_progress',
}

export interface DeliveryElementInterface {
  state: string;
  createdAt: string;
  updatedAt: string;
  rootline: RootlineInterface;
  currentDraft: DeliveryElementVersionInterface;
  currentVersion: DeliveryElementVersionInterface;
  id: string;
  description: string;
  type: string;
  deletedAt: string;
  delivery: DeliveryInterface;
  element: ElementInterface;
  comments: Array<DeliveryInterface>;
  text: string;
  media: MediaInterface;
  request: RequestInterface;
}
