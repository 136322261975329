<button class="row table-data" (click)="toggleCheckbox()" [ngClass]="{ isChecked: isChecked }">
  <div class="col-xs-number">
    <span class="number">{{ rowNumber }}</span>
  </div>

  <div class="col-xs">
    <div class="ready">
      <div class="custom-control custom-checkbox">
        <input
          type="checkbox"
          class="custom-control-input"
          [id]="'customCheck-' + rowNumber"
          [checked]="isChecked"
          (change)="toggleCheckbox()"
        />
        <label class="custom-control-label" [for]="'customCheck-' + rowNumber"></label>
      </div>
    </div>
  </div>

  <div class="col-sm">
    <div class="table-item">{{ club.countryName }}</div>
  </div>

  <div class="col-sm">
    <div class="table-item">{{ club.leagueName }}</div>
  </div>

  <div class="col-sm">
    <div class="table-item">{{ club.clubName }}</div>
  </div>
</button>
