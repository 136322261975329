<div *ngIf="progress" class="m04__progress__bar">
  <h5>{{ 'Overall status' | translate }}</h5>
  <div class="progress">
    <div
      class="progress-bar progress-bar-striped bg-success"
      [style.width]="progressPercentageCompleted"
    ></div>
    <div
      class="progress-bar progress-bar-striped bg-warning"
      [style.width]="progressPercentageDelivered"
    ></div>
  </div>
  <dl class="m04__progress__bar__legend">
    <dt>
      {{ DeliveryStates.COMPLETED.toString() | translate }}
    </dt>
    <dd>{{ progress.completed }} / {{ progress.count }}</dd>
    <dt>
      {{ DeliveryStates.DELIVERED.toString() | translate }}
    </dt>
    <dd>
      {{ progress.completed + progress.delivered }} /
      {{ progress.count }}
    </dd>
  </dl>
</div>
