import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface Notification {
  message: string;
  severity: 'info' | 'error';
  id: number;
}

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private _notifications$ = new BehaviorSubject<Notification[]>([]);

  get notifications$() {
    return this._notifications$.asObservable();
  }

  emplace(notification: Notification) {
    this._notifications$.next(this._notifications$.value.concat([notification]));
  }

  remove(id: number) {
    this._notifications$.next(this._notifications$.value.filter((n) => n.id !== id));
  }
}
