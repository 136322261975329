import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { take } from 'rxjs/operators';
import { DeliveryReadViewInterface } from '@kravling/modules/core/models/View/DeliveryReadViewInterface';
import { DeliveryService } from '@kravling/modules/core/services/delivery.service';
import { FileItem, FileUploader } from 'ng2-file-upload';
import { environment } from '../../../../../../environments/environment';
import { AuthService } from '../../../../security/services/auth.service';
import { CommentListViewInterface } from '@kravling/modules/core/models/View/CommentListViewInterface';
import { MediaProviderEnum } from '../../../../../models/interfaces/media.interface';
import { MediaInterface } from '@kravling/modules/core/models/MediaInterface';

@Component({
  selector: '<app-approval-comment-form>',
  templateUrl: './comment.component.html',
})
export class CommentComponent implements OnInit, OnDestroy {
  @Input() delivery: DeliveryReadViewInterface;
  @Output() onNewComment = new EventEmitter<CommentListViewInterface>();
  @Input() commentPlaceholder: string = '';

  form: FormGroup;
  isFormSubmitting = false;
  showAttachments = false;
  progress: string;
  MediaProviderEnum = MediaProviderEnum;
  showProgress: boolean;

  protected uploader = new FileUploader({
    url: environment.api + '/upload',
    authToken: 'Bearer ' + AuthService.user.token,
    method: 'post',
    autoUpload: true,
    headers: [
      {
        name: 'Accept',
        value: 'application/json',
      },
    ],
  });

  constructor(protected fb: FormBuilder, protected deliveryService: DeliveryService) {}

  ngOnInit() {
    this.buildForm();
    this.progress = '0%';
    const self = this;
    this.uploader.onAfterAddingAll = function (fileItems: FileItem[]) {
      self.progress = '0%';
    };
    this.uploader.onProgressAll = function (progress: number) {
      self.progress = progress.toString() + '%';
      self.showProgress = true;
    };
    this.uploader.onCompleteAll = function () {
      self.showProgress = false;
    };
  }

  buildForm() {
    this.form = this.fb.group({
      text: this.fb.control(null),
      attachments: this.fb.control([]),
    });
  }

  onSubmit() {
    this.isFormSubmitting = true;
    this.deliveryService
      .comment$(this.delivery.id, this.form.getRawValue())
      .pipe(take(1))
      .subscribe((_) => {
        this.onNewComment.emit(_);
        this.isFormSubmitting = false;
        this.form.get('text').setValue(null);
        this.form.get('attachments').setValue([]);
        this.uploader.queue = [];
      });
  }

  getFileAsMedia(file: any): MediaInterface {
    return file as MediaInterface;
  }

  get isSubmitDisabled(): boolean {
    if (this.form.get('text').value === null || this.form.get('text').value.toString() === '') return true;
    return false;
  }

  ngOnDestroy(): void {
    this.showAttachments = false;
    this.showProgress = false;
  }
}
