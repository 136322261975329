<div class="m09" *ngIf="showDetails">
  <div class="m09__users">
    <p>
      {{ 'Users with approval permissions' | translate }}
    </p>
    <div class="chip">
      {{ delivery.requester.displayName }}
    </div>
    <div
      class="chip chip--action"
      [ngClass]="{ 'chip--action': canRemoveUser }"
      *ngFor="let approver of delivery.approvers; let index = index"
    >
      {{ approver.displayName }}
      <span
        class="close-btn"
        (click)="removeUser.emit({ user: approver, users: delivery.approvers, type: 'approver', i: index })"
        *ngIf="canRemoveUser"
        >×</span
      >
    </div>
    <a
      href="#"
      tooltip="{{ 'Add Approver' }}"
      (click)="$event.preventDefault(); openAddUserModal.emit({ type: 'approver', users: delivery.approvers })"
      *ngIf="canAddUser"
    >
      <i class="fa fa-plus"></i>
    </a>
  </div>
  <div class="m09__users">
    <p>
      {{ 'Users with delivery permissions' | translate }}
    </p>
    <div class="chip">
		{{ delivery.deliverer.displayName }}
    </div>
    <div
      class="chip chip--action"
      [ngClass]="{ 'chip--action': canRemoveUser }"
      *ngFor="let deliverer of delivery.deliverers; let index = index"
    >
      {{ deliverer.displayName }}
      <span
        class="close-btn"
        (click)="removeUser.emit({ user: deliverer, users: delivery.deliverers, type: 'deliverer', i: index })"
        *ngIf="canRemoveUser"
        >×</span
      >
    </div>
    <a
      href="#"
      tooltip="{{ 'Add deliverer' }}"
      (click)="$event.preventDefault(); openAddUserModal.emit({ type: 'deliverer', users: delivery.deliverers })"
      *ngIf="canAddUser"
    >
      <i class="fa fa-plus"></i>
    </a>
  </div>
  <div class="m09__users">
    <p>
      {{ 'Observing users without approval permission' | translate }}
    </p>
    <div
      class="chip chip--action"
      [ngClass]="{ 'chip--action': canRemoveUser }"
      *ngFor="let observer of delivery.observers; let index = index"
    >
      {{ observer.displayName }}
      <span
        class="close-btn"
        (click)="removeUser.emit({ user: observer, users: delivery.observers, type: 'observer', i: index })"
        *ngIf="canRemoveUser"
        >×</span
      >
    </div>
    <a
      href="#"
      tooltip="{{ 'Add Observer' }}"
      (click)="$event.preventDefault(); openAddUserModal.emit({ type: 'observer', users: delivery.observers })"
      *ngIf="canAddUser"
    >
      <i class="fa fa-plus"></i>
    </a>
  </div>
  <div class="m09__settings" *ngIf="delivery.role === roles.DELIVERER.toString() && showCommentsSettings">
    <p>{{ 'Settings' | translate }}</p>
    <a href="#" (click)="$event.preventDefault(); toggleComments.emit()" class="btn btn-sm btn-secondary">
      <ng-container *ngIf="!delivery.enableComments; else disableComments">{{
        'Enable comments' | translate
      }}</ng-container>
      <ng-template #disableComments>{{ 'Disable comments' | translate }}</ng-template>
    </a>
  </div>
</div>
