<div class="container">
  <div class="m04">
    <div class="card ">
      <div class="card-body">
        <h2 class="card-title mb-5">
          {{ 'Budgets' }}
        </h2>
        <ng-container
          *ngIf="(budgetService.budget$ | async) as budget"
        >
          <div class="m14 p-3">
            <div class="m14__table">
              <div class="table-data-container">
                <div class="row table-header">
                  <div class="col-sm">Packs total</div>
                  <div class="col-sm">Budget total</div>
                  <div class="col-sm">Total costs</div>
                  <div class="col-sm">Over budget</div>
                </div>
                <div class="row table-data">
                  <div class="col-sm">
                    <div class="table-item">
                      {{ budget.numTotalPacks }}
                    </div>
                  </div>
                  <div class="col-sm">
                    <div class="table-item">
                      {{ budget.totalBudgetInCent / 100|currency:"EUR":"symbol" }}
                    </div>
                  </div>
                  <div class="col-sm">
                    <div class="table-item">
                      {{ budget.totalCostsInCents / 100|currency:"EUR":"symbol" }}
                    </div>
                  </div>
                  <div class="col-sm">
                    <div class="table-item">
                      {{ budget.totalCostsOverBudgetInCents / 100|currency:"EUR":"symbol" }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="m14 p-3">
            <div class="m14__table">
              <div class="table-data-container">
                <div class="row table-header">
                  <div class="col-sm">Region</div>
                  <div class="col-sm">Packs total</div>
                  <div class="col-sm">Completed Packs total</div>
                  <div class="col-sm">Packs relative <i class="fa fa-calculator"></i></div>
                  <div class="col-sm">Budget transfer <i class="fa fa-calculator"></i></div>
                </div>
                <div class="row table-data" *ngFor="let regionBudget of budget.regionBudgets">
                  <div class="col-sm">
                    <div class="table-item">{{ regionBudget.regionName }}</div>
                  </div>

                  <div class="col-sm">
                    <div class="table-item">{{ regionBudget.numPacks }}</div>
                  </div>

                  <div class="col-sm">
                    <div class="table-item">{{ regionBudget.numCompletedPacks }}</div>
                  </div>

                  <div class="col-sm">
                    <div class="table-item">{{ (regionBudget.packsRelative) * 100 | number: '1.2-2' }}%</div>
                  </div>

                  <div class="col-sm">
                    <div class="table-item">{{ regionBudget.budgetTransferInCents / 100|currency:"EUR":"symbol" }}</div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>

