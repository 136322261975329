import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { ImportQueueEntryView } from '@content-hub/ts-models/dist/Import';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FileUploader } from 'ng2-file-upload';
import { environment } from '../../../../../../environments/environment';
import { AuthService } from '../../../../security/services/auth.service';
import { FolderService } from '../../../services/folder.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { FolderView } from '@content-hub/ts-models/dist/Folder';
import {map, take, tap} from 'rxjs/operators';
import { MediaView } from '@content-hub/ts-models';
import { ImportService } from '../../../services/import.service';
import { WordCountValidators } from '../../../validators/word-count.validator';
import { RequestorService } from '../../../services/requestor.service';
import RequestorView from '@content-hub/ts-models/src/User/Requestor/requestor.view';

@Component({
  selector: 'app-club-form',
  templateUrl: './club-form.component.html',
})
export class ClubFormComponent implements OnInit, OnChanges, OnDestroy {

  form: FormGroup;
  uploader = new FileUploader({
    url: environment.api + '/upload',
    authToken: 'Bearer ' + AuthService.user.token,
    method: 'post',
    autoUpload: true,
    headers: [
      {
        name: 'Accept',
        value: 'application/json',
      },
    ],
  });
  leagues$ = new BehaviorSubject<FolderView[]>([]);
  crest: MediaView;
  isSendingRequest: boolean;
  typeOptions = [
    {
      value: 'club',
      label: 'Club',
    },
    {
      value: 'league',
      label: 'League',
    },
    {
      value: 'national_team',
      label: 'National Team',
    },
    {
      value: 'other',
      label: 'Social Cause / Other',
    }
  ];

  isSuperAdmin = AuthService.user && AuthService.user.roles.some((r) => r === 'ROLE_SUPER_ADMIN');
  requestors: RequestorView[];
  requestorSubscription: Subscription;

  @Input() importQueueEntry: ImportQueueEntryView;
  @Output() onRequestSuccess = new EventEmitter<boolean>();
  @Output() onCancelEntry = new EventEmitter<string>();

  constructor(
    private fb: FormBuilder,
    public folderService: FolderService,
    public importService: ImportService,
    public requestorService: RequestorService,
  ) {
  }

  ngOnInit() {
    if (this.isSuperAdmin) {
      this.typeOptions.push({
        value: 'league_approval',
        label: 'League Approval',
      });
    }
    this.refresh();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['importQueueEntry']) {
      this.refresh();
    }
  }

  refresh(): void {
    if (this.importQueueEntry) {
      this.crest = this.importQueueEntry.crest;
    }
    this.uploader.onCompleteItem = (_item, response) => {
      const media = <MediaView> JSON.parse(response);
      this.updateCrestPreview(media);
    };
    this.buildForm();
    this.updateLeagues(false);
  }

  buildForm() {
    const approvers = this.fb.array([]);
    if (this.importQueueEntry) {
      this
        .importQueueEntry
        .approvers
        .forEach(approver => {
          approvers
            .push(
              this.fb.group({
                fullName: this.fb.control(approver.fullName, [Validators.required, WordCountValidators.min(2)]),
                email: this.fb.control(approver.email, [Validators.required]),
              })
            )
          ;
        })
      ;
    }

    this.form = this.fb.group({
      type: this.fb.control('club', []),
      countryFolder: this.fb.control((this.importQueueEntry && this.importQueueEntry.country) ? this.importQueueEntry.country.id : null, [Validators.required]),
      leagueFolder: this.fb.control((this.importQueueEntry && this.importQueueEntry.league) ? this.importQueueEntry.league.id : null, [Validators.required]),
      clubFolderName: this.fb.control((this.importQueueEntry && this.importQueueEntry.club) ? this.importQueueEntry.club.name : null, [Validators.required]),
      crest: this.fb.control((this.importQueueEntry) ? this.importQueueEntry.crest : null, []),
      displayOnPublicPage: this.fb.control(true, []),
      isLicensed: this.fb.control(true, []),
      approvers: approvers,
    });

    if (this.isSuperAdmin) {
      this.form.addControl('requestor', this.fb.control(AuthService.user.id, [Validators.required]));
      this
        .requestorService
        .updateRequestors()
      ;
      this.requestorSubscription = this
        .requestorService
        .requestors$
        .subscribe(_ => {
          this.requestors = _;
          if (this.isSuperAdmin) {
            const isCurrentUserPartOfRequestors = _.find(requestor => requestor.id === AuthService.user.id) !== null;
            if (isCurrentUserPartOfRequestors) {
              this
                .form
                .get('requestor')
                .setValue(AuthService.user.id)
            }
          }
        })
      ;
    }


  }

  addApproverFormItem(): void {
    const approversArray = this
      .form
      .get('approvers') as FormArray
    ;
    approversArray
      .push(
        this.fb.group({
          fullName: this.fb.control(null, [Validators.required, WordCountValidators.min(2)]),
          email: this.fb.control(null, [Validators.required]),
        })
      )
    ;
  }

  removeApprover(index: number): void {
    const approversArray = this
      .form
      .get('approvers') as FormArray
    ;
    approversArray.removeAt(index);
  }

  updateCrestPreview(media: MediaView): void {
    if (this.importQueueEntry) {
      this.importQueueEntry.crest = media;
    }
    this.crest = media;
  }

  onChangeType(): void {
    this
      .form
      .get('leagueFolder')
      .setValue(null)
    ;
    this
      .leagues$
      .next([])
    ;
    this.setDefaultOrNullValueForCountryAndDisableControl();
    this.setValueOrNullForLeagueAndDisableControl();
    this.setValueOrNullForClubAndDisableControl();
  }

  onChangeCountry(): void {
    this.updateLeagues();
    this.setValueOrNullForLeagueAndDisableControl();
    this.setValueOrNullForClubAndDisableControl();
  }

  onChangeLeague(): void {
    const type = this.form.get('type').value;
    if (this.isClubSelectDisabled && type === 'league_approval') {
      this.setPackLabelForLeagueApproval();
    }
    if (this.isClubSelectDisabled && type === 'league') {
      this.setPackLabelForLeague();
    }
  }

  updateLeagues(clearForm: boolean = true): void {
    if (clearForm) {
      this
        .form
        .get('leagueFolder')
        .setValue(null)
      ;
    }
    this
      .folderService
      .fetchAllLeagues$(
        this.form.get('countryFolder').value
      )
      .pipe(
        take(1),
        map(_ => {
          this
            .leagues$
            .next(_)
          ;
          if (_.length === 1) {
            this
              .form
              .get('leagueFolder')
              .setValue(
                _[0].id
              )
            ;
            this.setValueOrNullForLeagueAndDisableControl();
          }
        })
      )
      .subscribe()
    ;
  }

  setValueOrNullForClubAndDisableControl(): void {
    const type = this.form.get('type').value;
    if (this.isClubSelectDisabled) {
      switch (type) {
        case 'league':
          this.setPackLabelForLeague();
          break;
        case 'league_approval':
          this.setPackLabelForLeagueApproval();
          break;
        default:
          this.form.get('clubFolderName').setValue(null);
      }
      this.form.get('clubFolderName').disable();
    } else {
      switch (type) {
        case 'national_team':
          this
            .folderService
            .getCountryFolderById(
              this.form.get('countryFolder').value
            )
            .then(folder => {
              if (!folder) {
                this.form.get('clubFolderName').setValue(null);
                return;
              }
              this.form.get('clubFolderName').setValue(folder.name + ' National Team');
            })
          ;
          break;
      }
      this.form.get('clubFolderName').enable();
      this.form.get('clubFolderName').setValue(null);
    }
  }

  setPackLabelForLeagueApproval(): void {
    this
      .folderService
      .getLeagueFolderById(
        this.form.get('leagueFolder').value
      )
      .then(folder => {
        if (!folder) {
          this.form.get('clubFolderName').setValue(null);
          return;
        }
        this.form.get('clubFolderName').setValue(folder.name + ' League Approval');
      })
    ;
  }

  setPackLabelForLeague(): void {
    this
      .folderService
      .getLeagueFolderById(
        this.form.get('leagueFolder').value
      )
      .then(folder => {
        if (!folder) {
          this.form.get('clubFolderName').setValue(null);
          return;
        }
        this.form.get('clubFolderName').setValue(folder.name + ' Cover');
      })
    ;
  }

  setValueOrNullForLeagueAndDisableControl(): void {
    if (this.isLeagueSelectDisabled) {
      const type = this.form.get('type').value;
      switch (type) {
        case 'national_team':
          this
            .folderService
            .getLeagueFolderByNameAndParent(
              'No league',
              this.form.get('countryFolder').value
            )
            .then(folder => {
              if (!folder) {
                this.form.get('leagueFolder').setValue(null);
                return;
              }
              this.form.get('leagueFolder').setValue(folder.id);
            })
          ;
          break;
        case 'other':
          this
            .folderService
            .getLeagueFolderByNameAndParent(
              'No league',
              this.form.get('countryFolder').value
            )
            .then(folder => {
              if (!folder) {
                this.form.get('leagueFolder').setValue(null);
                return;
              }
              this.form.get('leagueFolder').setValue(folder.id);
            })
          ;
          break;
      }
      this.form.get('leagueFolder').disable();
      return;
    } else {
      this.form.get('leagueFolder').enable();
      return;
    }
  }

  setDefaultOrNullValueForCountryAndDisableControl(): void {
    if (this.isCountrySelectDisabled) {
      this
        .folderService
        .getGeneralCountryFolder()
        .then(folder => {
          this.form.get('countryFolder').setValue(folder.id);
        })
      ;
      this.form.get('countryFolder').disable();
    } else {
      this.form.get('countryFolder').setValue(null);
      this.form.get('countryFolder').enable();
    }
  }

  cancelEntry(): void {
    this.isSendingRequest = true;
    this
      .importService
      .deleteImportQueueEntry$(
        this.importQueueEntry.id
      )
      .pipe(
        take(1)
      )
      .subscribe(_ => {
        this.isSendingRequest = false;
        this.onCancelEntry.emit(this.importQueueEntry.id);
        if (this.importQueueEntry) {
          this.importService.clearActiveImportQueue();
        }
      })
    ;
  }

  onSubmit(): void {
    this.confirmAndRequest();
  }

  confirmAndRequest(): void {
    this.isSendingRequest = true;
    let data = this.form.getRawValue();
    if (data.displayOnPublicPage === null) {
      data.displayOnPublicPage = false;
    }
    if (this.importQueueEntry) {
      data.importQueueEntry = this.importQueueEntry.id;
    }
    if (!this.isSuperAdmin && typeof data['requestor'] === 'undefined') {
      data['requestor'] = AuthService.user.id;
    }

    this
      .importService
      .confirmAndRequest$(
        data
      )
      .pipe(
        take(1)
      )
      .subscribe(_ => {
        this.isSendingRequest = false;
        this.onRequestSuccess.emit(true);
        if (this.importQueueEntry) {
          this.importService.clearActiveImportQueue();
        }
      })
    ;
  }

  get isCountrySelectDisabled(): boolean {
    return false;
  }

  get isLeagueSelectDisabled(): boolean {
    const type = this.form.get('type').value;
    return this.isCountrySelectDisabled || type === 'national_team' || type === 'other';
  }

  get isClubSelectDisabled(): boolean {
    const type = this.form.get('type').value;
    return type === 'league' || type === 'league_approval';
  }

  get clubFolderInputName(): string {
    const type = this.form.get('type').value;
    return (type === 'club')
      ? 'Club Name'
      : 'Pack Name'
      ;
  }

  ngOnDestroy(): void {
    if (this.requestorSubscription) {
      this.requestorSubscription.unsubscribe();
    }
  }



}
