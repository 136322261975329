import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { DeliveryService } from '@kravling/modules/core/services/delivery.service';
import { NotificationService } from '../../../../services/notification.service';
import { ImportService } from '../../services/import.service';
import { map, take } from 'rxjs/operators';
import { ImportQueueView } from '@content-hub/ts-models/dist/Import';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-request-packs',
  templateUrl: './request-packs.component.html',
})
export class RequestPacksComponent implements OnInit, OnDestroy {

  isLoading = true;
  importQueue: ImportQueueView;
  currentView: string;

  constructor(
    public translate: TranslateService,
    private deliveryService: DeliveryService,
    private notificationService: NotificationService,
    private importService: ImportService
  ) {}

  ngOnInit() {
    this.isLoading = true;
    this.getActiveImportQueue();
  }

  getActiveImportQueue(): void {
    this
      .importService
      .getActiveImportQueue$()
      .pipe(
        take(1),
        map(_ => {
          if (_.id) {
            this.importQueue = _;
          }
          this.isLoading = false;
        })
      )
      .subscribe()
    ;
  }

  ngOnDestroy(): void {
    this.isLoading = false;
  }


}
