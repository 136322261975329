import { Component, HostBinding, Input } from '@angular/core';
import { CommentFeedItemInterface } from '@kravling/modules/core/models/CommentFeedItemInterface';
import { FeedItemComponent } from '../feed-item.component';
import { environment } from '../../../../../../environments/environment';
import { AuthService } from '../../../../security/services/auth.service';
import { MediaInterface } from '@kravling/modules/core/models/MediaInterface';

@Component({
  selector: '<app-approval-delivery-feed-item-comment>',
  templateUrl: './feed-type-comment.component.html',
})
export class FeedTypeCommentComponent extends FeedItemComponent {
  @Input() item: CommentFeedItemInterface;

  @HostBinding('class')
  get hostClasses(): string {
    return 'm02__comment';
  }

  download(attachment: MediaInterface): void {
    window.location.href =
      environment.api + '/media/download/' + attachment.id + '/reference?bearer=' + AuthService.user.token;
  }
}
