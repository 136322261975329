import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { StorageService } from '../../../../../../src/app/modules/security/services/storage.service';
import { HttpClient } from '@angular/common/http';
import { UserInterface } from '@kravling/modules/core/models/UserInterface';

@Injectable()
export class UserService {
  constructor(private http: HttpClient, private _storage: StorageService) {}

  get(id): Observable<UserInterface> {
    return this.http.get<UserInterface>(`/user/${id}`);
  }
}
