<div class="container">
  <div class="m04">
    <app-club-packs-header
      headline="Launch suite request"
    ></app-club-packs-header>
  </div>

  <div class="card">
    <div class="card-body">
      <div class="m16">
        <h3 class="mb-3">
          Select your region:
        </h3>
        <p>
          <small>
            Please select the region for your requests. In the next step we’ll give you the list of all FIFA 23 Club packs from that region.
          </small>
        </p>
        <div>
          <ng-select
            bdSelect
            class="mb-0"
            [(ngModel)]="selected"
            bindValue="id"
            [virtualScroll]="true"
            bindLabel="name"
            [multiple]="false"
            [clearable]="false"
            [items]="regions$ | async"
            (change)="navigateToImport($event)"
          ></ng-select>
        </div>

        <!--<p>
          <a href="https://fifa22-club-packs.ea.com/">
            Show all FIFA22 Packs
          </a>
        </p>-->

      </div>
    </div>
  </div>
</div>

