import { UserInterface } from './UserInterface';
import { FolderInterface } from './FolderInterface';
import { TaskInterface } from './TaskInterface';
import { DeliveryElementInterface } from './DeliveryElementInterface';
import { RequestInterface } from './RequestInterface';

export enum DeliveryStates {
  NEW = 'new',
  DELIVERED = 'delivered',
  COMPLETED = 'completed',
}

export interface DeliveryInterface {
  state: string;
  createdAt: string;
  updatedAt: string;
  id: string;
  deletedAt: string;
  user: UserInterface;
  folder: FolderInterface;
  summary: any;
  request: RequestInterface;
  task: TaskInterface;
  deliveryElements: Array<DeliveryElementInterface>;
  approvers: Array<UserInterface>;
  observers: Array<UserInterface>;
}
