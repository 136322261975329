<div class="modal-header">
  <h5 class="modal-title">{{ 'Upload Club Crest'|translate }}</h5>
  <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <bd-upload
    *ngIf="uploader"
    class="m07__attachments"
    [multiple]="false"
    [form]="form.get('logo')"
    [uploader]="uploader"
    [disableMeta]="true"
  ></bd-upload>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="bsModalRef.hide()">
    {{ 'Close'|translate }}
  </button>
</div>

<ng-template #loading>
  <ngx-loading [show]="true"></ngx-loading>
</ng-template>
