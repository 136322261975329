import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { UserListViewInterface } from '@kravling/modules/core/models/View/UserListViewInterface';
import { RegionService } from '../../../services/region.service';
import { RequestorService } from '../../../services/requestor.service';
import { RequestorView } from '@content-hub/ts-models/dist/User/Requestor';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  templateUrl: 'invite-or-edit-requestor.modal.html',
  selector: '<app-invite-or-edit-requestor-modal>',
})
export class InviteOrEditRequestorModal implements OnInit, OnDestroy {
  requestor: RequestorView;
  type: string;
  isSending: boolean;
  private _subscriptions: Subscription[] = [];
  form: FormGroup;

  @Output()
  onSaveSuccess = new EventEmitter<UserListViewInterface>();

  constructor(
    public bsModalRef: BsModalRef,
    private fb: FormBuilder,
    public regionService: RegionService,
    public requestorService: RequestorService
  ) {}

  ngOnInit() {
    this
      .regionService
      .fetchRegions()
    ;
    window.setTimeout(_ => {
      this.buildForm();
    }, 200);
  }

  buildForm() {
    const regionIds = (this.requestor) ? this.requestor.regions.reduce((regions, region) => {
      return regions.concat([region.id]);
    }, []) : [];
    this.form = this.fb.group({
      firstName: this.fb.control((this.requestor) ? this.requestor.firstName : null, [Validators.required]),
      lastName: this.fb.control((this.requestor) ? this.requestor.lastName : null, [Validators.required]),
      email: this.fb.control((this.requestor) ? this.requestor.email : null, [Validators.email, Validators.required]),
      regions: this.fb.control(regionIds, [Validators.minLength(1)]),
    });
  }

  onSubmit(): void {
    this.isSending = true;
    if (!this.requestor) {
      this.createRequestor();
    } else {
      this.updateRequestor();
    }
  }

  createRequestor(): void {
    this.requestorService
      .create$(this.form.getRawValue())
      .pipe(take(1))
      .subscribe(
        (_) => {
          this.isSending = false;
          this.onSaveSuccess.emit(_);
          this.bsModalRef.hide();
        },
        (error) => {
          // Todo
        },
      );
  }

  updateRequestor(): void {
    this.requestorService
      .update$(
        this.requestor.id,
        this.form.getRawValue()
      )
      .pipe(take(1))
      .subscribe(
        (_) => {
          this.isSending = false;
          this.onSaveSuccess.emit(_);
          this.bsModalRef.hide();
        },
        (error) => {
          // Todo
        },
      );
  }

  get canSave(): boolean {
    if (this.form && this.form.valid) return true;
    return false;
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach((_) => _.unsubscribe());
  }
}
