import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { DeliveryElementListViewInterface } from '@kravling/modules/core/models/View/DeliveryElementListViewInterface';
import { DeliveryElementService } from '@kravling/modules/core/services/deliveryElement.service';
import { take } from 'rxjs/operators';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  templateUrl: 'delivery-element-edit.modal.html',
  selector: '<app-approval-delivery-element-edit-modal>',
})
export class DeliveryElementEditModal implements OnInit, OnDestroy {
  deliveryElement: DeliveryElementListViewInterface;
  form: FormGroup;
  isSending: boolean;
  private _subscriptions: Subscription[] = [];

  @Output()
  onSaveSuccess = new EventEmitter<DeliveryElementListViewInterface>();

  constructor(
    public bsModalRef: BsModalRef,
    private _fb: FormBuilder,
    private deliveryElementService: DeliveryElementService,
  ) {}

  ngOnInit(): void {}

  onSave(): void {
    this.isSending = true;
    this.deliveryElementService
      .patch$(this.deliveryElement.id, this.form.getRawValue())
      .pipe(take(1))
      .subscribe(
        (_) => {
          this.isSending = false;
          this.onSaveSuccess.emit(_);
          this.bsModalRef.hide();
        },
        (error) => {
          // Todo
        },
      );
  }

  get canSave(): boolean {
    if (this.form && this.form.valid) return true;
    return false;
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach((_) => _.unsubscribe());
  }
}
