import { Component } from '@angular/core';
import { FileItem } from 'ng2-file-upload';
import { BdUploadComponent } from '../bd-upload/bd-upload.component';

@Component({
  selector: '<app-approval-upload>',
  templateUrl: 'upload.component.html',
})
export class UploadComponent extends BdUploadComponent {
  progress: string;

  ngOnInit(): void {
    this.progress = '0%';
    const self = this;
    this.uploader.onAfterAddingAll = function (fileItems: FileItem[]) {
      self.progress = '0%';
    };
    this.uploader.onProgressAll = function (progress: number) {
      self.progress = progress.toString() + '%';
    };
  }
}
