import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DeliveryPackageListViewInterface } from '@kravling/modules/core/models/View/DeliveryPackageListViewInterface';
import { environment } from '../../../../../environments/environment';
import { AuthService } from '../../../security/services/auth.service';
import { DeliveryPackageStates } from '@kravling/modules/core/models/DeliveryPackageInterface';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: '<app-approval-delivery-package-download>',
  templateUrl: './delivery-package-download.component.html',
})
export class DeliveryPackageDownloadComponent implements OnInit, OnDestroy {
  @Input() deliveryPackage: DeliveryPackageListViewInterface;
  @Input() btnClass: string = 'btn btn-sm';

  subscription: Subscription;
  DeliveryPackageStates = DeliveryPackageStates;
  publicDeliveryToken: string;

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.subscription = this.route.params.subscribe((params) => {
      if (params['publicDeliveryToken']) {
        this.publicDeliveryToken = params['publicDeliveryToken'];
      }
    });
  }

  get downloadUrl(): string {
    if (AuthService.user) {
      return (
        environment.api + '/delivery-packages/' + this.deliveryPackage.id + '/download?bearer=' + AuthService.user.token
      );
    }
    if (this.publicDeliveryToken) {
      return (
        environment.api +
        '/public/delivery-packages/' +
        this.deliveryPackage.id +
        '/download?deliveryHasLinkToken=' +
        this.publicDeliveryToken
      );
    }
    return '';
  }

  ngOnDestroy(): void {
    if (this.subscription) this.subscription.unsubscribe();
    this.subscription = null;
  }
}
