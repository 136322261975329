import { Component, Input } from '@angular/core';
import { DeliveryPublicReadViewInterface } from '@kravling/modules/core/models/View/DeliveryPublicReadViewInterface';
import { DeliveryPackageListViewInterface } from '@kravling/modules/core/models/View/DeliveryPackageListViewInterface';

@Component({
  selector: 'app-approval-public-delivery',
  templateUrl: './public-delivery.component.html',
})
export class PublicDeliveryComponent {
  @Input() delivery: DeliveryPublicReadViewInterface;

  selectedDeliveryPackage: DeliveryPackageListViewInterface;
}
