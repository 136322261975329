export enum DeliveryPackageTransitions {
  DELIVER = 'deliver',
  REJECT = 'reject',
  REOPEN = 'reopen',
  APPROVE = 'approve',
}

export enum DeliveryPackageStates {
  NEW = 'new',
  REJECTED = 'rejected',
  DELIVERED = 'delivered',
  APPROVED = 'approved',
  WAITING = 'waiting',
}

export interface DeliveryPackageInterface {
  state: string;
}
