import { Component, Input } from '@angular/core';
import { DeliveryStates } from '@kravling/modules/core/models/DeliveryInterface';

@Component({
  selector: '<app-approval-delivery-status>',
  templateUrl: './delivery-status.component.html',
})
export class DeliveryStatusComponent {
  @Input()
  state: string;

  @Input()
  workflow: string = 'default';

  DeliveryState = DeliveryStates;
}
