import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { take } from 'rxjs/operators';
import { UserListViewInterface } from '@kravling/modules/core/models/View/UserListViewInterface';
import { RequestorService } from '../../../services/requestor.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { LegalLineView } from '@content-hub/ts-models/dist/LegalLine';
import { FolderService } from '../../../services/folder.service';
import { LegalLineService } from '../../../services/legal-line.service';

@Component({
  templateUrl: 'create-or-edit-legal-line.modal.html',
  selector: '<app-invite-or-edit-requestor-modal>',
})
export class CreateOrEditLegalLineModal implements OnInit {

  legalLine: LegalLineView;
  type: string;
  isSending: boolean;
  form: FormGroup;

  @Output()
  onSaveSuccess = new EventEmitter<LegalLineView>();

  constructor(
    public bsModalRef: BsModalRef,
    private fb: FormBuilder,
    public folderService: FolderService,
    public legalLineService: LegalLineService
  ) {}

  ngOnInit() {
    window.setTimeout(_ => {
      this.buildForm();
    }, 200);
  }

  buildForm() {
    this.form = this.fb.group({
      title: this.fb.control((this.legalLine) ? this.legalLine.title : null, [Validators.required]),
      text: this.fb.control((this.legalLine) ? this.legalLine.text : null, [Validators.required]),
      folder: this.fb.control((this.legalLine) ? this.legalLine.league['id'] : null, [Validators.required]),
    });

    if (this.legalLine) {
      this.form.get('folder').disable();
    }
  }

  onSubmit(): void {
    this.isSending = true;
    if (!this.legalLine) {
      this.createLegalLine();
    } else {
      this.updateLegalLine();
    }
  }

  createLegalLine(): void {
    this.legalLineService
      .create$(this.form.getRawValue())
      .pipe(take(1))
      .subscribe(
        (_) => {
          this.isSending = false;
          this.onSaveSuccess.emit(_);
          this.bsModalRef.hide();
        },
        (error) => {
          // Todo
        },
      );
  }

  updateLegalLine(): void {
    this.legalLineService
      .update$(
        this.legalLine.id,
        this.form.getRawValue()
      )
      .pipe(take(1))
      .subscribe(
        (_) => {
          this.isSending = false;
          this.onSaveSuccess.emit(_);
          this.bsModalRef.hide();
        },
        (error) => {
          // Todo
        },
      );
  }

  get canSave(): boolean {
    if (this.form && this.form.valid) return true;
    return false;
  }
}
